/**
* Types module hold global constant definitions
* @module Types
* @version 1.2
* @copyright Telecom2 Ltd.
*/

export const SHOW_LOADER = 'SHOW_LOADER'
export const SHOW_ALERT = 'SHOW_ALERT'
export const STREAM_SESSION_LISTING = 'STREAM_SESSION_LISTING'
export const STREAM_DETAILS = 'STREAM_DETAILS'
export const CONNECTED_USER_LISTING = 'CONNECTED_USER_LISTING'
export const ROOM_LISTING = 'ROOM_LISTING'
export const STREAMING_SUBSCRIBE_AREA = 'STREAMING_SUBSCRIBE_AREA'
export const NOTIFICATION_CONTENT = 'NOTIFICATION_CONTENT'
export const BLOCK_USER_DATA = 'BLOCK_USER_DATA'
export const BLOCK_SUBSCRIBER = 'BLOCK_SUBSCRIBER'
export const ROOM_INFO = 'ROOM_INFO'
export const ALLOW_SUBSCRIBER_PERMISSION_DATA = 'ALLOW_SUBSCRIBER_PERMISSION_DATA'
export const DENY_SUBSCRIBER_PERMISSION_DATA = 'DENY_SUBSCRIBER_PERMISSION_DATA'
export const ALLOW_SUBSCRIBER_PERMISSION_EVENT = 'ALLOW_SUBSCRIBER_PERMISSION_EVENT'
export const DENY_SUBSCRIBER_PERMISSION_EVENT = 'DENY_SUBSCRIBER_PERMISSION_EVENT'
export const INCOME_TIP_LISTING = 'INCOME_TIP_LISTING'
export const PING_READY = 'pingReady';
export const TWO_WAY_CAM_REQUEST ='TWO_WAY_CAM_REQUEST';
export const CONNECT_DIRECTLY_TO_ROOM ='CONNECT_DIRECTLY_TO_ROOM';
