/**
* Static Actions
* @module StaticActions
* @version 1.2
* @copyright Telecom2 Ltd.
* 
*/

import * as actionTypes from './../types/types';
import {log} from '../helpers/logger';

/**
 * @function ShowLoader
 * @param {*} value 
 */
export const ShowLoader = (value) => {
    const func = "ShowLoader(),dashboardActions.js,";
    log.trace("%svalue:%s", func, value);
    return dispatch => {   
         dispatch({type: actionTypes.SHOW_LOADER,payload: value}) 
    }
}//ShowLoader

/**
 * @function ShowAlert
 * @param {*} value 
 */
export const ShowAlert = (value) => {
    const func = "ShowAlert(),dashboardActions.js,";
    log.trace("%svalue:%s", func, value);

    return dispatch => {   
         dispatch({type: actionTypes.SHOW_ALERT,payload: value}) 
    }
}//ShowAlert
