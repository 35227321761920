/**
* @module FirstUrlRedirect
* @version 1.0
* @copyright Telecom2 Ltd.
* 
* At performer first login this component redirect 
* performer to an external url
* 
*/

import React from 'react';
import config from '../config.json';

// eslint-disable-next-line no-unused-vars
export class FirstUrlRedirect extends React.Component {
    componentDidMount() {
        //replace not allow to user go back 
        window.location.replace(config.firstLogin.url);
        //window.location.href = config.firstLogin.url;
    }
    render() {
        return (<></>);
    }
}