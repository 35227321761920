
/**
 * Red5 component customer
 * @module Red5Streaming
 * @version 1.2
 * @copyright Telecom2 Ltd.
 *
 */

import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Chat } from "../Dashboard/Chat";
import {
    AuthenticateSubscription,
    roomListing,
    chargeStream,
    blockSubscriberEvent,
    allowSubscriberPermissionEvent,
    denySubscriberPermissionEvent,
    twoWayCamRequestEvent,
    connectDirectlyToRoomEvent,
    statusUpdate,
    roomUpdate,
    joinApproval,
    twoWayCamApproval,
    connectDirectlyToRoomApproval
} from "../../store/actions/streamingsubscriberActions";
import { RTCSubscriber, PlaybackView, setLogLevel } from 'red5pro-webrtc-sdk'
import Modal from "react-bootstrap/Modal";
import { ch } from "../../ChatHandler/ChatHandler";
import config from "../../config.json";
import { CustomerAuth } from "../../store/actions/userActions";
import { log } from '../../store/helpers/logger';
import { CleanExit, removeAllIds } from "../../store/actions/userActions";
import { customerPopup } from "../../components/TwoWayCams/CustomerPopup.jsx";
import {
    removeShutDown,
    setId,
    getId,
    removeLocalStorage,
    setState,
    setPingReady,
    setShutDown,
    displayPopup,
    red5ErrorLevel,
    getShutDown,
    BaseUrl,
    apiCallAwait,
    deleteBrowserCache,
    //setCookie

}
    from "../../store/helpers/common";

import constants from "../../constants";
import errors from "../../errors";
import Swal from 'sweetalert2';
import { Scheduler } from '../../store/helpers/scheduler';


//here is subscriber status
// 0 - Awaiting
// 1- Accepted
// 2- Denied
// 3 - Kick out
// 4- disconnected
// 5- browser close/offline

/**
 * Red5Streaming Component
 * @example  
 * Booting process:
 * ComponentDidMount->init()
 * ->enterRoomHandler(null) || ->enterRoomHandler(room_id)
 * ->StartStreamingHandler()->StartStreaming()
 * 
*/

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export class _red5Streaming extends Component {

    retryTimeout = null;
    connected = false;
    connectionTime = {
        "timeLeft": 0,
        "timeStamp": 0,
        "lastUpdate": 0,
        "lastChargeStamp": 0,
        "lastCharge": 0
    };
    reConnectCounter = 0;
    refreshTimer = 0;
    instanceId = null;

    constructor(props) {
        const func = "constructor(),Red5Streaming.jsx,";
        super(props);
        this.state = {
            streaming: true, //used for storing streaming start or stop value
            stream_name: "", //used for storing stream Name
            show: false, //used for storing modal open and close value
            roomListingResult: [], //used for storing list of rooms
            room_id: null, //used for storing particular room id
            room_info: null,
            subscriberChat: false, //used for chat either enable or disable
            donationButtons: false, //used for donation buttons either enable or disable
            blockusername: null, //used for storing username of block user
            blockstatus: false, //used for storing status of block user
            allowSubscriberPermissionUsername: null, //used for storing username of allow user
            denySubscriberPermissionUsername: null, //used for storing username of deny user
            allowSubscriberPermissionStatus: false, //used for storing status of allow user
            denySubscriberPermissionStatus: false, //used for storing status of deny user
            waitingbuttonstatus: true, //used to storing status of waiting button
            waitingForApproval: false,
            enterRoom: false,
            twoWayCamRequestUsername: null, //used for storing username of cam request
            connectDirectlyToRoomUsername: null,
            connectionParams: {
                username: "",
                password: "",
                token: ""
            },
            cameraClass: "non-visible"
        };
        this.subscriber = undefined;
        this.viewer = undefined;
        log.trace("%sthis.state:%s", func, this.state);
        this.connectionTimeOutInterval = 0;
        this.connectionTime.timeLeft = 0;
        this.connectionTime.timeStamp = 0;
        this.connectionTime.lastUpdate = "";
        this.connectionTime.lastChargeStamp = 0;
        this.connectionTime.lastOnlineStatusStamp = 0;
        this.connectionTime.lastCharge = "";
        this.connectionTime.lastOnlineStatus = "";
        this.chatDirection = null;
        this.chatId = 0;
    }

    UNSAFE_componentWillUnMount() {
        const func = "UNSAFE_componentWillUnMount(),Red5Streaming.jsx,";
        if (this.refreshTimer) clearInterval(this.refreshTimer);
        this.refreshTimer = 0;

        removeAllIds(func);
        setShutDown(true, func);
        setPingReady(false, func);
    } //UNSAFE_componentWillMount


    /**
     * set username,performer IDs, remove last error, remove shutDown
     * set ping ready, start refreshChat pooling here, open room selector
     */
    async componentDidMount() {
        const func = "componentDidMount(),Red5Streaming.jsx,";

        await deleteBrowserCache(func);
        const { performer, username, returnUrl } = this.props.params;
        const queryParams = new URLSearchParams(window.location.search);
        const newReturnUrl = decodeURIComponent(returnUrl);
        let roomId = null;
        if(queryParams.get("roomId"))
            roomId = queryParams.get("roomId");
        log.trace("%sperformer:%s", func, performer);
        log.trace("%susername:%s", func, username);
        log.trace("%snewReturnUrl:%s", func, newReturnUrl);
        //this is only important to remove, 
        //if use removeAllIds not loggin in require further investigation 
        //if need , at this moment not very important to remove all.
        removeAllIds(func);
        setPingReady(false, func);
        //setCookie('loggerUser', username, func);
        setId('loggerUser', username, func);
        setId("username", username, func);
        setId("performer", performer, func);
        setId("returnUrl", newReturnUrl, func);
        if(roomId)
            setId("roomId", roomId, func);
        log.trace("%sperformer:%s", func, getId('performer'));
        log.trace("%susername:%s", func, getId('username'));
        removeLocalStorage('lastError', func, true);
        removeShutDown(func);

        var instanceCss = null
        if(typeof config.themes != "undefined"){
            let re = /(?:-([^-]+))?$/;
            let instance_id = re.exec(getId('username', func))[1]
            instanceCss = 'telecom2';
            if(config.themes && instance_id in config.themes)
            instanceCss = config.themes[instance_id];
            
        } else {
            instanceCss = 'telecom2';
        }

        var div = document.createElement("div");
        div.style.width = "100%";
        div.style.height = "100%";
        div.style.background = "grey";
        div.style.zIndex = "999";
        div.style.position = "absolute";
        div.style.top = "0px";
        div.style.left = "0px";
        div.id = 'loading';
        
        document.getElementById("card-body").appendChild(div);

        var head  = document.getElementsByTagName('head')[0];
        var link  = document.createElement('link');
        link.id   = 'myCss';
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = window.location.origin + '/assets/templates/' + instanceCss + '/css/style.css';
        link.media = 'all';
        head.appendChild(link);

        document.querySelectorAll('link[id^="theme-prefetch"]').forEach(item => item.remove());

        document.querySelectorAll('link[id^="current-theme"]').forEach(item => item.remove());

        
        const initResult = await this.init();


        const subscribeResult = await this.subscriberStreaming(func).catch(async (error) => {
            log.fatal("%serror:%s", func, error);
            await this.handleErrorEvent(error.message, func);
            return;
        });
        if (!subscribeResult) {
            log.fatal("%s!subscribeResult error", func);
            await this.handleErrorEvent(errors.SUBSCRIBE_AUTH, func);
            return;
        }
        //setState({ streaming: false, cameraClass: "visible" }, null, this, func);
        //setState({ cameraClass: "visible" }, null, this, func);
        //await this.unsubscribeStreaming(func, true);
        if (initResult) {
            const roomList = await this.getRoomList(initResult);
            log.trace("%sroomList:%s", func, roomList);

            if (roomList.response === constants.RESULT_SUCCESS) {
                this.modalOpen();
            } else {
                await this.handleError(roomList.message, func);
            }
        }
        //important to allow ping already after the init, due we don't have auth data before init
        setPingReady(true, func);
        if (ch.getHandler() === constants.CHAT_HANDLER_MYSQL) {
            log.trace("%sconfig.refresh.refreshChat:%s", func, getId('username'));
            if (this.refreshTimer === 0) {
                const schedulerData = {
                    type: constants.SCHEDULER_REFRESH_CHAT_CUSTOMER,
                    refreshRate: config.refresh.refreshChat,
                }
                const scheduler = new Scheduler(schedulerData, this.refreshChat);
                this.refreshTimer = scheduler.getTimer();
                log.debug('%sRefresh chat scheduler started:%s', func, scheduler);
            }
        }

    } //componentDidMount

    /**
     * boot process auth user, get room list
     */
    async init() {
        const func = "init(),Red5Streaming.jsx,";
        let retVal = { response: constants.RESULT_ERROR, message: null };
        Swal.fire({
            title: '',
            text: 'Loading..',
            icon: 'success',
            showConfirmButton: false,
            showCancelButton: false,
            showDenyButton: false
        });
        const authResult = await this.auth()
            .catch((error) => {
                log.fatal("%sauth error:%s", func, error);
                retVal.message = error.message;
            });

        if (!authResult) {
            Swal.close();
            await this.handleErrorEvent(retVal.message, func);
            return null;
        }

        log.debug("%sAuth result:%s", func, authResult);

        if (authResult.response === constants.RESULT_ERROR) {
            Swal.close();
            await this.handleErrorEvent(authResult.message, func);
            return null;
        }
        this.setState({ stream_name: authResult.message.stream_name });
        document.getElementById('loading').remove();
        //we have room id already let's autoconnect the customer
        //instead of select room from a popup

        if (authResult.message.room_id > 0) {
            Swal.close();
            //we need to wait approve/reject by customer
            let data = {
                auth_token: getId("auth_token", func),
                streaming_id: getId("streaming_id", func),
                stream_name: this.state.stream_name,
                room_id: this.state.room_id,
                status: constants.CONNECTION_ACCEPTED,
                caller: func,
            };
            log.trace("%sdata:%s", func, data);

            data["status"] = constants.CONNECTION_WAIT_FOR_CUSTOMER_APPROVAL;
            await this.props.dispatch(statusUpdate(data));


            log.trace("%sROOM_ID:%s", func, authResult.message.room_id);

            if (!authResult.message.moderator) {

                if (authResult.message.room_name === constants.PRIVATE_ROOM) {
                    let data = {
                        auth_token: getId("auth_token", func),
                        streaming_id: getId("streaming_id", func),
                        stream_name: authResult.message.stream_name,
                        room_id: authResult.message.room_id,
                        status: constants.CONNECTION_CLOSED_BY_PRIVATE,
                        caller: func,
                    };
                    log.debug("%sPerformer in private room,connection closed by this event:%s", func, data);
                    await this.props.dispatch(statusUpdate(data));
                    await this.handleErrorEvent(errors.PERFORMER_IN_PRIVATE_ROOM, func);
                    return null;
                } else {
                    //alert(JSON.stringify(authResult.message));
                    let text = `The performer is currently in a ${authResult.message.performer_room_info} room, would you like to join?`;
                    if (authResult.message.room_name === constants.FREE_ROOM) {
                        text = `The performer is currently in a ${authResult.message.room_name} room, would you like to join?`;
                    }
                    const approvalData = {
                        title: "",
                        text: text,
                        buttons: {
                            ok: "Yes",
                            cancel: "No"
                        },

                    };

                    const result = await joinApproval(approvalData).catch((error) => {
                        log.debug("%sJoin error:%s", func, error);

                    });
                    if (!result) {
                        await this.handleErrorEvent(errors.JOIN_TO_ROOM_REJECTED, func);
                        return null;
                    } else {
                        this.enterRoomHandler(authResult.message.room_id, false, true);
                        return null;
                    }

                }
            }

            if (authResult.message.moderator) {
                this.enterRoomHandler(authResult.message.room_id, false, true);
                this.moderatorConnect(authResult.message.room_id);
                return null;
            } else {
                this.enterRoomHandler(authResult.message.room_id, false, false);
            }
            return authResult.message;
        } else if(getId("roomId", func)) {
            Swal.close();
            this.enterRoomHandler(getId("roomId", func), true, false);
            return null;
        }


        if (authResult.message.moderator) {
            await this.handleErrorEvent(errors.MODERATOR_WAIT, func);
            return null;
        }

        Swal.close();
        return authResult.message;
    }//init

    /**
     * authenticate the user by username and performer from localstorage
     * @param {void} void
     * @return {JSON} see in example
     * @example
     * Result Success:
     * --------------
     * authResult:
     * {
     *  "response":"Success",
     *  "message":
     *    {
     *      "auth_token":"368956490c932c65e417a01561286b915c624fe4db5088f91678bb111291ca331965396530554205c624fe4db5088f91678bb111291ca33242525c624fe4db5088f91678bb111291ca33101055c624fe4db5088f91678bb111291ca3312",
     *      "streaming_id":"7d90dbae-62ea-11eb-bc34-39145808f34b",
     *      "stream_name":"83bb6e24-997f-4728-aa88-c0299d9b342e",
     *      "room_id":2456 //room_id can be NULL
     *    }
     * }
     */
    auth() {
        const func = "auth(),Red5Streaming.jsx,";
        log.trace("%sauth..start..", func);
        let retVal = { response: constants.RESULT_ERROR, message: null };
        if (getShutDown(func)) {
            return;
        }
        return new Promise(async (resolve, reject) => {
            //these two params comes as URL parameters live-streaming/:performer/:username"
            //see Router.js
            const username = getId("username", func);
            const performer = getId("performer", func);

            log.trace("%susername:%s", func, username);
            log.trace("%sperformer:%s", func, performer);

            if ((username === undefined) || (username === "")) {
                const errorMessage = "id username is missing!";
                log.fatal("%s%s", func, errorMessage);
                retVal.message = errorMessage;
                return reject(retVal);
            }

            if ((performer === undefined) || (performer === "")) {
                const errorMessage = "id performer is missing!";
                log.fatal("%s%s", func, errorMessage);
                retVal.message = errorMessage;
                return reject(retVal);
            }

            const authData = {
                username: username,
                performer: performer,
            };
            log.trace("%sauthData:%s", func, authData);
            const customerAuthResult = await this.props.dispatch(CustomerAuth(authData));
            log.debug("%sCustomer auth result:%s", func, customerAuthResult);
            if (!customerAuthResult) {
                retVal = { response: constants.RESULT_ERROR, message: errors.CUSTOMER_AUTH };
                log.error("%sretVal:%s", func, retVal);
                return reject(retVal);
            }

            if (customerAuthResult.response === constants.RESULT_ERROR) {
                retVal = { response: constants.RESULT_ERROR, message: customerAuthResult.message };
                log.error("%sretVal:%s", func, retVal);
                return reject(retVal);
            }

            if (!customerAuthResult.message.stream_name) {
                retVal = { response: constants.RESULT_ERROR, message: errors.STREAM_NAME_IS_NULL };
                log.fatal("%sretVal:%s", func, retVal);
                return reject(retVal);
            }

            setId('username', customerAuthResult.message.username, func);
            setId('stream_name', customerAuthResult.message.stream_name, func);
            setId('streaming_id', customerAuthResult.message.streaming_id, func);
            setId('auth_token', customerAuthResult.message.auth_token, func);
            setId('red5_token', customerAuthResult.message.red5_token, func);
            setId('performer_username', customerAuthResult.message.performer_username, func);

            const result =
            {
                auth_token: customerAuthResult.message.auth_token,
                streaming_id: customerAuthResult.message.streaming_id,
                stream_name: customerAuthResult.message.stream_name,
                room_id: customerAuthResult.message.room_id,
                moderator: customerAuthResult.message.moderator,
                room_name: customerAuthResult.message.room_name,
                performer_room_info: customerAuthResult.message.performer_room_info,
            }
            retVal = { response: constants.RESULT_SUCCESS, message: result };
            log.info("%sCustomer authentication is successful:%s", func, retVal);
            return resolve(retVal);
        });
    }//auth

    /**
     * get list of available rooms
     * @param  {JSON} data
     * @example
     * data:
     * {
     *  "auth_token":"368956490c932c65e417a01561286b915c624fe4db5088f91678bb111291ca331965406680016205c624fe4db5088f91678bb111291ca33242525c624fe4db5088f91678bb111291ca33101055c624fe4db5088f91678bb111291ca3312",
     *  "streaming_id":"7d90dbae-62ea-11eb-bc34-39145808f34b",
     *  "stream_name":"f89b08c0-206c-4764-a20e-37e1cfcb6699"
     * }
     * Return success:
     * ---------------
     * {
     *  "response":"Success",
     *  "message":[
     * {
     *  "room_type":"FREE",
     *  "cost":null,
     *  "room_id":21956
     * },
     * {
     *  "room_type":"GROUP",
     *  "cost":1.99,"room_id":21957
     * },
     * {
     *  "room_type":"PRIVATE",
     *  "cost":2.99,
     *  "room_id":21958
     * }
     * ]
     * }
     */
    getRoomList(data) {
        const func = "getRoomList(),Red5Streaming.jsx,";
        log.trace("%sdata:%s", func, data);
        if (getShutDown(func)) {
            return;
        }

        return new Promise(async (resolve, reject) => {
            if (!data.stream_name) {
                return reject(errors.STREAM_NAME_IS_NULL);
            }
            const result = await this.props.dispatch(roomListing(data)).catch((error) => {
                log.fatal("%s.catch(roomListing),error:%s", func, error);
            });
            log.trace("%sresult:%s", func, result);

            if ((!result) || (result.response === constants.RESULT_ERROR)) {
                log.fatal("%sresult:%s", func, result);
                return reject(result);
            }
            log.debug("%sGet roomlist result,successful:%s", func, result);
            //ATTILA this.setState({ stream_name: data.stream_name });
            return resolve(result);
        });
    }//getRoomList

    /**
     * refresh chat from chat table
     * @param  {obj} vThis current object 
     * @param  {string} caller caller function
     */
    refreshChat = async (data) => {
        const func = "refreshChat(" + data.caller + "),Red5Streaming.jsx,";
        if (getShutDown(func)) {
            log.trace("%sgetShutDown fired..:%s", func);
            return;
        }

        const username = getId('username', func);
        const stream_name = getId('stream_name', func);
        log.trace("%sstream_name:%s", func, stream_name);
        log.trace("%susername:%s", func, username);

        if (this.chatId) {
            this.chatDirection = constants.CHAT_DIRECTION_DOWN;
        }

        const pullData =
        {
            id: this.chatId,
            direction: this.chatDirection,
            stream_name: stream_name,
            caller: func,
        }
        log.trace("%spullData:%s", func, pullData);
        let errorMessage = null;
        const result = await ch.pull(pullData).catch((error) => {
            errorMessage = error.message;
            log.fatal("%serror:%s", func, error);
        })
        log.trace("%sresult:%s", func, result);
        if (!result) {
            return;
        }

        if (!result.length) {
            return;
        }

        if (result.response === constants.RESULT_ERROR) {
            errorMessage = result.message;
        }

        if (errorMessage) {
            log.fatal("%serror:%s", func, errorMessage);
            this.setState({ readError: errorMessage });
            displayPopup(errorMessage, func);
            return;
        }

        result.forEach((snap) => {
            log.trace('%ssnap:%s', func, snap);
            if (snap.value.id > this.chatId) {
                this.chatId = snap.value.id;
            }

            let data = {};

            switch (snap.value.type) {
                case constants.CHAT_ACCEPTED:
                case constants.CHAT_AUTO_ACCEPTED:
                    data = {
                        allow_subscriber_permission_event: username,
                        type: snap.value.type
                    };
                    break;
                case constants.CHAT_DENIED:
                    if (snap.value.to_username === username) {
                        data = { deny_subscriber_permission_event: username };
                    }
                    break;
                case constants.CHAT_KILL_OLD_CONNECTION:
                    if (snap.value.to_username.toString() === username) {
                        data = { kill_old_connection_id: snap.value.content };
                        log.trace('%sCHAT_KILL_OLD_CONNECTION:%s', func, data);
                    }
                    break;

                case constants.CHAT_BLOCKED:
                    if (snap.value.to_username === username) {
                        data = { block_subscriber: username };
                    }
                    break;
                case constants.CHAT_PERFORMER_EXIT:
                    data = { performer_exit_event: username };
                    break;
                case constants.CHAT_PERFORMER_UNAVAILABLE:
                    data = { performer_unavailable_event: username };
                    break;
                case constants.CHAT_PERFORMER_IN_PRIVATE:
                    data = { performer_in_private_event: username };
                    break;
                case constants.CHAT_JOIN:
                case constants.CHAT_LEAVE:
                case constants.CHAT_DONATION:
                case constants.CHAT_WAIT_FOR_APPROVAL:
                    break;
                case constants.CHAT_MESSAGE:
                    break;
                case constants.CHAT_TWO_WAY_CAM_REQUEST:
                    if (snap.value.to_username === username) {
                        data = {
                            two_way_cam_request: username,
                            two_way_cam_request_stream_name: snap.value.content,
                            two_way_cam_request_uid: snap.value.uid
                        };
                    }
                    break;
                case constants.CHAT_CONNECT_DIRECTLY_TO_ROOM:
                    if (snap.value.to_username === username) {
                        data = {
                            connect_directly_to_room: username,
                            connect_directly_to_room_content: JSON.parse(snap.value.content)
                        };
                    }
                    break;
                default:
                    log.fatal("%sinvalid chat type received:%s", func, snap.value.type);
                    break;
            }//switch
            //run just if any events match with current user

            if (data !== {}) {
                log.trace('%scall processChatProps:%s', func, data);
                this.processChatProps(data, func);
            }
        });//snap
    }//refreshChat


    /**
     * @param  {} newProps received new properties
     * @param  {} caller caller function
     */
    processChatProps(newProps, caller) {
        const func = "processChatProps(" + caller + "),Red5Streaming.jsx,";
        log.trace("%sexecuting is started,newProps:%s", func, newProps);
        if (getShutDown(func)) {
            return;
        }
        const username = getId("username", func);
        log.trace("%susername:%s", func, username);
        log.trace("%sblock_subscriber:%s", func, newProps.block_subscriber);
        if (newProps.block_subscriber !== null) {
            if (newProps.block_subscriber === username) {
                this.setState(
                    {
                        blockusername: newProps.block_subscriber,
                    },
                    () => {
                        this.props.dispatch(blockSubscriberEvent(null));
                        if (this.state.blockstatus === false) {
                            this.blockStreamingHandler();
                        }
                    });
                log.debug("%sCustomer is banned,this.state:%s", func, this.state);
            }
        }
        log.trace("%sallow_subscriber_permission_event:%s", func, newProps.allow_subscriber_permission_event);
        if (newProps.allow_subscriber_permission_event !== null) {
            if (newProps.allow_subscriber_permission_event === username) {
                this.setState(
                    {
                        allowSubscriberPermissionUsername:
                            newProps.allow_subscriber_permission_event,
                    },
                    () => {
                        this.props.dispatch(allowSubscriberPermissionEvent(null));
                        if (this.state.allowSubscriberPermissionStatus === false) {
                            this.allowSubscriberPermissionHandler(newProps);
                        }
                    });
                log.debug("%sCustomer connection is approved,this.state:%s", func, this.state);
            }
        }
        if (newProps.deny_subscriber_permission_event !== null) {
            log.trace("%sdeny_event:%s", func, newProps.deny_subscriber_permission_event);
            log.trace("%susername:%s", func, username);
            if (newProps.deny_subscriber_permission_event === username) {
                this.setState(
                    {
                        denySubscriberPermissionUsername:
                            newProps.deny_subscriber_permission_event,
                    },
                    () => {
                        this.props.dispatch(denySubscriberPermissionEvent(null));
                        if (this.state.denySubscriberPermissionStatus === false) {
                            this.denySubscriberPermissionHandler();
                        }
                    });
                log.debug("%sCustomer connectio is denied by the performer,this.state:%s", func, this.state);
            }
        }//deny subscriber

        //=== performer_exit_event
        if (newProps.performer_exit_event !== null) {
            log.trace("%sperformer_exit_event:%s", func, newProps.performer_exit_event);
            log.trace("%susername:%s", func, username);
            if (newProps.performer_exit_event === username) {
                //kickout all users due performer exit
                this.performerStopHandler();
            }
        }//performer_exit_event

        //=== performer_unavailable_event
        if (newProps.performer_unavailable_event !== null) {
            log.trace("%performer_unavailable_event:%s", func, newProps.performer_unavailable_event);
            log.trace("%susername:%s", func, username);
            if (newProps.performer_unavailable_event === username) {
                //kickout all users due performer exit
                this.performerUnavailableHandler();
            }
        }//performer_unavailable_event

        //=== performer_in_private_event
        if (newProps.performer_in_private_event!== null) {
            log.trace("%performer_in_private_event:%s", func, newProps.performer_in_private_event);
            log.trace("%susername:%s", func, username);
            if (newProps.performer_in_private_event === username) {
                //kickout all users due performer exit
                this.performerInPrivateHandler();
            }
        }//performer_in_private_event

        //==== kill old connection event
        if ((newProps.kill_old_connection_id !== null) && (newProps.kill_old_connection_id !== undefined)) {
            const connection_id = getId("connection_id");
            log.trace("%skill_old_connection_id:%s", func, newProps.kill_old_connection_id);
            log.trace("%susername:%s", func, username);
            log.trace("%sconnection_id:%s", func, connection_id);
            if (newProps.kill_old_connection_id === connection_id) {
                this.newConnectionKickHandler();
            }
        }//

        if ((newProps.two_way_cam_request !== null) && (newProps.two_way_cam_request !== undefined)) {
            log.trace("%stwo_way_cam_request:%s", func, newProps.two_way_cam_request);

            if (newProps.two_way_cam_request === username) {
                this.setState(
                    {
                        twoWayCamRequestUsername:
                            newProps.two_way_cam_request,
                    },
                    () => {
                        this.props.dispatch(twoWayCamRequestEvent(null));
                        const c2cStreamName = getId(constants.C2C_STREAM_NAME, func, true);
                        log.trace("%sc2cStreamName:%s", func, c2cStreamName);
                        if (c2cStreamName === null || c2cStreamName === undefined) {
                            this.twoWayCamRequestHandler(newProps.two_way_cam_request_stream_name, newProps.two_way_cam_request_uid);
                        }
                    });
                log.debug("%sTwoWayCam request received,this.state:%s", func, this.state);
            }
        }//two_way_cam_request

        if ((newProps.connect_directly_to_room !== null) && (newProps.connect_directly_to_room !== undefined)) {
            log.trace("%sconnect_directly_to_room:%s", func, newProps.connect_directly_to_room);

            if (newProps.connect_directly_to_room === username) {
                this.setState(
                    {
                        connectDirectlyToRoomUsername:
                            newProps.connect_directly_to_room,
                    },
                    () => {
                        this.props.dispatch(connectDirectlyToRoomEvent(null));
                        this.connectDirectlyToRoomHandler(newProps.two_way_cam_request_stream_name, newProps.two_way_cam_request_uid,newProps.connect_directly_to_room_content);
                    });
                log.debug("%sTwoWayCam request received,this.state:%s", func, this.state);
            }
        }//two_way_cam_request

    } //processChatProps

    /**
     * @param  {} newProps received new properties
     */
    UNSAFE_componentWillReceiveProps(newProps) {
        const func = "UNSAFE_componentWillReceiveProps(),Red5Streaming.jsx,";
        log.trace("%sexecuting is started,newProps:%s", func, newProps);
        if (getShutDown(func)) {
            return;
        }

        if (newProps.room_listing !== undefined) {
            setState({ roomListingResult: newProps.room_listing }, null, this, func);
        }
        if (newProps.room_info !== undefined) {
            setState({ room_info: newProps.room_info }, null, this, func);
        }

        this.processChatProps(newProps, func);
    } //UNSAFE_componentWillReceiveProps

    /**
     * handle allow subscription by performer green thumb click
     */
    allowSubscriberPermissionHandler = async (params) => {
        const func = "allowSubscriberPermissionHandler(),Red5Streaming.jsx,";
        log.trace("%sparams%s", func, params);
        if (getShutDown(func)) {
            return;
        }
        log.trace("%sthis.state-1:%s", func, this.state);
        //allow this earlier to avoid background close
        //setPingReady(true, func);
        let donations = false;

        if(typeof config.donations != "undefined"){
            let re = /(?:-([^-]+))?$/;
            let instance_id = re.exec(getId('username', func))[1]
            donations = false;
            if(config.donations && instance_id in config.donations)
                donations = config.donations[instance_id];
            
        } else {
            donations = true;
        }

        this.setState(
            {
                allowSubscriberPermissionStatus: true,
                subscriberChat: true,
                donationButtons: donations,
            },
            async () => {
                const username = getId("username", func);
                log.trace("%sthis.state-2:%s", func, this.state);
                if (this.state.allowSubscriberPermissionUsername === username) {

                    let data = {
                        auth_token: getId("auth_token", func),
                        streaming_id: getId("streaming_id", func),
                        stream_name: this.state.stream_name,
                        room_id: this.state.room_id,
                        status: constants.CONNECTION_ACCEPTED,
                        caller: func,
                    };
                    log.debug("%sRoom updated after connection is approved,data:%s", func, data);
                    //this.props.dispatch(statusUpdate(data));
                    await this.props.dispatch(roomUpdate(data));
                    log.trace("%sthis.state:%s", func, this.state);

                    if (params.type === constants.CHAT_ACCEPTED) {

                        const updateData = {
                            auth_token: getId("auth_token", func),
                            streaming_id: getId("streaming_id", func),
                            stream_name: this.state.stream_name,
                            uid: getId("performer", func),
                            status: true,
                            type: constants.CHAT_ACCEPTED,
                            to_username: getId("username", func),
                            username: getId("performer_username", func),
                            caller: func,
                            
                        };

                        log.debug('%sConnection is approved,update chat data:%s', func, updateData);
                        const result = await ch.update(updateData).catch((error) => {
                            log.fatal("%serror:%s", func, error);
                        })
                        log.debug("%sUpdate result:%s", func, result);
                        if (result.response === constants.RESULT_ERROR) {
                            log.fatal("%sresult.response:%s", func, result.response);
                        }
                    }//if accepted
                    if (params.type === constants.CHAT_AUTO_ACCEPTED) {
                        const updateData = {
                            auth_token: getId("auth_token", func),
                            streaming_id: getId("streaming_id", func),
                            stream_name: this.state.stream_name,
                            uid: getId("performer", func),
                            status: true,
                            type: constants.CHAT_AUTO_ACCEPTED,
                            to_username: getId("performer_username", func),
                            username: getId("username", func),
                            caller: func,
                        };
                        log.debug("%sAuto accepted,update chat data:%s", func, updateData);
                        const result = await ch.update(updateData).catch((error) => {
                            log.fatal("%serror:%s", func, error);
                        })
                        log.debug("%sUpdate result:%s", func, result);
                        if (result.response === constants.RESULT_ERROR) {
                            log.fatal("%sresult.response:%s", func, result.response);
                        }

                    }


                    if (!this.state.room_info) {
                        await this.StopStreaming(errors.ROOM_UPDATE_FAILED, func, true);
                        return;
                    }



                    let room_info = this.state.room_info.message;
                    log.debug("%sRoom info:%s", func, room_info);

                    if (this.state.room_info.response === constants.RESULT_ERROR) {
                        if (room_info === errors.NO_CONNECTIONS_FOUND) {
                            room_info = errors.PERFORMER_IN_PRIVATE_ROOM;
                        }
                        await this.StopStreaming(room_info, func, true);
                        return;
                    }

                    if (room_info.customer_choice !== room_info.performer_choice) {
                        log.trace("%sperformer <> customer choice", func);
                        if (room_info.performer_info.room_name === constants.PRIVATE_ROOM) {
                            log.trace("%sthis.state.room_info.performer_info.room_name:%s", func, room_info.performer_info.room_name);
                            log.trace("%sconstants.PRIVATE_ROOM:%s", func, constants.PRIVATE_ROOM);
                            // data["status"] = constants.CONNECTION_CLOSED_BY_PRIVATE;
                            // this.props.dispatch(statusUpdate(data));
                            // this.handleErrorEvent(errors.PERFORMER_IN_PRIVATE_ROOM, func);
                            //this.StopStreaming(errors.JOIN_TO_ROOM_REJECTED, func, true);
                            await this.StopStreaming(errors.PERFORMER_IN_PRIVATE_ROOM, func, true);
                            return;
                        }

                        //we need to wait approve/reject by customer
                        data["status"] = constants.CONNECTION_WAIT_FOR_CUSTOMER_APPROVAL;
                        await this.props.dispatch(statusUpdate(data));
                        const approvalData = {
                            title: errors.YOUR_CHOICE_OF_ROOM_NOT_AVAILABLE,
                            text: `your choice was a ${room_info.customer_choice} room,however the only room available is a ${room_info.performer_choice} room.
                 \n\nPress ok to accept this room choice`,
                            buttons: {
                                ok: "Ok",
                                cancel: "Cancel"
                            },

                        };
                        const result = await joinApproval(approvalData).catch((error) => {
                            log.debug("%sJoin error:%s", func, error);
                        });
                        if (result) {
                            //why credit check is here ?
                            //if customer selected first free room, it is bypass the credit check and allow to join
                            //if performer select group or private room and accept this join, customer able to connect
                            //with zero balance, ok it will goes out after 10 seconds , but better to not connect at all.            let errorMessage = null;
                            let errorMessage = "";
                            const apiUrl = BaseUrl + constants.A_CREDIT_CHECK;
                            const creditCheckData = {
                                room_id: room_info.performer_info.id,
                                auth_token: getId("auth_token", func),
                                streaming_id: getId("streaming_id", func),
                                username: getId("username", func),
                                caller: func,

                            };
                            log.debug("%sCredit check parameters:%s", func, creditCheckData);

                            const creditCheck = await apiCallAwait(apiUrl, 'POST', creditCheckData).catch((error) => {
                                log.fatal("%serror:%s", func, error);
                                errorMessage = error.message;
                            })
                            log.debug("%sCredit check result:%s", func, creditCheck);
                            if (errorMessage) {
                                await this.handleErrorEvent(errorMessage, func);
                                return;
                            }
                            if (creditCheck.response === constants.RESULT_ERROR) {
                                await this.handleErrorEvent(creditCheck.message, func);
                                return;
                            }


                            data["status"] = constants.CONNECTION_ACCEPTED;
                            await this.props.dispatch(statusUpdate(data));

                            const updateData = {
                                auth_token: getId("auth_token", func),
                                streaming_id: getId("streaming_id", func),
                                stream_name: this.state.stream_name,
                                uid: getId("performer", func),
                                status: true,
                                type: constants.CHAT_AUTO_ACCEPTED,
                                to_username: getId("performer_username", func),
                                username: getId("username", func),
                                caller: func,
                            };

                            log.debug('%sAuto accepted-2,update chat:%s', func, updateData);
                            const result = await ch.update(updateData).catch((error) => {
                                log.fatal("%serror:%s", func, error);
                            })
                            log.debug("%sResult of update:%s", func, result);
                            if (result.response === constants.RESULT_ERROR) {
                                log.error("%sresult.response:%s", func, result.response);
                            }
                            setState({
                                cameraClass: "visible",
                                waitingbuttonstatus: false,
                                waitingForApproval: false
                            }, null, this, func);
                            this.subscriber.unmute();
                            this.subscriber.play();

                        } else {
                            //true parameter means no charge here
                            await this.StopStreaming(errors.JOIN_TO_ROOM_REJECTED, func, true);
                            return;
                        }
                    }
                    else {
                        setState({
                            cameraClass: "visible",
                            waitingbuttonstatus: false,
                            waitingForApproval: false
                        }, null, this, func);
                        this.subscriber.unmute();
                        this.subscriber.play();
                    }
                }
            }
        );
        
        log.info('%s%s customer received an approval from %s', func, getId("username", func), getId("performer_username", func));
    }; //allowSubscriberPermissionHandler

    /**
     */
    denySubscriberPermissionHandler = async () => {
        const func = "denySubscriberPermissionHandler(),Red5Streaming.jsx,";
        log.debug("%sexecuting is started..%s", func);


        const updateData = {
            auth_token: getId("auth_token", func),
            streaming_id: getId("streaming_id", func),
            stream_name: this.state.stream_name,
            uid: getId("performer", func),
            status: true,
            type: constants.CHAT_DENIED,
            to_username: getId("username", func),
            username: getId("performer_username", func),
            caller: func,
        };

        log.debug('%sDeny subscriber, update data:%s', func, updateData);
        const result = await ch.update(updateData).catch((error) => {
            log.fatal("%serror:%s", func, error);
        })
        log.trace("%sresult:%s", func, result);
        if (result.response === constants.RESULT_ERROR) {
            log.error("%sresult.response:%s", func, result.response);
        }


        if (getShutDown(func)) {
            return;
        }
        this.setState(
            {
                denySubscriberPermissionStatus: true,
            },
            async () => {
                const username = getId("username", func);
                log.trace("%sthis.state:%s", func, this.state);
                if (this.state.denySubscriberPermissionUsername === username) {
                    if (this.refreshTimer) clearInterval(this.refreshTimer);
                    this.refreshTimer = 0;
                    await this.handleErrorEvent(errors.DENIED_BY_PUBLISHER, func);

                }
            }
        );
    }; //denySubscriberPermissionHandler

    /**
    */
    performerStopHandler = async () => {
        const func = "performerStopHandler(),Red5Streaming.jsx,";
        await this.handleErrorEvent(errors.STREAMING_IS_STOPPED_BY_PERFORMER, func);
    } //performerStopHandler

    newConnectionKickHandler = async () => {
        const func = "newConnectionKickHandler(),Red5Streaming.jsx,";
        await this.handleErrorEvent(errors.CONNECTION_CLOSED_BY_NEW_CONNECTION, func);
    } //newConnectionKickHandler

    performerUnavailableHandler = async () => {
        const func = "performerUnavailableHandler(),Red5Streaming.jsx,";
        await this.handleErrorEvent(errors.STREAMING_IS_STOPPED_BY_PERFORMER_UNAVAILABLE, func);
    } //performerUnavailableHandler

     performerInPrivateHandler = async () => {
        const func = "performerInPrivateHandler(),Red5Streaming.jsx,";
        await this.handleErrorEvent(errors.STREAMING_IS_STOPPED_BY_PERFORMER_IN_PRIAVTE, func);
    } //performerUnavailableHandler


    twoWayCamRequestHandler = async (stream_name, uid) => {
        const func = "twoWayCamRequestHandler(),Red5Streaming.jsx,";
        log.trace("%sexecuting is started,stream_name:%s", func, stream_name);
        if (getShutDown(func)) {
            return;
        }

        const updateData = {
            auth_token: getId("auth_token", func),
            streaming_id: getId("streaming_id", func),
            stream_name: this.state.stream_name,
            uid: getId("performer", func),
            status: true,
            type: constants.CHAT_TWO_WAY_CAM_REQUEST,
            to_username: getId("username", func),
            username: getId("performer_username", func),
            caller: func,
        };

        log.trace('%sTwoWay cams request update data:%s', func, updateData);
        const result = await ch.update(updateData).catch((error) => {
            log.fatal("%serror:%s", func, error);
        })
        log.trace("%sresult:%s", func, result);
        if (result.response === constants.RESULT_ERROR) {
            log.error("%sresult.response:%s", func, result.response);
        }

        const username = getId("username", func);
        log.trace("%sthis.state:%s", func, this.state);
        if (this.state.twoWayCamRequestUsername === username) {
            const result = await twoWayCamApproval().catch((error) => {
                log.debug("%serror:%s", func, error);
            });
            log.trace("%sresult:%s", func, result);
            if (result.isConfirmed) {
                log.debug("%sRequest confirmed by customer", func);
                customerPopup(stream_name);
            }
            else {
                //TODO C2C send info to performer from cancel
                log.trace("%scancel", func);
                const data = {
                    stream_name: stream_name,
                    performer: getId("performer", func),
                    username: getId("username", func),
                    streaming_id: getId("streaming_id", func),
                    auth_token: getId("auth_token", func),
                    status: constants.C2C_CANCEL,
                    caller: func,
                }

                log.trace("%sCreate stream for 2waycams request:%s", func, data);

                const apiUrl = `${BaseUrl}${constants.A_C2C_CREATE_STREAM}`;
                log.trace("%sapiUrl:%s", func, apiUrl);
                const createResult = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
                    log.fatal("%serror:%s", func, error);
                })
                log.trace("%sCreate stream result of 2waycams request:%s", func, createResult);

            }
        } else {

            return;
        }
    }//twoWayCamRequestHandler

    connectDirectlyToRoomHandler = async (stream_name, uid, content) => {
        const func = "connectDirectlyToRoomHandler(),Red5Streaming.jsx,";
        if (getShutDown(func)) {
            return;
        }

        const updateData = {
            auth_token: getId("auth_token", func),
            streaming_id: getId("streaming_id", func),
            stream_name: this.state.stream_name,
            uid: getId("performer", func),
            status: true,
            type: constants.CHAT_CONNECT_DIRECTLY_TO_ROOM,
            to_username: getId("username", func),
            username: getId("performer_username", func),
            caller: func,
        };


        log.trace('%sconnect directly to room request update data:%s', func, updateData);
        const result = await ch.update(updateData).catch((error) => {
            log.fatal("%serror:%s", func, error);
        })
        log.trace("%sresult:%s", func, result);
        if (result.response === constants.RESULT_ERROR) {
            log.error("%sresult.response:%s", func, result.response);
        }
        setId("roomId", content.room_id, func);
        const username = getId("username", func);
        log.trace("%sthis.state:%s", func, this.state);
        if (this.state.connectDirectlyToRoomUsername === username) {
            const result = await connectDirectlyToRoomApproval().catch((error) => {
                log.debug("%serror:%s", func, error);
            });
            log.trace("%sresult:%s", func, result);
            if (result.isConfirmed) {
                log.debug("%sRequest confirmed by customer", func);
                //hit node with the removal function
                
                //credit check start
                const apiUrl = BaseUrl + constants.A_CONNECT_DIRECT_TO_ROOM;
                log.trace("%sapiUrl:%s", func, apiUrl);
                const data = {
                    room_id: getId("roomId", func),
                    auth_token: getId("auth_token", func),
                    streaming_id: getId("streaming_id", func),
                    username: getId("username", func),
                    stream_name: getId("stream_name", func),
                    caller: func,

                };
                log.trace("%sdata:%s", func, data);
                //check credit if not moderator log in
                    let errorMessage = null;
                    const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
                        log.fatal("%serror:%s", func, error);
                        errorMessage = error.message;
                    })
                    log.trace("%screditCheck:%s", func, result);
                    if (errorMessage) {
                        await this.handleErrorEvent(errorMessage, func);
                        return;
                    }
                    if (result.response === constants.RESULT_ERROR) {
                        await this.handleErrorEvent(result.message, func);
                        return;
                    }

                    log.trace("%sconfirmed", func);
                    //const redirectUrl = getId('customerBackUrl', func);
                    const redirectUrl = `/live-streaming/${getId('performer', func)}/${getId('username', func)}/${encodeURIComponent(getId('returnUrl', func))}?roomId=${result.message.room_id}`;
                    log.trace("%s,redirectUrl:%s", func, redirectUrl);
                    window.location.href = redirectUrl;

            }
            else {
                //TODO C2C send info to performer from cancel
                log.trace("%scancel", func);
                const data = {
                    stream_name: stream_name,
                    performer: getId("performer", func),
                    username: getId("username", func),
                    streaming_id: getId("streaming_id", func),
                    auth_token: getId("auth_token", func),
                    status: constants.C2C_CANCEL,
                    caller: func,
                }

                log.trace("%sCreate stream for 2waycams request:%s", func, data);

                const apiUrl = `${BaseUrl}${constants.A_C2C_CREATE_STREAM}`;
                log.trace("%sapiUrl:%s", func, apiUrl);
                const createResult = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
                    log.fatal("%serror:%s", func, error);
                })
                log.trace("%sCreate stream result of 2waycams request:%s", func, createResult);

            }
        } else {

            return;
        }
    }//twoWayCamRequestHandler


    /**
     */
    blockStreamingHandler = () => {
        const func = "blockStreamingHandler(),Red5Streaming.jsx,";
        log.trace("%sexecuting is started..", func);
        if (getShutDown(func)) {
            return;
        }

        this.setState(
            {
                blockstatus: true,
            },
            async () => {
                const username = getId("username", func);
                if (this.state.blockusername === username) {
                    const data = {
                        auth_token: getId("auth_token", func),
                        streaming_id: getId("streaming_id", func),
                        stream_name: this.state.stream_name,
                        status: constants.CONNECTION_BLOCK,
                        caller: func,
                    };
                    await this.props.dispatch(statusUpdate(data));

                    const updateData = {
                        auth_token: getId("auth_token", func),
                        streaming_id: getId("streaming_id", func),
                        stream_name: this.state.stream_name,
                        uid: getId("performer", func),
                        status: true,
                        type: constants.CHAT_BLOCKED,
                        to_username: getId("username", func),
                        username: getId("performer_username", func),
                        caller: func,
                    };

                    log.trace('%sBlock a customer,update data:%s', func, updateData);
                    const result = await ch.update(updateData).catch((error) => {
                        log.fatal("%serror:%s", func, error);
                    })
                    log.debug("%sBlock customer update result:%s", func, result);
                    if (result.response === constants.RESULT_ERROR) {
                        log.fatal("%sresult.response:%s", func, result.response);
                    }

                    log.trace("%scall StopStreaming:%s", func, this.state);
                    await this.StopStreaming(errors.BLOCKED_BY_PUBLISHER, func);
                }
            }
        );
        log.trace("%sthis.state:%s", func, this.state);
    }; //blockStreamingHandler

    /**
     */
    StartStreamingHandler = () => {
        const func = "StartStreamingHandler(),Red5Streaming.jsx,";
        if (getShutDown(func)) {
            return;
        }
        this.setState({ streaming: true }, () => {
            this.StartStreaming(func);
        });
        log.trace("%sthis.state:%s", func, this.state);
    }; //StartStreamingHandler

    /**
     * @param  {} message
     * @param  {} caller
     */
    async handleErrorEvent(message, caller) {
        const func = "handleErrorEvent(" + caller + "),Red5Streaming.jsx,";
        log.trace("%smessage:%s", func, message);

        //checkforRoomRejecton
        if(message === errors.CUSTOMER_MAX_CONNECTION_TIME || message === errors.CUSTOMER_MAX_CONNECTION_TIME_IN_PERIOD || message === errors.CUSTOMER_INSUFFICIENT_BALANCE)
        {
            const twspAlert = Swal.mixin(config.sweetAlertError);
            await twspAlert.fire('', message);
            return;
        }

        this.connectionTimeOutInterval = 0;
        clearInterval(this.connectionTimeOutInterval);
        setState({ streaming: false, room_id: "" }, null, this, func);
        if (this.refreshTimer) clearInterval(this.refreshTimer);
        this.refreshTimer = 0;
        const retVal = await this.unsubscribeStreaming(func);
        log.trace("%sretVal of unsubscribeStreaming:%s", func, retVal);
        let connectionStatus = constants.CONNECTION_CLOSED_BY_UNSUBSCRIBE;

        switch (message) {
            case errors.JOIN_TO_ROOM_REJECTED:
                connectionStatus = constants.CONNECTION_JOIN_TO_ROOM_REJECTED;
                break;
            case errors.BLOCKED_BY_PUBLISHER:
                connectionStatus = constants.CONNECTION_BLOCK;
                break;
            case errors.DENIED_BY_PUBLISHER:
                connectionStatus = constants.CONNECTION_DENIED;
                break;
            case errors.CUSTOMER_MAX_CONNECTION_TIME:
            case errors.CUSTOMER_MAX_CONNECTION_TIME_IN_PERIOD:
                connectionStatus = constants.CONNECTION_UNABLE_TO_JOIN_ROOM;
                break;

            default:
                break;
        }

        const params =
        {
            caller: func,
            test: false,
            status: connectionStatus,
        }

        const exitResult = await CleanExit(params);
        log.debug("%sClean exit result:%s", func, exitResult);
        const twspAlert = Swal.mixin(config.sweetAlertError);
        //catch was not recognised
        // const result = await twspAlert.fire('', message).catch((error) => {
        //     log.error("%s,twspAlert.fire,catch(error):%s", func, error.message);
        // });
        const result = await twspAlert.fire('', message);

        if (result.isConfirmed) {
            log.trace("%sconfirmed", func);
            log.trace("%sredirect here,message:%s", func, message);
            //const redirectUrl = getId('customerBackUrl', func);
            const redirectUrl = getId('returnUrl', func);
            //const redirectUrl = `/live-streaming/${getId('performer', func)}/${getId('username', func)}/${encodeURIComponent(getId('returnUrl', func))}`;//?roomId=21961&cunt=true
            log.trace("%s,redirectUrl:%s", func, redirectUrl);
            window.top.location.href = redirectUrl;
        }

        


    } //handleErrorEvent

    /**
     * count next time to next charge based on time update by red5
     */
    handleNextCharge() {
        const func = "handleNextCharge(),Red5Streaming.jsx,";
        if (getShutDown(func)) {
            return;
        }
        //we have to wait until first customer select a room
        log.trace("%sthis.state:%s", func, this.state);
        if (!this.state.room_id) return;
        //charging frequency is 10 seconds
        if (this.connectionTime.lastChargeStamp === 0) {
            this.connectionTime.lastChargeStamp = Date.now();
            this.connectionTime.lastCharge = new Date().toISOString();
            log.trace("%sFIRST CALL:%s", func, this.connectionTime.lastCharge);
        }
        const nextChargeStamp = this.connectionTime.lastChargeStamp + config.chargeFrequency;
        const now = Date.now();
        if ((nextChargeStamp) <= now) {
            if (this.streamingChargeHandler(constants.CHARGE_AT_LOOP, func)) {
                //charged okay, let's update 
                this.connectionTime.lastChargeStamp = Date.now();
                this.connectionTime.lastCharge = new Date().toISOString();
                log.trace("%scharge time,this.connectionTime:%s", func, this.connectionTime);
            }
        }
    }//handleNextCharge



    /**
     * update connection time 
     * @param  {string} caller
     */
    updateConnectionTime(caller, timeLeft = null) {
        const func = "updateConnectionTime(),Red5Streaming.jsx,";

        if (getShutDown(func)) {
            return;
        }

        this.connectionTime.timeStamp = Date.now();
        this.connectionTime.lastUpdate = new Date().toISOString();

        this.connectionTime.timeLeft = timeLeft;
        log.trace("%sthis.connectionTime:%s,timeLeft:%s,caller:%s", func, this.connectionTime, timeLeft, caller);
    }//updateConnectionTime

    /**
     * watch connection and if don't receive time update
     * start the reconnect and if it is not works then the exit process
     * @param  {} obj hold this object to reach class properties
     */
    handleConnectionTimeOut(obj) {
        const func = "handleConnectionTimeOut(),Red5Streaming.jsx,";
        if (getShutDown(func)) {
            return;
        }
        if (!config.red5.handleConnTimeOut) {
            log.trace("%shandleConnTimeOut false,return:%s", func, config.red5.handleConnTimeOut);
            return;
        }

        const now = Date.now();
        log.trace("%snow:%s,lastUpdate:%s", func, now, obj.connectionTime.lastUpdate);
        if (!obj.connectionTime.timeStamp) {
            //if connectionTime.timeStamp = 0 is no reason to execute
            //the function due we not receive yet any connection info from red5
            return;
        }
        log.trace("%snow:%s", func, now);
        const timeOutFire = obj.connectionTime.timeStamp + (config.red5.connTimeOut);
        const timeOutFireHuman = new Date(timeOutFire).toISOString();
        const nowHuman = new Date(now).toISOString();

        if (timeOutFire < now) {
            log.trace("%sthis.connectionTime:%s", func, obj.connectionTime);
            log.trace("%snow:%s,lastUpdate:%s", func, new Date().toISOString(), obj.connectionTime.lastUpdate);
            log.trace("%stimeOutFire:%s,now:%s", func, timeOutFireHuman, nowHuman);
            obj.setConnected(false, func);
        }
    }//handleConnectionTimeOut


    /**
     * try to reconnect to red5 server
     */
    async retryConnect() {
        const func = "retryConnect(),Red5Streaming.jsx,";
        if (getShutDown(func)) {
            return;
        }
        if (!config.red5.reConnect) {
            log.trace("%sreConnect false,return:%s", func, config.red5.reConnect);
            return;
        }
        log.trace("%sconfig.red5.repeatReConnect:%s", func, config.red5.repeatReConnect);
        clearTimeout(this.retryTimeout);
        if (!this.connected) {
            if (this.reConnectCounter < config.red5.repeatReConnect) {
                log.debug("%sReconnect did happen,reConnectCounter/repeatReConnect%s",
                    func, this.reConnectCounter, config.red5.repeatReConnect);
                this.retryTimeout = setTimeout(async () => {
                    await this.subscriberStreaming(func).catch(async (error) => {
                        log.fatal("%serror:%s", func, error);
                        await this.handleErrorEvent(error.message, func);
                        return;
                    });
                }, config.red5.reTimeOut);
                this.reConnectCounter = this.reConnectCounter + 1;
                log.trace("%sreConnectCounter%s", func, this.reConnectCounter);
            }
            else {
                await this.handleErrorEvent(`ReConnect Failed,${this.reConnectCounter} times!`, func);
            }
        }
    }//retryConnect

    /**
     * set connected property 
     * @param  {boolean} value
     */
    setConnected(value, caller) {
        const func = "setConnected(),Red5Streaming.jsx,";
        if (getShutDown(func)) {
            return;
        }
        this.connected = value;
        if (!this.connected) {
            log.debug("%sNOT CONNECTED,RETRY,caller:%s,this.connection.time:%s", func, caller, this.connectionTime);
            this.retryConnect();
        } else {
            this.updateConnectionTime(func);
            log.debug("%sCONNECTED,caller:%s,this.connection.time:%s", func, caller, this.connectionTime);
            log.trace("%sreConnectCounter%s", func, this.reConnectCounter);
            if (config.red5.resetReconnect) {
                this.reConnectCounter = 0;
                log.trace("%sreConnectCounter reseted%s", func, this.reConnectCounter);
            }
        }
    }//setConnected


    /**
     * this function contain subscriber event
     * @param {event} event
     */
    handleSubscriberEvent = async (event) => {
        const func = "handleSubscriberEvent(),Red5Streaming.jsx,";
        if (getShutDown(func)) {
            return;
        }
        log.trace("%sevent.type:%s", func, event.type);
        if (event.type !== "Subscribe.Time.Update") {
            log.trace("%sevent.type:%s", func, event.type);
        }

        if (event.type === "Connect.Success") {
            log.info("%s%s", func, event.type);
            this.updateConnectionTime(func + "-1");
            this.setConnected(true, event.type);
        } else if (event.type === "Subscribe.Time.Update") {
            this.updateConnectionTime(func + "-2", event.data.time);
            this.handleNextCharge();
        } else if (event.type === "Subscribe.Play.Unpublish") {

            this.handleErrorEvent(errors.RED5PRO_SUBSCRIBE_PLAY_UNPUBLISH, func);
        } else if (event.type === "Subscribe.Connection.Closed") {
            this.setConnected(false, event.type);
        } else if (event.type === "Connect.Failure") {
            this.setConnected(false, event.type);
        }
    }; //handleSubscriberEvent


    /**
     * this function contain red5pro subscriber functionality
     */
    subscriberStreaming = async (caller) => {
        const func = "subscriberStreaming(" + caller + "),Red5Streaming.jsx,";
        clearTimeout(this.retryTimeout);
        if (getShutDown(func)) {
            return false;
        }
        const { stream_name } = this.state;
        let errMessage = null;

        const connectionParams = {
            username: getId("username", func),
            password: getId("username", func),
            token: getId("red5_token", func)
        }
        setState({
            connectionParams,
        }, null, this, func);


        log.debug("%sSubscribe a stream:%s", func, stream_name);
        this.subscriber = new RTCSubscriber();
        //set red5 loglevel
        setLogLevel(red5ErrorLevel());



        // Assign new subscription id in off chance server rejects on subscriber already assigned.
        // Subscribers will be cleaned up, but if we try to immediately re-subscribe, we may get rejected.
        //const instanceId = Math.floor(Math.random() * 0x10000).toString(16);
        let instanceId = null;
        while (instanceId === this.instanceId || (instanceId === null)) {
            instanceId = Math.floor(Math.random() * 0x10000).toString(16);
        }

        this.instanceId = instanceId;

        log.trace("%sinstanceId:%s", func, instanceId);

        const initData = {
            protocol: config.red5.protocol, //"wss",
            host: config.red5.host, //"dev-red5-c7.t2.tl",
            port: config.red5.port, //443,
            app: config.red5.app, //"live",
            streamName: stream_name,
            //this can be auto generated, just comment it out
            subscriptionId: 'subscriber-' + instanceId,
            rtcConfiguration: {
                iceServers: [config.red5.iceServers],
            }, connectionParams: connectionParams
        };
        log.debug("%sSubscribe,initData:%s", func, initData);
        const initResult = await this.subscriber.init(initData).catch((error) => {
            const errMessage = error;
            log.fatal("%s%s:%s", func, errMessage, error);
            this.subscriber = undefined;
            this.viewer = undefined;
            if (!config.red5.reConnect) {
                if (this.refreshTimer) clearInterval(this.refreshTimer);
                this.refreshTimer = 0;
                this.handleErrorEvent(errMessage, func);
            }
            // A fault occurred while trying to initialize and subscribe to the stream.
        });
        log.trace("%sinitResult:%s", func, initResult);
        if (!initResult) {
            await this.handleErrorEvent(errMessage, func);
        }

        log.debug("%sstream attached:%s", func, initData);

        if (this.subscriber) {
            const subscribeResult = await this.subscriber.subscribe().catch((error) => {
                errMessage = error;
                log.fatal("%serrMessage:%s", func, errMessage);
                this.handleErrorEvent(errMessage, func);
            });
            if (!subscribeResult) {
                await this.handleErrorEvent(errMessage, func);
            }
            this.subscriber.on("*", this.handleSubscriberEvent);
            //  setState({ waitingbuttonstatus: false }, null, this, func);
            //  setState({ waitingForApproval: false }, null, this, func);
            this.subscriber.mute();
            this.subscriber.pause();
            log.info("%sstream subscribed...", func);
        } else {
            const errMessage = "this.subscriber not works";
            log.fatal("%s:%s", func, errMessage);
            await this.handleErrorEvent(errMessage, func);
        }
        this.viewer = new PlaybackView("red5pro-subscriber");
        this.viewer.attachSubscriber(this.subscriber);
        return true;

    }; //subscriberStreaming

    /**
     * this function contain charges of stream functionality
     */

    streamingChargeHandler = async (status, caller) => {
        const func = "streamingChargeHandler(),Red5Streaming.jsx,";
        if (getShutDown(func)) {
            return false;
        }

        const data = {
            auth_token: getId("auth_token", func),
            streaming_id: getId("streaming_id", func),
            stream_name: this.state.stream_name,
            status: status,
            caller: caller,
        };

        let result = await this.props.dispatch(chargeStream(data)).catch((error) => {
            log.fatal("%s.catch(chargeStream),error:%s", func, error);
            result = error;
        });

        log.trace("%sresult:%s", func, result);

        if(result.message === "You do not have sufficient balance"){
            log.info("%sresult.message:%s", func, result.message);
            await this.handleErrorEvent(result.message, func);
            return true;
        }

        if (result.response === constants.RESULT_ERROR) {
            log.fatal("%sresult.message:%s", func, result.message);
            await this.StopStreaming(result.message, func, true);
            return false;
        }
        log.trace("%scustomer charged,data:%s,result:%s", func, data, result);
        return true;
    } //streamingChargeHandler

    /**
     * this function contain authenticate subscription functionality
     * @async
     */
    StartStreaming = async (caller = "") => {
        const func = "StartStreaming(),Red5Streaming.jsx,";
        log.trace("%scaller:%s", func, caller);
        if (getShutDown(func)) {
            return;
        }

        log.trace("%sthis.props:%s", func, this.props);
        log.trace("%sthis.state:%s", func, this.state);
        const data = {
            auth_token: getId("auth_token", func),
            username: getId("username", func),
            performer: getId("performer", func),
            streaming_id: getId("streaming_id", func),
            stream_name: this.state.stream_name,
            room_id: this.state.room_id,
            caller: func,
        };
        log.debug("%sAuthenticate a subscription,data:%s", func, data);

        const result = await this.props.dispatch(AuthenticateSubscription(data)).catch((error) => {
            log.fatal("%s.catch(result),error:%s", func, error);
        });
        log.debug("%sResult of authenticate a subscription:%s", func, result);

        if (result.response === constants.RESULT_ERROR) {
            await this.handleErrorEvent(result.message, func);
            return;
        }
        setId("connection_id", result.message.connection_id, func);
        log.info("%sCustomer connected,stream_name:%s,connection_id:%s", func, this.state.stream_name, result.message.connection_id);
    }; //StartStreaming

    /**
     * this function contain Red5pro unsubscriber functionality
     */
    unsubscribeStreaming = (caller) => {
        const func = "unsubscribeStreaming(" + caller + "),Red5Streaming.jsx,";
        let retVal = {
            response: constants.RESULT_SUCCESS, message: errors.UNSUBSCRIBE_SUCCESS,
        };

        if ((this.subscriber !== undefined) && (this.subscriber !== null)) {
            this.subscriber.unsubscribe()
                .then(function (result) {
                    log.debug("%sthis.subscriber unsubscribe() success", func);
                })
                .catch(function (error) {
                    retVal.response = constants.RESULT_ERROR;
                    retVal.message = error;
                    log.fatal("%sretVal.message:%s", func, retVal.message);
                });

            this.subscriber.off('*', this.handleSubscriberEvent);
        }
        if (this.state.blockstatus === true) {
            retVal.response = constants.RESULT_ERROR;
            retVal.message = errors.BLOCKED_BY_PUBLISHER;
            log.trace("%sretVal.message:%s", func, retVal.message);
            //??? is this need removeLocalStorage("streaming", func);
            this.setState({
                blockstatus: false, streaming: false, room_id: ""
            });
        } else {
            setState(
                {
                    subscriberChat: false,
                    allowSubscriberPermissionStatus: false,
                    streaming: false,
                    cameraClass: "non-visible",
                    room_id: ""
                }, null, this, func
            );
        }//success
        log.info("%s unsubscribe %s stream successfully with connection_id:%s", func,
            getId("stream_name", func), getId("connection_id", func));
        return retVal;
    }; //unsubscribeStreaming

    /**
     * this function contain delete subscription functionality
     * @async
     */
    StopStreaming = async (message, caller, noCharge = false) => {
        const func = "StopStreaming(" + caller + "),Red5Streaming.jsx,";
        log.debug("%smessage:%s", func, message);
        //don't need to call at all cleanExit will does the job

        // let chargeStatus = constants.CHARGE_STOP_BY_CUSTOMER;
        // switch (message) {
        //   case errors.BLOCKED_BY_PUBLISHER:
        //     chargeStatus = constants.CHARGE_AT_BLOCK_STREAMING;
        //     break;
        //   default:
        //     break;
        // }
        //don't call again if invoking function was streamingChargeHandler
        //if (!noCharge) await this.streamingChargeHandler(chargeStatus, caller);
        log.trace("%smessage:%s", func, message);
        setState({
            waitingbuttonstatus: false,
        }, null, this, func);
        setState({ waitingForApproval: false }, null, this, func);

        await this.handleErrorEvent(message, func);

    }; //StopStreaming

    /**
     * this function open modal
     */
    modalOpen = async () => {
        const func = "modalOpen(),Red5Streaming.jsx,";
        log.trace("%sthis.state:%s", func, this.state);
        if (getShutDown(func)) {
            return;
        }
        this.adjustRoomSelection(this.state.roomListingResult)
        if (this.state.roomListingResult.length === 0) {
            const errorMessage = "No available rooms!,roomListingResult is empty!";
            log.fatal("%s,error:%s", func, errorMessage);
            await this.handleErrorEvent(errorMessage, func);
            return;
        }
        setState({ show: true }, null, this, func);
    }; //modalOpen

    /**
     * this function close modal
     */
    handleClose = async () => {
        const func = "modalOpen(),Red5Streaming.jsx,";
        setState({ show: false }, null, this, func);
        log.trace("%sthis.state:%s", func, this.state);
        await this.handleErrorEvent(errors.ALL_ROOMS_REJECTED, func);
    }; //handleClose

    /**
     * this function contain enter room functionality
     */
    enterRoomHandler = async (room_id, enterRoom, moderator = false) => {
        const func = "enterRoomHandler(),Red5Streaming.jsx,";
        //prevent to create multiply records on fast clicks
        log.info("%sRoom selected:%s", func, room_id);
        if (this.state.enterRoom === true) return;
        //credit check start
        const apiUrl = BaseUrl + constants.A_CREDIT_CHECK;
        log.trace("%sapiUrl:%s", func, apiUrl);
        const data = {
            room_id: room_id,
            auth_token: getId("auth_token", func),
            streaming_id: getId("streaming_id", func),
            username: getId("username", func),
            caller: func,

        };
        log.trace("%sdata:%s", func, data);
        //check credit if not moderator log in
        if (!moderator) {
            let errorMessage = null;
            const creditCheck = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
                log.fatal("%serror:%s", func, error);
                errorMessage = error.message;
            })
            log.trace("%screditCheck:%s", func, creditCheck);
            if (errorMessage) {
                await this.handleErrorEvent(errorMessage, func);
                return;
            }
            if (creditCheck.response === constants.RESULT_ERROR) {
                await this.handleErrorEvent(creditCheck.message, func);
                return;
            }

        }
        this.setState(
            {
                room_id: room_id,
                show: false,
                enterRoom: enterRoom,
                waitingForApproval: true,
            },
            () => {
                //need to execute only at connection when room_id is null
                this.StartStreamingHandler();
                const data = {
                    auth_token: getId("auth_token", func),
                    streaming_id: getId("streaming_id", func),
                    stream_name: this.state.stream_name,
                    room_id: this.state.room_id,
                    status: constants.CONNECTION_ROOM_SELECTED,
                    caller: func,
                };
                log.debug("%sUpdate room at room selection,data:%s", func, data);
                this.props.dispatch(roomUpdate(data));
                log.trace("%sthis.state:%s", func, this.state);
            }
        );
    }; //enterRoomHandler

    /**
    * 
    */
    moderatorConnect = (room_id) => {
        const func = "moderatorConnect(),Red5Streaming.jsx,";
        log.info("%sModerator connected to this room:%s", func, room_id);
        this.setState(
            {
                room_id: room_id,
                show: false,
                streaming: true
            },
            async () => {
                setState({
                    cameraClass: "visible",
                    waitingbuttonstatus: false,
                    waitingForApproval: false
                }, null, this, func);
                this.subscriber.unmute();
                this.subscriber.play();

            }
        );
    }; //moderatorConnect

    adjustRoomSelection = (roomListingResult) => {
        const func = "adjustRoomSelection(),Red5Streaming.jsx,";
        const roomSelectionFormat = config.roomSelectionFormat;
        let re = /(?:-([^-]+))?$/;
        let instance_id = re.exec(getId('username', func))[1]
        if(roomSelectionFormat && instance_id in roomSelectionFormat){
            let customRoomListing = roomSelectionFormat[instance_id];
            var roomListingResultArray = [];

            for (let i = 0; i < Object.keys(this.state.roomListingResult).length; i++) {
                roomListingResultArray[i] = this.state.roomListingResult[i];
                roomListingResultArray[i].name = customRoomListing[roomListingResultArray[i].room_type_id];
                //this.state.roomListingResult[i].name = customRoomListing[i + 1];
            }

            this.setState({
                roomListingResult: roomListingResultArray,
                customRoomListing: true
             });
        }
    }


    render() {
        const func = "render(),Red5Streaming.jsx,";
        const {
            streaming,
            stream_name,
            show,
            roomListingResult,
            waitingbuttonstatus,
            waitingForApproval,
            customRoomListing
        } = this.state;

        if (this.state.roomListingResult === errors.NO_VALID_ROOM_FOUND) {
            //const redirectUrl = getId('customerBackUrl', func);
            const twspAlert = Swal.mixin(config.sweetAlertError);
            twspAlert.fire('', errors.NO_VALID_ROOM_FOUND).then((result) => {
                log.trace("%sresult:%s", func, result);
                if (result.isConfirmed) {
                    log.trace("%sconfirmed", func);
                    const redirectUrl = getId('returnUrl', func);
                    log.trace("%s,redirectUrl:%s", func, redirectUrl);
                    window.top.location.href = redirectUrl;
                }
            });
            return null;
        }
        log.trace("%sthis.state:%s", func, this.state);
        return (
            <>

                <div className="card preview-section preview-section-iframe">
                    <div id='card-body'className="card-body preview-body">
                        <div className="row">
                            <div className="col-md-6 sectionBackgroundColor">
                                <div className={this.state.cameraClass}>
                                    {streaming ? (
                                        <video
                                            id="red5pro-subscriber"
                                            controls={true}
                                            autoPlay={true}
                                        ></video>
                                    ) : (
                                        <video
                                            id="red5pro-publisher"
                                            muted={true}
                                            autoPlay={true}
                                            controls={true}
                                        ></video>
                                    )}
                                </div>
                                <div className="row streaming-btn-sec">
                                    {streaming ? (
                                        waitingbuttonstatus ? (
                                            <>
                                                <div className="col-md-6">
                                                    <Link to="#" id="btn-waiting" className={`btn d-block btn-orange`}>
                                                        Waiting
                                                    </Link>
                                                </div>
                                                <div className="col-md-6">
                                                    <Link to="#" id="btn-stop-viewing" className={`btn d-block btn-danger`} onClick={() => this.StopStreaming(constants.STOP_STREAMING, func)}>
                                                        STOP VIEWING
                                                    </Link>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="col-md-12">
                                                <Link to="#" id="btn-stop-viewing" className={`btn d-block btn-danger stopButtonDesktop`} onClick={() => this.StopStreaming(constants.STOP_STREAMING, func)}>
                                                    STOP VIEWING
                                                </Link>
                                            </div>
                                        )
                                    ) : (
                                        <div className="col-md-12">
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6 sectionBackgroundColor">
                                {streaming ? (
                                    <Chat
                                        iframeWidth={true}
                                        stream_name={stream_name}
                                        subscriberChat={this.state.subscriberChat}
                                        donationButtons={this.state.donationButtons}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    show={show}
                    backdrop="static"
                    keyboard={false}
                    onHide={() => this.handleClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>{errors.ROOM_SELECTOR_TITLE}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    
                        <form>
                            <div className="row">
                                {roomListingResult &&
                                    roomListingResult.length > 0 &&
                                    roomListingResult.map((item, index) => (
                                        <div className="form-group col-sm-4 col-md-4 col-lg-12 row" key={item.room_type}>
                                            <label
                                                htmlFor="enter room"
                                                className="col-lg-5 align-self-center col-form-label text-right">
                                                {customRoomListing ? 
                                                    `${item.name}`
                                                    :
                                                    `${item.room_type.toUpperCase()} Room (${constants.CURRENCY_SYMBOL}${item.cost === null ? "0.00" : item.cost})`
                                                }

                                                </label>
                                            <div className="col-lg-6">
                                                <Link to="#" id={"btn-enter-room-" + index} className="btn btn-green" onClick={() => this.enterRoomHandler(item.room_id, true)}>
                                                    Enter Room
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                <div className="text-center">
                                    By clicking you are accepting the{" "}
                                    <Link to="#">terms and condition</Link>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={waitingForApproval}
                    size="md"
                    // aria-labelledby="contained-modal-title-vcenter"
                    //centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="waitingPopup">{errors.WAITING_FOR_APPROVAL}</div>
                    </Modal.Body>
                </Modal>

            </>
        );
    }
}
/**
 * @function mapStateToProps
 * @param {*} state
 */
const mapStateToProps = (state) => ({
    room_listing: state.streamingSubscribe.room_listing,
    streaming_value: state.streamingSubscribe.streaming_value,
    block_subscriber: state.streamingSubscribe.block_subscriber,
    room_info: state.streamingSubscribe.room_info,
    allow_subscriber_permission_event: state.streamingSubscribe.allow_subscriber_permission_event,
    deny_subscriber_permission_event: state.streamingSubscribe.deny_subscriber_permission_event,
    two_way_cam_request: state.streamingSubscribe.two_way_cam_request,
});


const connectedComponent = connect(mapStateToProps)(withParams(_red5Streaming));
export { connectedComponent as Red5Streaming };

