/**
 * ChatNotification component
 * @module ChatNotification
 * @version 1.2
 * @copyright Telecom2 Ltd.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import {log} from '../../store/helpers/logger';
import constants from '../../constants';
import { setState } from "../../store/helpers/common";
import Sound from '@studysync/react-sound';
//https://www.npmjs.com/package/react-sound

/**
 * ChatNotification
 */
export class _chatNotification extends Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    const func = "constructor(),ChatNotification.jsx,";
    log.trace("%sprops:%s", func, props);
    super(props);
    this.state = {
      notificationChat: {}, //used for storing notification data
      isOpen: false, //used for storing the value either notification pop show or hide
      play: Sound.status.STOPPED,
      soundUrl: "assets/sounds/click.mp3",
      loop: false,
    };
  } //constructor

  /**
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate = async (prevProps, prevState) => {
    const func = "componentDidUpdate(),ChatNotification.jsx,";
    log.trace("%sprevProps:%s,prevState:%s", func, prevProps, prevState);
    const { notification_content } = prevProps;
    if (notification_content !== this.props.notification_content) {
      await this.showDiv(this.props.notification_content);
    }//if
  }; //component did update

  //this function show notification pop for 5 seconds
  showDiv = (notificationChat) => {
    const func = "showDiv(),ChatNotification.jsx,";
    log.debug("%sNotification is received:%s", func, notificationChat);
    
    let soundUrl = null;
    let loop = false;
    let timeOut = 5000;
    let isOpen = true;
    let play = Sound.status.PLAYING;
    switch (notificationChat.type) {
      case constants.CHAT_ACCEPTED:
      case constants.CHAT_AUTO_ACCEPTED:
      case constants.CHAT_DENIED:
      case constants.CHAT_PERFORMER_EXIT:
      case constants.CHAT_BLOCKED:
        isOpen = false;
        play = Sound.status.STOP
        break;
      case constants.CHAT_WAIT_FOR_APPROVAL:
        soundUrl = "assets/sounds/doorbell.mp3";
        timeOut = 0; //means non stop
        loop = true;
        break;
      case constants.CHAT_DONATION:
        play = Sound.status.STOP
        break;
      case constants.CHAT_LEAVE:
        soundUrl = "assets/sounds/cashregister.mp3";
        break;
      case constants.CHAT_MESSAGE:
        soundUrl = "assets/sounds/click.mp3";
        break;
      default:
        isOpen = false;
        play = Sound.status.STOP
        return;
    }

    setState(
      {
        isOpen: isOpen,
        play: play,
        soundUrl: soundUrl,
        loop: loop,
        notificationChat: notificationChat,
      },
      () => {
        log.trace("%stimeOut:%s", func, timeOut);

        if (timeOut) {
          setTimeout(() => {
            setState({ isOpen: false }, null, this, func);
          }, timeOut);
        }
      }, this, func

    );
    if (isOpen) {
      document.getElementById("toastDiv").style.display = "block";
    } else {
      document.getElementById("toastDiv").style.display = "none";
    }
  }//showDiv

  render() {
    const func = "render(),ChatNotification.jsx,";
    log.trace("%sthis.state:%s", func, this.state);
    const { isOpen, notificationChat, soundUrl } = this.state;
    log.trace("%ssoundUrl:%s", func, soundUrl);
    return (
      <>
        {isOpen ?
          <Sound url={soundUrl}
            playStatus={this.state.play}
            loop={this.state.loop}
          />
          :
          ""
        }
        <div
          id="toastDiv"
          className="toast notification-toast"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          style={isOpen ? { display: "block" } : { display: "none" }}
        >
          <div className="toast-header">
            <strong className="mr-auto">
              New Message from{" "}
              {notificationChat && Object.keys(notificationChat).length > 0
                ? this.state.notificationChat.username
                : ""}
            </strong>
            <button
              type="button"
              className="ml-2 mb-1 close"
              data-dismiss="toast"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                onClick={() => setState({ isOpen: false }, null, this, func)}
              >
                &times;
              </span>
            </button>
          </div>
          <div className="toast-body">
            {notificationChat && Object.keys(notificationChat).length > 0
              ? this.state.notificationChat.content
              : ""}
          </div>
        </div>
      </>
    );
  }
}
/**
 * @function mapStateToProps
 * @param {*} state 
 */
const mapStateToProps = (state) => ({
  notification_content: state.streamingSubscribe.notification_content,
});
log.trace("ChatNotification.jsx,mapStateToProps:%s", mapStateToProps)

const connectedComponent= connect(mapStateToProps)(_chatNotification);
export {connectedComponent as ChatNotification};







