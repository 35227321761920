/**
 * Footer layout Component
 * @module Footer
 * @version 1.2
 * @copyright Telecom2 Ltd.
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import metadata from '../version.json';
import {log} from "../store/helpers/logger";
/**
 * Footer layout Component
 */
export class _footer extends Component {
  constructor(props) {
    //const func = "constructor(),Footer.js,";
    //log.trace("%sprops:%s",func,props); cause logging error

    super(props);
    this.state = {};
  }

  render() {
    const func = "render(),Footer.js,";
    log.trace("%s", func);

    return (
      <footer className="footer new-footer">
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container-fluid">
            {/* Copyright */}
            <div className="copyright">
              <div className="row">
                <div className="col-md-12 col-lg-12 text-center">
                  <div className="copyright-text">
                    <p className="mb-0">© 2020 T2. All rights reserved.</p>
                  </div>
                  <div className="sf-footer-version">
                    {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
                  </div>
                </div>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </div>
        {/* /Footer Bottom */}
      </footer>
    );
  }
}
/**
 * @function mapStateToProps
 * @property notification_content
 * @function mapStateToProps
 * @param {*} state
 */
const mapStateToProps = (state) => ({
  notification_content: state.streamingSubscribe.notification_content,
});
log.trace("Footer.jsx,mapStateToProps:%s", mapStateToProps);

const connectedComponent = connect(mapStateToProps)(_footer);
export { connectedComponent as Footer };








