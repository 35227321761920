/**
* DashboardReducer module
* @module DashboardReducer
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import * as types from "./../types/types";
import {log} from '../../store/helpers/logger';

const initalState = {
    block_user_data:null,
    allow_subscriber_permission:null,
    deny_subscriber_permission:null,
    income_tip_list:[]
}

/**
 * @function DashboardReducer
 * @param {*} state 
 * @param {*} action 
 */
export const DashboardReducer = (state = initalState, action) => {
    const func = "DashboardReducer(),DashboardReducer.js,";
    log.trace("%saction.type:%s", func, action.type);
    log.trace("%sstate:%s", func, state);

    switch (action.type) {
        case types.BLOCK_USER_DATA:
            log.trace("%sBLOCK_USER_DATA:%s", func, action.type);
            return {
                ...state,
                block_user_data: action.payload
            }
          case types.ALLOW_SUBSCRIBER_PERMISSION_DATA:
            log.trace("%sALLOW_SUBSCRIBER_PERMISSION_DATA:%s", func, action.type);
              return {
                  ...state,
                  allow_subscriber_permission: action.payload
            }
            case types.DENY_SUBSCRIBER_PERMISSION_DATA:
                log.trace("%sDENY_SUBSCRIBER_PERMISSION_DATA:%s", func, action.type);
                return {
                    ...state,
                    deny_subscriber_permission: action.payload
              }
          case types.INCOME_TIP_LISTING:
            log.trace("%sINCOME_TIP_LISTING:%s", func, action.type);
              return {
                  ...state,
                  income_tip_list: action.payload
            }

        default:
            log.trace("%sdefault:%s", func, action.type);

            return state
    }
}
