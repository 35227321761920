/**
* Connected Users Actions
* @module ConnectedUserActions
* @version 1.2
* @copyright Telecom2 Ltd.
* 
*/

import { BaseUrl } from '../helpers/common';
import * as actionTypes from '../types/types';
import { ShowLoader } from './staticActions'
import {log} from '../helpers/logger';
import constants from "../../constants";
import errors from "../../errors";
import { apiCallAwait, getStop } from '../helpers/common';
import { RedirectToLogin } from "./userActions";

/**
 * Get connected users to the list of connected users
 * @param {JSON} data {stream_name,streaming_id,auth_token}
 * @returns {JSON} {response,message}
 * @example
 * import { connectedUserListing } from "../ConnectedUsersActions"
 * ....
 * 
 * const data = {
 *           stream_name: uuidv4(),
 *           streaming_id: getId("streaming_id",func),
 *           auth_token: getId("auth_token",func),
 *       }
 *
 * const result = await store.dispatch(connectedUserListing(data));
 * ....
 * Return Success
 * --------------
 * {
 * "response":"Success",
 *  "message":
 *  [
 *      {    
 *          "id":48,
 *          "customer_id":10675,
 *          "stream_session_id":96,
 *          "created":"2021-03-31T14:47:05.000Z",
 *          "updated":"2021-03-31T14:47:05.000Z",
 *          "start_datetime":"2021-03-31T14:47:05.000Z",
 *          "end_datetime":null,
 *          "room_id":10902,
 *          "userID":"3c99ef98-fc04-11ea-ba24-2daa45fe204b",
 *          "username":"testuser",
 *          "balance":"265104.88",
 *          "ip_address":"",
 *          "status":0
 *      }
 *  ]
 * }
 * 
 * Return Error
 * ------------
 * { 
 *      "response": 'Error', 
 *      "message": 'Create Stream failed' 
 * }
 * 
 */
export function connectedUserListing(data) {
    const func = "connectedUserListing(),ConnectedUsersAction.js,";
    log.trace("%sdata:%s", func, data);
    return async dispatch => {
        let retVal = null;
        let errorMessage = null;

        if (getStop(func)) {
            dispatch(ShowLoader(false));
            dispatch({ type: actionTypes.CONNECTED_USER_LISTING, payload: [] })
            retVal = { response: constants.RESULT_ERROR, message: "getStop is true" };
            return retVal;
        }

        if ((data.stream_name === null) || (data.stream_name === undefined) || (data.stream_name === "")) {
            dispatch({ type: actionTypes.CONNECTED_USER_LISTING, payload: [] })
            retVal = { response: constants.RESULT_SUCCESS, message: errorMessage };
            log.trace("%sdata.stream_name is null,return:%s", func, retVal);
            return retVal;

        }


        dispatch(ShowLoader(true));
        const apiUrl = `${BaseUrl}${constants.A_SUBSCRIBE_STREAM_LIST}`;

        log.trace("%sapiUrl:%s", func, apiUrl);
        const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
            log.fatal("%serror:%s", func, error);
            errorMessage = error.message;
        });
        log.trace("%sresult:%s", func, result);

        if ((!result) || (result === undefined)) {
            dispatch(ShowLoader(false));
            dispatch({ type: actionTypes.CONNECTED_USER_LISTING, payload: [] })
            if (!errorMessage) errorMessage = errors.CONNECTED_USER_LISTING;
            retVal = { response: constants.RESULT_ERROR, message: errorMessage };
            log.fatal("%sretVal:%s", func, retVal);
            RedirectToLogin(errorMessage, func);
            return retVal;
        }


        errorMessage = result.message;
        log.trace("%serrorMessage:%s", func, errorMessage);
        //general error
        if (result.response === constants.RESULT_ERROR) {
            dispatch(ShowLoader(false));
            dispatch({ type: actionTypes.CONNECTED_USER_LISTING, payload: [] })
            if (!errorMessage) errorMessage = errors.CONNECTED_USER_LISTING;
            retVal = { response: constants.RESULT_ERROR, message: errorMessage };
            log.fatal("%sretVal:%s", func, retVal);
            //because this is scheduled action it can be happen when performer stopped?!
            if (result.message !== errors.STREAM_NAME_IS_NULL) {
                RedirectToLogin(errorMessage, func);
            }
            return retVal;
        }


        dispatch({ type: actionTypes.CONNECTED_USER_LISTING, payload: errorMessage })
        retVal = { response: constants.RESULT_SUCCESS, message: errorMessage };
        log.trace("%sretVal:%s", func, retVal);
        return retVal;
    }

}//connectedUserListing
