/**
* About component
* @module About
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import React, { Component } from 'react';
import {Header} from "../layout/Header";
import {Footer} from "../layout/Footer";
import metadata from '../version.json';
import {log} from '../store/helpers/logger';

/**
* About component
* @property {*} intervalID
* @property {*} SubscriberPresence
*/
export class About extends Component {
  /**
   * @constructor
   * @param {*} props 
   */
  constructor(props) {
    const func = "constructor(),About.js,";
    log.trace('%sprops:%s', func, props);
    super(props);
  }

  /**
  * 
  * @function render
  * @memberof About#
  * @param {*} value
  */
  render() {
    const func = "render(),About.js,";
    log.trace('%srender start', func);
    return (
      <>
        <div className="main-wrapper">
          <Header {...this.props} />
          <div className="min-height-area">
            <div className="main-section">

              <section className="section about">
                <div className="container-fluid">
                  <div className="row">
                    <div className="jumbotron">
                      <h1>{`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}</h1>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <Footer {...this.props} />
        </div>
      </>
    )
  }
}//class