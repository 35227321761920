/**
* IdleMonitor Component
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import config from '../config.json';
import { useIdleTimer } from 'react-idle-timer'
import { UserLogout } from "../store/actions/userActions";

import {log} from '../store/helpers/logger';

/**
 * IdleMonitor function component
 * 
 * @example
 * put anywhere in component rendering
 * [code]
 *        render() {
 * ...
 *       <IdleMonitor/>
 * ...
 * [/code]
 */
export function IdleMonitor() {

    const [idleModal, setIdleModal] = useState(false);

    /**
     * This function will extend current user session.
     */
    const extendSession = () => {
        const func = "extendSession(),IdleMonitor.js,";
        log.trace("%suser did hit extend session", func);
        setIdleModal(false);
    }//extendSession
    
    /**
     * 
     * @param {event} event 
     * @return {*} ComposedClass
     */
    const handleOnIdle = (event) => {
        const func = "handleOnIdle(),IdleMonitor.js,";
        const errMessage = "Idle time expired, logged out!";
        log.error("%sError:%s", func, errMessage);
        setIdleModal(true);
        log.debug('%sLast active time is:%s', func, new Date(getLastActiveTime()));
    }//handleOnIdle

    /**
     * 
     */
    const { getLastActiveTime } = useIdleTimer({
        timeout: 1000 * config.idleTimeout,
        onIdle: handleOnIdle,
        debounce: 500,
    })

    /**
     * 
     */
    const logOut = () => {
        const func = "logOut(),IdleMonitor.js,";
        log.info("%suser did hit ok button to logout ", func);
        UserLogout();
    }//logOut

    return (
        <Modal isOpen={idleModal} toggle={() => setIdleModal(false)}>
            <ModalHeader toggle={() => setIdleModal(false)}>
                Session expire warning
        </ModalHeader>
            <ModalBody>
                Session is expiring!Do you want to extend it?
        </ModalBody>
            <ModalFooter>
                <button id="btn-logout" className="btn btn-info" onClick={() => logOut()}>Logout</button>
                <button id="btn-extend"className="btn btn-success" onClick={() => extendSession()}>Extend session</button>

            </ModalFooter>
        </Modal>
    )

}//IdleMonitor
