/* eslint-disable no-multi-str */
/**
 * Error definitions
 * @module Errors
 * @version 1.2
 * @copyright Telecom2 Ltd.
 *
*/

module.exports = {
    ALL_ROOMS_REJECTED: "All of the the rooms have been rejected by the customer",
    AUTH_TOKEN_IS_NULL: "Sorry an error has occurred. Please logout and try again.\nError code: AUTH_TOKEN_IS_NULL",
    AUTH_USER_API: "Sorry an error has occurred. Please logout and try again.\nError code: AUTH_USER_API failure",
    BLOCKED_BY_PUBLISHER: "You have been blocked.",
    BROWSER_OLD:"browser is too old, please update to the latest one.",
    BROWSER_NOT_ACCEPTED:"Your current browser is not tested with our system.Please use any well known browser!",
    C2C_CUSTOMER_CLOSE_CONNECTION: 'Twoway cam stopped',
    C2C_CUSTOMER_LOST: 'Customer stopped twoway cam',
    C2C_CUSTOMER_REJECT: "Customer has rejected the twoway cam connection.",
    C2C_PERFORMER_CONNECTION_FAILURE: 'Twoway cam performer connection failure',
    C2C_PERFORMER_CLOSE_CONNECTION: 'Performer has closed the connection',
    CANCELING: "Sorry an error has occurred. Please logout and try again.\nError code: CANCELING",
    CLEAN_EXIT_NO_IDS: "Sorry an error has occurred. Please try again.\nError code: CLEAN_EXIT_NO_IDS - Host/customer was not logged-in or auth_token/streaming_id is missing",
    CONFIGURATION_ERROR_TITLE: "Configuration Error",
    CONNECTED_USER_LISTING: "Sorry an error has occurred. Please try again.\nError code: CONNECTED_USER_LISTING",
    CREATE_STREAM: "Sorry an error has occurred. Please try again.\nError code: CREATE_STREAM failed",
    CUSTOMER_AUTH: "Sorry an error has occurred. Please try again.\nError code: CUSTOMERINFO_API failed",
    CUSTOMER_BAN_DELETE_FAILED: "Customer ban-deletion failed. Please try again.",
    CUSTOMERINFO_API: "Sorry an error has occurred. Please try again.\nError code: CUSTOMERINFO_API failure",
    DELETE_STREAM: "Sorry an error has occurred. Please try again.\nError code: DELETE_STREAM failed",
    DENIED_BY_PUBLISHER: "Your stream has been denied by the host",
    ERROR_MESSAGE_IS_NULL: "Sorry an error has occurred. Please try again.\nError code: ERROR_MESSAGE_IS_NULL",
    FIREFOXONMAC:"Well known Firefox issue on Mac appear, please use Safari!",
    INCOME_TIPS: "Sorry an error has occurred. Please try again.\nError code: Retrieval of INCOME_TIPS failed",
    INVALID_CONFIG_VALUE: "is invalid config value,please contact to support!",
    INVALID_PASSWORD: "Invalid password",
    INVALID_USERNAME: "Invalid username",
    JOIN_TO_ROOM_REJECTED: "Join-to-room rejected by the customer",
    LOGIN_API: "Sorry an error has occurred. Please try again.\nError code: LOGIN_API failure",
    LOGOUT: "Are you sure you want to exit?",
    MODERATOR_WAIT: "No customer connection yet, please try later.",
    NO_CONNECTIONS_FOUND: "No connections found test",
    CONNECTION_CLOSED_BY_NEW_CONNECTION: "Connection closed by new connection",
    NO_STREAM_FOUND: 'No stream found',
    NO_USER_SIGNED_IN: "No user is signed-in",
    NO_VALID_ROOM_FOUND: 'Sorry an error has occurred. Please try again.\nError code: NO_VALID_ROOM_FOUND',
    PASSWORD_IS_NULL: "Sorry an error has occurred. Please try again.\nError code: PASSWORD_IS_NULL",
    PAY_NOW_PREFIX: "Send ",
    PAY_NOW_SUFFIX: " Tip",
    PERFORMER_ID_IS_NULL: "Sorry an error has occurred. Please try again.\nError code: PERFORMER_ID_IS_NULL",
    PERFORMER_IN_PRIVATE_ROOM: "Host in private room, please try again later.",
    PERFORMER_IS_NULL: "Sorry an error has occurred. Please try again.\nError code: PERFORMER_IS_NULL",
    PING_IS_DISABLED: "Sorry an error has occurred. Please try again.\nError code: PING_DISABLED in config",
    PING_NOT_READY: "Sorry an error has occurred. Please try again.\nError code: PING_NOT_READY",
    RED5PRO_API_NOT_LOADED: "RED5API not loaded,fatal error",
    
    
    RED5PRO_PUBLISHER_CONNECTION_CLOSED:"The host has closed the stream",
    RED5PRO_CONNECT_FAILURE:"There has been a Streaming server connection failure",
    RED5PRO_SUBSCRIBE_PLAY_UNPUBLISH:"The host stream has stopped",
    
    REFRESH: "Refreshing..",
    ROOM_ID_IS_NULL: "Sorry an error has occurred. Please try again.\nError code: ROOM_ID_IS_NULL",
    ROOM_SELECTOR_TITLE: "Please select a room from this list!",
    ROOM_UPDATE_FAILED: "You have been kicked from the session.",
    SHUTDOWN: "Shut down in progress",
    STATUS_IS_INVALID: "Sorry an error has occurred. Please try again.\nError code: STATUS_IS_INVALID",
    STATUS_IS_NULL: "Sorry an error has occurred. Please try again.\nError code: STATUS_IS_NULL",
    STREAM_NAME_IS_NULL: "Sorry an error has occurred. Please try again.\nError code: STREAM_NAME_IS_NULL",
    STREAMING_ID_IS_NULL: "Sorry an error has occurred. Please try again.\nError code: STREAMING_ID_IS_NULL",
    STREAMING_IS_STOPPED_BY_PERFORMER: "Streaming has been stopped by the host",
    STREAMING_IS_STOPPED_BY_PERFORMER_UNAVAILABLE: "Performer has become unavailable",
    STREAMING_IS_STOPPED_BY_PERFORMER_IN_PRIAVTE: "This consultant has gone private",
    STREAMING_IS_STOPPED_BY_USER: "Streaming has been stopped by the customer or host",
    STREAMING_ERROR_TITLE:"Streaming error",
    SUBSCRIBE_AUTH: "Sorry an error has occurred. Please try again.\nError code: SUBSCRIBE_AUTH failed",
    TOKEN_EXPIRED: "Sorry an error has occurred. Please try again.\nError code: TOKEN_EXPIRED",
    TOKEN_VALIDATION: "Sorry an error has occurred. Please try again.\nError code: TOKEN_VALIDATION failed",
    UNSUBSCRIBE_SUCCESS: "Stream unsubscribed,",
    USER_SIGNED_OUT: "Successfully signed-out",
    USERNAME_IS_NULL: "Sorry an error has occurred. Please try again.\nError code: USERNAME_IS_NULL",
    WAITING_FOR_APPROVAL: "Waiting for performer approval...",
    YOUR_CHOICE_OF_ROOM_NOT_AVAILABLE: "Your choice of room is not available",
    CUSTOMER_MAX_CONNECTION_TIME: 'You have connected for the max amount of time',
    CUSTOMER_MAX_CONNECTION_TIME_IN_PERIOD: 'You have connected for the max amount of time, please try again later',
    CUSTOMER_INSUFFICIENT_BALANCE: 'You do not have sufficient balance',


    REFRESH_PAGE: "Please don't refresh the page!\n\n\
    After click on \"Ok\" you have to login again!",

    BUSY_CAMERA: "We are unable to connect to the camera\n\n\
    Please try the following steps\n\n\
    - disconnect and reconnect the USB camera\n\n\
    - select another camera\n\n\
    - close other applications that are using the camera\n\n\
    - Log out and login\n\n\
    If we can still not connect to the camera, please contact support\n",

}