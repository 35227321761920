/**
 * Header component
 * @module Header
 * @version 1.2
 * @copyright Telecom2 Ltd.
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { UserLogout } from "../store/actions/userActions";
import {log} from '../store/helpers/logger';
import { getId, setState } from "../store/helpers/common";


/**
 * Header layout
 */
export class Header extends Component {
  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);
    const func = "constructor(),Header.jsx,";
    this.state = { performerName: "Performer Name" };
    log.trace("%sthis.state:%s", func, this.state);

  } //constructor

  /**
   *
   */
  componentDidMount() {
    const func = "componentDidMount(),Header.jsx,";
    const performerName = getId("performerName", func);
    const vThis = this;
    setState(
      {
        performerName: performerName,
      },
      () => {
        log.trace("%sperformerName:%s", func, vThis.state.performerName);
      }, vThis, func
    );
  } //componentDidMount

  /**
   * Call Logout()
   * @param {event} e
   */
  logoutHandler = (e) => {
    //const func = "logoutHandler(),Header.jsx,";
    //this is freeze the browser don't use it!
    //log.trace("%se:%s", func, e);
    UserLogout();
  };//logoutHandler

  render() {
    const func = "render(),Header.jsx,";
    log.trace("%s", func);
    return (
      <header className="header">
        <nav className="navbar navbar-expand-lg header-nav header-nav-home">
          <div className="logo-container">
            <div className="logo"></div>
          </div>

          <div className="main-menu-wrapper">
            <ul className="main-nav"></ul>
          </div>
          <ul className="nav header-navbar-rht">
            <li className="nav-item dropdown has-arrow logged-item">
              <Link to="#" id="dropdown-menu" className="dropdown-toggle nav-link"
                data-toggle="dropdown" aria-expanded="false">{this.state.performerName}</Link>
              <div className="dropdown-menu dropdown-menu-right">
                <Link id="btn-logout"
                  className="dropdown-item"
                  to="#"
                  onClick={(e) => this.logoutHandler(e)}
                >
                  Logout
                </Link>
              </div>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}//render