/**
* App
* @module App
* @version 1.1
* @copyright Telecom2 Ltd.
* @see  {@link https://reactjs.org/docs/react-api.html react API ref}
* 
*/

import React, { useEffect } from 'react';
import { AppRouter } from './Routes/Router';
import { log } from './store/helpers/logger';
import { Beforeunload } from 'react-beforeunload';
import { CleanExit, removeAllIds } from './store/actions/userActions';
import Swal from 'sweetalert2';
import { getId } from "./store/helpers/common";
import errors from "./errors";
import config from "./config.json";
import constants from './constants';
import { ConfigVerification } from './store/helpers/configVerification';
import { CommandCenter } from './store/helpers/commandCenter';

//https://github.com/JoseRFelix/react-css-theme-switcher
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';


const themes = {
  default: '/assets/templates/default/css/style.css',
  telecom2: '/assets/templates/telecom2/css/style.css',
  babestation: '/assets/templates/babestation/css/style.css',
  veristream: '/assets/templates/veristream/css/style.css',
  asktheanswer: '/assets/templates/asktheanswer/css/style.css',
};



export function App() {
  const func = "App(),App.js,";
  const twspAlert = Swal.mixin(config.sweetAlertError);
  /*try {
    const configVerification = new ConfigVerification();
    configVerification.main();
  }
  catch (error) {
    const errorMessage = `${error.message}`;
    twspAlert.fire(errors.CONFIGURATION_ERROR_TITLE, errorMessage).then((result) => {
      log.trace("%sresult:%s", func, result);
      if (result.isConfirmed) {
        log.trace("%svalue ok pressed", func);
        let performer = false;
        let customer = false;
        if (getId("performer_id", func, true)) {
          performer = true;
          log.trace("%sperformer", func);
        }
        if (getId("username", func, true)) {
          customer = true;
          log.trace("%scustomer", func);
        }

        if (performer) {
          removeAllIds(func + "_1");
          window.top.location.href = "/";
        }
        if (customer) {
          const returnUrl = getId("returnUrl", func);
          removeAllIds(func + "_2");
          window.top.location.href = returnUrl;
        }

      }
    });
  }//catch*/
  // eslint-disable-next-line no-unused-vars
  let commandCenter = new CommandCenter();
  const mod = "chat";
  log.trace("%s,%s,something", func, mod);
  useEffect(() => {
    const func = "App(useEffect),App.js,";
    let customer = false;
    let performer = false;

    let p = 0;
    //let reload = 0;
    if (window.performance.getEntriesByType("navigation")) {
      p = window.performance.getEntriesByType("navigation")[0].type;
      log.trace("%sp:%sp", func);
      if (p === 'reload') {
        //alert("reload");
        log.trace("%sThis page is reloaded", func);
        if (getId("performer_id", func, true)) {
          performer = true;
          log.trace("%sperformer", func);

        }

        if (getId("username", func, true)) {
          customer = true;
          log.trace("%scustomer", func);

        }

        if (!performer && !customer) {
          log.trace("%s!performer && !customer", func);

          return;
        }

      } else {
        //alert("not reload return");
        return;
      }
    }

    twspAlert.fire('', errors.REFRESH_PAGE).then((result) => {
      if (result.isConfirmed) {
        log.trace("%svalue ok pressed", func);
        if (performer) {
          removeAllIds(func + "_1");
          window.top.location.href = "/";
        }
        if (customer) {
          const returnUrl = getId("returnUrl", func);
          removeAllIds(func + "_2");
          window.top.location.href = returnUrl;
        }
      }
    })

    // Anything in here is fired on component mount.
    log.trace("%smount", func);
    return () => {
      // Anything in here is fired on component unmount.

      log.trace("%sumount", func);

    }

  }, [twspAlert])

  log.info("%sStart at:%s", func, new Date().toUTCString());
  return (
    <>
      <Beforeunload onBeforeunload={async (event) => {
        const func = "render(Beforeunload),App.js,";
        let p = 0;
        if (window.performance.getEntriesByType("navigation")) {
          p = window.performance.getEntriesByType("navigation")[0].type;
          log.trace("%sp:%sp", func);
          if ((p === 'reload') || (p === 'navigate')) {
            const params = {
              caller: func,
              test: false,
              status: constants.CONNECTION_CLOSED_BY_CLEAN_EXIT
            }

            await CleanExit(params);
          }

          return true;
        }
      }}>
        <ThemeSwitcherProvider
          defaultTheme={config.theme}
          insertionPoint={document.getElementById('inject-styles-here')}
          themeMap={themes}>
          <AppRouter />
        </ThemeSwitcherProvider>
      </Beforeunload>
    </>
  );
}//App

