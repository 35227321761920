/**
* Define redux store, persistor here
* @module Store
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
//import logger from 'redux-logger'
import { createLogger } from 'redux-logger'

import {rootReducer} from './reducer/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import config from '../config.json';
import {log} from './helpers/logger';

/**
 * convert winston log levels to redux-log levels
 * error,info, warn are equals at both system
 * @see {@link https://www.npmjs.com/package/redux-logger redux-logger}
 */
function convertLog() {
    const func = "convertLog(),store.js,";
    let retVal = null;
    log.trace("%sconfig.consolelog.redux:%s", func, config.consolelog.redux);
    //level = 'log': 'log' | 'console' | 'warn' | 'error' | 'info',
    switch (String(config.consolelog.redux)) {
        case 'trace':
            retVal = 'log';
            break;
        case 'debug':
            retVal = 'log';
            break;
        case 'info':
            retVal = 'info';
            break;
        case 'warn':
            retVal = 'warn';
            break;
        case 'error':
            retVal = 'error';
            break;
        case 'fatal':
            retVal = 'error';
            break;
        default:
            retVal = 'log';
            break;
    }
    log.trace("%sretVal:%s", func, retVal);
    return retVal;
};//convertLog
/**
 * custom redux logger definition
 */

const middlewares = [];

const logger = createLogger({
    level: convertLog(),
    collapsed: (getState, action, logEntry) => !logEntry.error

});

//turn off redux logger
if (config.consolelog.redux!=="off"){
    middlewares.push(logger);
}

/**
 * create Redux store
 * @module Store
 */
export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares, thunk)));
/**
 * create Redux persistor
 * @module Persistor
 */
export const persistor = persistStore(store);
/**
 * 
 */
// eslint-disable-next-line
export default { store, persistor };
