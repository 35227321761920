/**
* RootReducer module
* @module RootReducer
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import { combineReducers } from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {staticReducer} from './staticReducer';
import {StreamingsubscriberReducer} from './StreamingsubscriberReducer';
import {StreamingDetailsReducer} from './StreamingDetailsReducer';
import {ConnectedUserReducer} from './ConnectedUserReducer';
import {DashboardReducer} from './DashboardReducer';
import {log} from '../../store/helpers/logger';
const persistConfig = {key:'root',storage,whitelist:[]};

/**
 * @function rootReducer
 */
export const _rootReducer = combineReducers({
  static:staticReducer,
  streamingSubscribe:StreamingsubscriberReducer,
  StreamingDeatails:StreamingDetailsReducer,
  ConnectedUsers:ConnectedUserReducer,
  Dashboard:DashboardReducer
});

log.trace("rootReducer.js,rootReducer:",_rootReducer);

/**
 * 
 */
 const rootReducer= persistReducer(persistConfig,_rootReducer)
 export {rootReducer};
