/**
 * IncomeTip component
 * @module IncomeTip
 * @version 1.2
 * @copyright Telecom2 Ltd.
 */

import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import constants from "../../constants";
import { ListingArray } from "../../store/helpers/ListingArray";
import { getShutDown, getId, setState } from "../../store/helpers/common";
import { incomeTipListing } from "../../store/actions/dashboardActions";
import config from "../../config.json";
import { Scheduler } from '../../store/helpers/scheduler';


import { log } from '../../store/helpers/logger';

/**
 * Income tip component
 */
export class _incomeTip extends Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    const func = "constructor(),IncomeTip.jsx,";
    log.trace("%sprops:%s", func, props);
    super(props);
    this.state = {
      income_tip: [], //used for storing the list of income tip
    };
    this.durationTimer = 0;
    this.incomeRefefreshID = 0;
    this.lastFullRefresh=0;
  }

  componentDidMount() {
    const func = "componentDidMount(),IncomeTip.jsx,";
    log.trace("%start..%s", func);
    if (this.durationTimer === 0) {
      const schedulerData = {
        type: constants.SCHEDULER_DURATION,
        refreshRate: 1000,
      }
      const scheduler = new Scheduler(schedulerData, this.duration);
      this.durationTimer = scheduler.getTimer();
      log.trace('%sDuration scheduler started:%s', func, scheduler);
    }
    this.getIncomeTipList({ init: true });
    this.lastFullRefresh=new Date().getTime();
    if (this.incomeRefefreshID === 0) {
      const schedulerData = {
        type: constants.SCHEDULER_INCOME_TIPLIST,
        refreshRate: config.refresh.dashboardLists,
        init: false,
      }

      const scheduler = new Scheduler(schedulerData, this.getIncomeTipList);
      this.incomeRefefreshID = scheduler.getTimer();
      log.debug('%sIncome tiplist scheduler started:%s', func, scheduler);
    }

  }//componentDidMount

  componentWillUnmount() {
    const func = "componentWillUnmount(),IncomeTip.jsx,";
    log.trace("%start..%s", func);
    clearInterval(this.durationTimer);
    this.durationTimer = 0;
    clearInterval(this.incomeRefreshID);
    this.incomeRefreshID = 0;
  }//componentWillUnmount


  /**
   * this function update state from props
   * @param {*} newProps
   */
  UNSAFE_componentWillReceiveProps(newProps) {
    const func = "UNSAFE_componentWillReceiveProps(),IncomeTip.jsx,";
    log.trace("%snewProps:%s", func, newProps);
    log.trace('%sthis.state.income_tip:%s', func, this.state.income_tip);
    log.trace('%snewProps.income_tip_list:%s', func, newProps.income_tip_list);

    let listingArray = new ListingArray(this.state.income_tip, newProps.income_tip_list);
    log.trace('%slistingArray:%s', func, listingArray);
    if (listingArray.length) {
      setState({ income_tip: listingArray }, null, this, func);
    }
  } //UNSAFE_componentWillReceiveProps

  /**
  * Get income tip list
  * @function getIncomeTipList
  * @memberof Dashboard#
  * @return {json} income tip list   
  */
  getIncomeTipList = async (data) => {
    const func = "getIncomeTipList(),IncomeTip.jsx,";
    log.trace('%sdata:%s', func, data);
    if (getShutDown(func)) {
      return;
    }
    let init = data.init;
    log.trace('%sconfig.refresh.incomeTipFull:%s', func, config.refresh.incomeTipFull);
    if (config.refresh.incomeTipFull)
    {
      const now=new Date().getTime();
      log.trace('%snow:%s', func, now);
      if (this.lastFullRefresh+config.refresh.incomeTipFull < now)
      {
        init=true;  
        this.lastFullRefresh=now;
        log.trace('%sfullRefresh done:%s', func, new Date().toString());
      }
    }

    const tipListData = {
      streaming_id: getId("streaming_id", func),
      auth_token: getId("auth_token", func),
      init: init,
      caller: func,

    }
    log.trace('%sdata:%s', func, data);
    try {
      this.props.dispatch(incomeTipListing(tipListData));
    } catch (error) {
      log.fatal("%serror:%s", func, error);
    }

  }//getIncomeTipList


  getDuration(start) {
    const durationstartDate = new Date(start);
    const durationendDate = new Date();
    let durationseconds = (durationendDate.getTime() - durationstartDate.getTime()) / 1000;
    const durationdate = new Date(0);

    if (durationseconds < 0) durationseconds = 0;
    durationdate.setSeconds(durationseconds);
    return durationdate.toISOString().substr(11, 8);
  }

  duration = (data) => {
    const func = "duration(" + data.caller + "),IncomeTip.jsx,";
    log.trace("%s", func);
    const durations = document.getElementsByClassName('duration');
    if (!durations.length) return;

    for (let i = 0; i < durations.length; i++) {
      const status = durations[i].getAttribute("status");
      const tip = durations[i].getAttribute("duration");
      if (tip === constants.TIP) {
        durations[i].innerHTML = constants.TIP;

      } else if (status === constants.CONNECTION_ACCEPTED.toString()) {
        durations[i].innerHTML = this.getDuration(durations[i].getAttribute("start_datetime"));
      }
    }
  }//duration

  convertTime(utcTime) {
    const func = "convertTime(),IncomeTip.jsx,";
    log.trace("%sutcTime:%s", func,utcTime);
    //const date = moment.utc().format('YYYY-MM-DD HH:mm:ss');
    const stillUtc = Date.parse(utcTime);
    const retVal = moment(stillUtc).local().format('YYYY-MM-DD HH:mm:ss');
    //const retVal = utcTime;
    return retVal;
  }//convertTime

  getRowClass(status,duration)
  {
    const func = "getRowClass(),IncomeTip.jsx,";
    log.trace("%sstatus:%s", func,status);
    if ((status===constants.CONNECTION_ACCEPTED) && (duration !== constants.TIP))
    {
      return "active_session";
    }
    return "";
  }//getRowClass

  /**
   * render component
   */
  render() {
    const func = "render(),IncomeTip.jsx,";
    log.trace("%s", func);
    const { income_tip } = this.state;
    log.trace("%sincome_tip:%s", func, income_tip);

    return (
      <div className="card income-tips-table">
        <div className="card-body">
          <div className="table-responsive">
            <h2>Income/Tips</h2>
            <table id="tip" className="table table-hover table-center mb-0">
              <thead>
                <tr>
                  {
                    config.developer.incomeTip === true ?
                      (
                        <>
                          <th>ID</th>
                          <th>ST</th>
                        </>
                      ) : (<></>)
                  }
                  <th>User Name</th>
                  <th>Mode</th>
                  <th>Time</th>
                  <th>Duration</th>
                  <th>Net Pay</th>
                </tr>
              </thead>
              <tbody>
                {income_tip &&
                  income_tip.length > 0 &&
                  income_tip.map((item, index) => {
                    return (
                      <tr className={this.getRowClass(item.status,item.duration)} key={index}>
                        {
                          config.developer.incomeTip === true ?
                            (
                              <>
                                <td>{item.id}</td>
                                <td>{item.status}</td>
                              </>
                            ) : (<></>)
                        }
                        <td>{item.stripped_username}</td>
                        <td>{item.mode}</td>
                        {item.duration === constants.TIP ? (
                          <>
                            <td className="connected_time">{this.convertTime(item.created)}</td>
                          </>
                        ) : (
                          <>
                            <td className="connected_time">{this.convertTime(item.connected_time)}</td>
                          </>)
                        }

                        <td status={item.status} duration={item.duration} start_datetime={item.start_datetime} className="duration">{item.duration}</td>
                        <td>{constants.CURRENCY_SYMBOL}{item.gross_pay}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div >
    );
  }
}
/**
 * @function mapStateToProps
 * @param {*} state
 */
const mapStateToProps = (state) => ({
  income_tip_list: state.Dashboard.income_tip_list,
});
log.trace("IncomeTip.jsx,mapStateToProps:%s", mapStateToProps)
const connectedComponent = connect(mapStateToProps)(_incomeTip);
export { connectedComponent as IncomeTip };