/**
* CommandCenter wrapper
* @module CommandCenter
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import config from '../../config.json';
import { log, setLogLevel } from './logger';
import { Scheduler } from './scheduler';
import constants from "../../constants";
import { getShutDown, BaseUrl, apiCallAwait, getId } from "./common";
import { CleanExit } from "../actions/userActions";
import Swal from 'sweetalert2';
//import errors from "../../errors";


export class CommandCenter {
    refreshTimer = 0;

    /**
     */
    constructor() {
        //const func = "constructor(),commandCenter.js,";
        this.main();
    }
    /**
     */
    main() {
        const func = "main(),commandCenter.js,";
        if ((config.refresh.commandCenter === "off") || (config.refresh.commandCenter === 0)) {
            log.debug("%s Command center's refresh is off.", func, config.refresh.commandCenter);
            return;
        }
        if (this.refreshTimer === 0) {
            const schedulerData = {
                type: constants.SCHEDULER_COMMAND_CENTER,
                refreshRate: config.refresh.commandCenter,
                obj: this,
            }
            const scheduler = new Scheduler(schedulerData, this.refreshCommands);
            this.refreshTimer = scheduler.getTimer();
            log.trace('%s scheduler:%s', func, scheduler);
        }
    }//main

    async refreshCommands(data) {
        const func = "refreshCommands(),commandCenter.js,";
        log.trace("%sdata:%s", func, data);
        if (getShutDown(func)) {
            log.trace("%sgetShutDown fired..:%s", func);
            return;
        }
        const callData = {
            streaming_id: getId("streaming_id", func),
            auth_token: getId("auth_token", func),
        }
        log.trace("%scallData:%s", func, callData);
        //we don't have yet id's for auth 
        if ((callData.streaming_id === null) || (callData.auth_token === null)) {
            log.debug("%snot enough data to pull %s", func, callData);
            return;
        }

        const apiUrl = `${BaseUrl}${constants.A_COMMAND_CENTER_PULL}`;
        log.trace("%sapiUrl:%s", func, apiUrl);
        const result = await apiCallAwait(apiUrl, 'POST', callData).catch((error) => {
            log.error("%serror:%s", func, error);
        });
        log.trace("%sresult:%s", func, result);
        if (result.response === constants.RESULT_SUCCESS) {
            if ((result.message.log) && (result.message.log.execute === "on")) {
                await data.obj.executeCmdLog(result.message.log);
            }
            if ((result.message.exit) && (result.message.exit.execute === "on")) {
                await data.obj.executeCmdExit(result.message.exit);
            }
            if ((result.message.notify) && (result.message.notify.execute === "on")) {
                await data.obj.executeCmdNotify(result.message.notify);
            }

        }
    }//refreshCommands

    async executeCmdLog(logCmd) {
        const func = "executeCmdLog(),commandCenter.js,";
        const username = getId("username", func);
        if (logCmd) {
            if ((username === logCmd.username) || (logCmd.username === '*')) {
                switch (logCmd.level) {
                    case "fatal":
                    case "error":
                    case "warn":
                    case "info":
                    case "debug":
                    case "trace":
                        setLogLevel(logCmd.level);
                        log.debug(func, "Set log level executed..");
                        break;
                    default:
                        log.error("%sInvalid log level received:%s", func, logCmd.level);
                        break;
                }
            }
        }
    }//executeCmdLog

    async executeCmdExit(exitCmd) {
        const func = "executeCmdExit(),commandCenter.js,";
        const username = getId("username", func);
        if (exitCmd) {
            if ((username === exitCmd.username) || (exitCmd.username === '*')) {
                const params =
                {
                    caller: func,
                    test: false,
                    status: constants.CONNECTION_CLOSED_BY_CLEAN_EXIT,
                }
                const exitResult = await CleanExit(params);
                log.trace("%sexitResult:%s", func, exitResult);
                const twspAlert = Swal.mixin(config.sweetAlertError);
                //catch was not recognised
                // const result = await twspAlert.fire('', message).catch((error) => {
                //     log.error("%s,twspAlert.fire,catch(error):%s", func, error.message);
                // });
                const result = await twspAlert.fire('', exitCmd.message);
                log.debug("%sExit command executed..%s", func, exitCmd.message);

                if (result.isConfirmed) {
                    log.trace("%sconfirmed", func);
                    //performer identification 
                    let redirectUrl = null;
                    if (getId('stream_name', func) &&
                         getId("performer_id", func)) {
                         redirectUrl = "/";
                     } else {
                         redirectUrl = getId('returnUrl', func);
                         log.trace("%s,redirectUrl:%s", func, redirectUrl);
                     }
                    window.top.location.href = redirectUrl;

                }
            }
        }
    }//executeCmdExit
    
    async executeCmdNotify(notifyCmd) {
        const func = "executeCmdNotify(),commandCenter.js,";
        const username = getId("username", func);
        if (notifyCmd) {
            if ((username === notifyCmd.username) || (notifyCmd.username === '*')) {
                const twspAlert = Swal.mixin(config.sweetAlertError);
                //catch was not recognised
                // const result = await twspAlert.fire('', message).catch((error) => {
                //     log.error("%s,twspAlert.fire,catch(error):%s", func, error.message);
                // });
                const result = await twspAlert.fire('', notifyCmd.message);
                log.debug("%sExit command executed..%s", func, notifyCmd.message);

                if (result.isConfirmed) {
                    log.info("%sNotify message was confirmed by user", func);
                }
            }
        }
    }//executeCmdNotify


}//CommandCenter
