/**
* Chat component
* @module Chat
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ch } from "../../ChatHandler/ChatHandler";
import Moment from "moment";
import Picker from 'emoji-picker-react';
import ScrollableFeed from 'react-scrollable-feed';
import {
  donation,
  notificationContent,
  blockSubscriberEvent,
  denySubscriberPermissionEvent,
  allowSubscriberPermissionEvent
} from "../../store/actions/streamingsubscriberActions";
import {ChatNotification} from './ChatNotification';
import { connect } from "react-redux";
import {
  logToMysql
} from "../../store/actions/dashboardActions";
import {log} from '../../store/helpers/logger';
import { setLocalStorage, getId, setState, getShutDown, displayPopup } from "../../store/helpers/common";
import constants from "../../constants";
import errors from "../../errors";
import { getChatUserName } from "../../store/helpers/common";
import config from '../../config.json'
import {Scheduler} from '../../store/helpers/scheduler';
import Sound from '@studysync/react-sound';


/**
 * Chat component 
 * 
 * These are the message type codes
 * - 0 - Message
 * - 1 - Accepted
 * - 2 - Denied
 * - 3 - Kick out
 * - 4 - joining,leave
 * 
*/
export class _chat extends Component {
  #playSound = [];
  constructor(props) {
    const func = "constructor(),Chat.js,";
    log.trace('%sprops:%s', func, props);
    super(props);
    /**
     * @property {string} stream_name used for storing stream Name
     * @property {array}  chats used for chat data 
     * @property {string} used for storing the text
     * @property {*} readError 
     * @property {*} writeError 
     * @property {*} ChosenEmoji used for storing emoji data
     * @property {bool} emojiShow used for storing value of emoji pop show or hide
     * @property {string} block_username used for storing the username of block user
     * @property {string} allow_subscriber_permission_username used for storing the username of allow user
     * @property {string} deny_subscriber_permission_username used for storing the username of deny user
     */
    this.state = {
      stream_name: "",
      chats: [],
      content: "",
      readError: null,
      writeError: null,
      ChosenEmoji: null,
      emojiShow: false,
      block_username: "",
      allow_subscriber_permission_username: null,
      deny_subscriber_permission_username: null,
      emojiIcon: "fa fa-smile-o",
    };
    this.refreshTimer = 0;
    this.emojiWindow = React.createRef();
    this.customer = false;
    if (getId('customer')) {
      this.customer = true;
    }
  }//constructor

  /**
   * 
   */
  componentDidMount() {
    const func = "componentDidMount(),Chat.js,";
    log.trace('%s call UpdateCustomer(),this.props%s', func, this.props);
    setLocalStorage('lastPing', 0, func);
    // Adding a click event listener
    document.addEventListener('click', this.handleOutsideClick);

    if (ch.getHandler() === constants.CHAT_HANDLER_MYSQL) {
      log.trace('%s config.refresh.refreshChat:%s', func, config.refresh.refreshChat);
      if (this.refreshTimer === 0) {
        const schedulerData = {
          type: constants.SCHEDULER_REFRESH_CHAT,
          refreshRate: config.refresh.refreshChat,
        }
        const scheduler = new Scheduler(schedulerData, this.refreshChat);
        this.refreshTimer = scheduler.getTimer();
        log.trace('%s scheduler:%s', func, scheduler);
      }
    }
  } //componentDidMount

  /**
   * 
   */
  componentWillUnmount() {
    const func = "componentWillUnmount(),Chat.js,";
    if (this.refreshTimer) clearInterval(this.refreshTimer);
    this.refreshTimer = 0;
    log.trace('%stimers are cleared out', func);
  }//componentWillUnmount


  /**
   * this function collect a chat message
   * @async
   * @param {string } stream_name 
   */
  refreshChat = async (data) => {
    const func = "refreshChat(" + data.caller + "),Chat.js,";
    log.trace('%sdata:%s', func, data);
    if (getShutDown()) {
      return;
    }
    const stream_name = this.props.stream_name;
    if (stream_name === undefined) {
      log.trace('%sstream_name is undefined', func);
      return;
    }
    if (!stream_name) {
      log.trace('%sstream_name is null or empty', func);
      return;
    }
    log.trace('%sstream_name:%s', func, stream_name);
    setState({ stream_name: stream_name }, null, this, func);
    const uid = getId('streaming_id', func);
    if (uid) {
      this.uid = uid;
      setState({ uid: this.uid }, null, this, func);
      log.trace('%sUser is signed-in:%s', func, uid);
    } else {
      log.error('%sNo user is signed-in', func);
    }

    setState({ readError: null }, null, this, func);
    log.trace('%sthis.state-2:%s', func, this.state);

    let chatId = 0;
    let chatDirection = null;
    let i = 0;
    while (i < this.state.chats.length) {
      if (this.state.chats[i].type === constants.CHAT_MESSAGE) {
        if (this.state.chats[i].id > chatId) {
          chatId = this.state.chats[i].id;
          chatDirection = constants.CHAT_DIRECTION_DOWN;
        }
      }
      i++;
    }
    const pullData =
    {
      id: chatId,
      stream_name: stream_name,
      direction: chatDirection,
      caller: func,
    }
    // eslint-disable-next-line no-unused-vars
    let errorMessage = null;
    log.trace('%spullData:%s', func, pullData);
    const result = await ch.pull(pullData).catch((error) => {
      errorMessage = error.message;
      log.fatal("%serror:%s", func, error);
    })

    log.trace("%sresult:%s", func, result);
    if (!result) {
      return;
    }

    if (!result.length) {
      return;
    }

    if (result.response === constants.RESULT_ERROR) {
      errorMessage = result.message;
    }

    if (errorMessage) {
      if (errorMessage !== errors.NO_STREAM_FOUND) {
        log.error("%serror:%s", func, errorMessage);
        setState({ readError: errorMessage }, null, this, func);
        displayPopup(errorMessage, func);
      }
      log.trace("%serrorMessage:%s", func, errorMessage);
      return;
    }
    //process only if result is not null
    log.trace('%sresult has value:%s', func, result);
    let newChatsArray = [];

    if (this.state.chats.length) {
      //when publisher stop and start a new stream , chatbox cleared out
      if (this.state.chats[0].stream_name === result[0].value.stream_name) {
        newChatsArray = this.state.chats.slice();
      }
    }


    for (let i = 0; i < result.length; i++) {
      //this is helps to get duplicate chat messages when system resources are low
      let found = false;
      for (let j = 0; j < newChatsArray.length; j++) {
        if (newChatsArray[j].id === result[i].value.id) {
          found = true;
          break;
        }
      }
      if (!found) {
        newChatsArray.push(result[i].value);
      }
    }

    log.trace('%snewChatsArray:%s', func, newChatsArray);
    this.setState({ chats: newChatsArray });
    log.trace('%sthis.state.chats:%s', func, this.state.chats);
  }//refreshChat

  setDebugMessage(mess, caller) {
    //log.trace(caller, mess);
    //document.getElementsByClassName("message")[0].innerHTML = mess;
  }


  /**
   * this function store text message
   * @param {*} event 
   */
  handleChange = (event) => {
    const func = "handleChange(),Chat.js,";
    log.trace('%sevent:%s', func, event);
    const data = event.target.value;
    setState({ content: data }, null, this, func);
  };//handleChange



  /**
   * this function send simple text message
   * @async
   * 
   */
  handleSubmit = async () => {
    const func = "handleSubmit(),Chat.js,";
    if (this.state.content !== "") {
      //performerName getId
      const username = getChatUserName(func);
      //const username = constants.PUBLISHER;
      log.info('%sUser %s sent a chat message', func, username);
      setState({ writeError: null }, null, this, func + "(writeError)");
      if (this.state.uid === undefined) {
        log.error('%suid is undefined', func);
        return;
      }

      const pushData = {
        auth_token: getId("auth_token", func),
        streaming_id: getId("streaming_id", func),
        stream_name: this.state.stream_name,
        content: this.state.content,
        timestamp: Date.now(),
        uid: this.state.uid,
        type: 0,       // 0 - Message
        status: false,
        caller: func,
      };

      log.debug('%sChat message will send with these parameters:%s', func, pushData);
      // eslint-disable-next-line no-unused-vars
      let errorMessage = null;
      const result = await ch.push(pushData).catch((error) => {
        errorMessage = error.message;
        log.fatal("%serror:%s", func, error);
      })

      log.debug("%sResult of sent chat message:%s", func, result);
      if (result.response === constants.RESULT_ERROR) {
        errorMessage = result.message;
      }

      if (errorMessage) {
        log.fatal("%serrorMessage:%s", func, errorMessage);
        setState({ writeError: errorMessage }, null, this, func + "(push-error)");
        displayPopup(errorMessage, func);
        return;
      }
      let streaming_id = getId('streaming_id', func, true);
      log.trace('%sstreaming_id-1:%s', func, streaming_id);
      if ((streaming_id === undefined) || (streaming_id === null) || (streaming_id === '')) {
        log.fatal("%schatlog failed, streaming_id is null", func);
        setState({ content: "" }, null, this, func + "(streaming_id)");
        return;
      }
      const logData = {
        auth_token: getId('auth_token', func),
        streaming_id: getId('streaming_id', func),
        message: this.state.content,
        stream_name: getId('stream_name', func),
        created: Date.now(),
        caller: func,
      };
      this.props.dispatch(logToMysql(logData));
      log.debug('%sChat message %s is logged into chatlog table.', func, logData);
      setState({ content: "" }, null, this, func + "(push-result)");

    }
  };//handleSubmit


  /**
   * this function contain enter button press functionality
   * @param {*} event 
   */
  onKeyDownHandler = (event) => {
    const func = "onKeyDownHandler(),Chat.js,";
    //log.trace('%sevent:%s', func, event); cause error
    if (event.key === "Enter") {
      if (this.state.content !== "") {
        log.trace('%scall this.handleSubmit()', func);
        this.handleSubmit();
      }
    }
  };//onKeyDownHandler

  /**
   * this function contain emoji functionality 
   * @param {*} event 
   * @param {*} emojiObject 
   */
  onEmojiClick = (event, emojiObject) => {
    const func = "handleSubmit(),Chat.js,";
    log.trace('%sevent:%s', func, event);
    log.trace('%semojiObject:%s', func, emojiObject);

    this.setState({
      ChosenEmoji: emojiObject,
    }, () => {
      this.setState({
        content: this.state.content + this.state.ChosenEmoji.emoji
      })
      log.trace('%ssetState:%s', func, this.state);
    });
  }//onEmojiClick

  showNotification(item) {
    const func = "showNotification(),Chat.js,";
    log.trace('%sitem:%s', func, item);
    let retVal = false;
    //client side , disable it at this time
    let username = getId("username");
    if (getId("username")) {
      return retVal;
    } else {
      username = getId("performerFullName");
    }
    if ((item.to_username === '*') || (item.to_username === username)) {
      retVal = true;
    }
    return retVal;
  }//showNotification



  /**
   * 
   * @param {*} prevProps 
   * @param {*} prevState 
   * @see {@link https://reactjs.org/docs/react-component.html#componentdidupdate componentDidUpdate}
   */
  componentDidUpdate = async (prevProps, prevState) => {
    const func = "componentDidUpdate(),Chat.js,";
    log.trace('%sprevProps:%s', func, prevProps);
    log.trace('%sprevState:%s', func, prevState);
    let el = this.refs.wrap;
    el.scrollTop = el.scrollHeight;

    let nextState = this.state.chats;
    log.trace('%snextState:%s', func, nextState);
    let chatMessages = [];
    for (let i = 0; i < nextState.length; i++) {
      const lastitem = nextState[i];
      log.trace('%slastitem:%s', func, lastitem);

      if (!this.showNotification(lastitem)) continue;


      try {
        log.trace('%slastitem:%s', func, lastitem);
        if (lastitem.type === constants.CHAT_AUTO_ACCEPTED) {
          //not require any processing this item
          nextState.splice(i, 1);//remove processed items from the array
        }
        //== wait for approval notification
        if (lastitem.type === constants.CHAT_WAIT_FOR_APPROVAL) {
          log.trace('%slastitem-wait-for-approval:%s', func, lastitem);
          chatMessages.push(lastitem);
          nextState.splice(i, 1);//remove processed items from the array

          const updateData = {
            auth_token: getId("auth_token", func),
            streaming_id: getId("streaming_id", func),
            stream_name: this.state.stream_name,
            uid: lastitem.uid,
            status: true,
            type: constants.CHAT_WAIT_FOR_APPROVAL,
            to_username: lastitem.to_username,
            username: lastitem.username,
            caller: func,
          };

          log.trace('%supdateData-1:%s', func, updateData);
          const result = await ch.update(updateData).catch((error) => {
            log.fatal("%serror:%s", func, error);
          })
          if (result.response === constants.RESULT_ERROR) {
            log.fatal("%sresult.response:%s", func, result.response);
          }
          log.debug("%sWait for approval notification updated successfully in db,result:%s", func, result);
        }//CHAT_WAIT_FOR_APPROVAL

        //== donation notification
        if (lastitem.type === constants.CHAT_DONATION) {
          log.trace('%slastitem-donation:%s', func, lastitem);
          chatMessages.push(lastitem);
          nextState.splice(i, 1);
          const updateData = {
            auth_token: getId("auth_token", func),
            streaming_id: getId("streaming_id", func),
            stream_name: this.state.stream_name,
            uid: lastitem.uid,
            status: true,
            type: constants.CHAT_DONATION,
            to_username: lastitem.to_username,
            username: lastitem.username,

            caller: func,
          };

          log.trace('%supdateData-1:%s', func, updateData);
          const result = await ch.update(updateData).catch((error) => {
            log.fatal("%serror:%s", func, error);
          })
          log.trace("%sresult:%s", func, result);
          if (result.response === constants.RESULT_ERROR) {
            log.fatal("%sresult.response:%s", func, result.response);
          }
          log.debug("%sDonation notification updated successfully in db,result:%s", func, result);
        }//CHAT_DONATION

        //== leave notification
        if (lastitem.type === constants.CHAT_LEAVE) {
          log.trace('%slastitem-leave:%s', func, lastitem);
          //this.props.dispatch(notificationContent(lastitem));
          chatMessages.push(lastitem);
          nextState.splice(i, 1);
          const updateData = {
            auth_token: getId("auth_token", func),
            streaming_id: getId("streaming_id", func),
            stream_name: this.state.stream_name,
            uid: lastitem.uid,
            status: true,
            type: constants.CHAT_LEAVE,
            to_username: lastitem.to_username,
            username: lastitem.username,
            caller: func,
          };

          log.trace('%supdateData-2:%s', func, updateData);
          const result = await ch.update(updateData).catch((error) => {
            log.fatal("%serror:%s", func, error);
          })
          log.trace("%sresult:%s", func, result);
          if (result.response === constants.RESULT_ERROR) {
            log.fatal("%sresult.response:%s", func, result.response);
          }
          log.debug("%sCustomer leaving notification updated successfully in db,result:%s", func, result);
        }//leave notification


        if (lastitem.type === constants.CHAT_BLOCKED && (lastitem.status === false) && (this.props.subscriberChat === true)) {
          nextState.splice(i, 1);
          const updateData = {
            auth_token: getId("auth_token", func),
            streaming_id: getId("streaming_id", func),
            stream_name: this.state.stream_name,
            uid: lastitem.uid,
            status: true,
            type: constants.CHAT_BLOCKED,
            to_username: lastitem.to_username,
            username: lastitem.username,
            caller: func,
          };

          log.trace('%supdateData-3:%s', func, updateData);
          const result = await ch.update(updateData).catch((error) => {
            log.fatal("%serror:%s", func, error);
          })
          log.trace("%sresult:%s", func, result);
          if (result.response === constants.RESULT_ERROR) {
            log.fatal("%sresult.response:%s", func, result.response);
          }
          log.trace('%sredux dispatch,(lastitem.to_username)%s:', func, lastitem.to_username);
          this.props.dispatch(blockSubscriberEvent(lastitem.to_username));
          log.debug("%sBlock updated successfully in db %s", func, lastitem);
        }
        if ((lastitem.type === constants.CHAT_DENIED) && (lastitem.status === false) && (this.props.subscriberChat === true || this.props.subscriberChat === false)) {
          nextState.splice(i, 1);
          const updateData = {
            auth_token: getId("auth_token", func),
            streaming_id: getId("streaming_id", func),
            stream_name: this.state.stream_name,
            uid: lastitem.uid,
            status: true,
            type: constants.CHAT_DENIED,
            to_username: lastitem.to_username,
            username: lastitem.username,
            caller: func,
          };

          log.trace('%supdateData-4:%s', func, updateData);
          const result = await ch.update(updateData).catch((error) => {
            log.fatal("%serror:%s", func, error);
          })
          log.trace("%sresult:%s", func, result);
          if (result.response === constants.RESULT_ERROR) {
            log.fatal("%sresult.response:%s", func, result.response);
          }
          log.trace('%sredux dispatch,denySubscriberPermissionEvent(lastitem.to_username)%s:', func, lastitem.to_username);
          this.props.dispatch(denySubscriberPermissionEvent(lastitem.to_username));
          log.debug("%sDeny updated successfully in db %s", func, lastitem);
        }

        if ((lastitem.type === constants.CHAT_ACCEPTED) && (lastitem.status === false) && (this.props.subscriberChat === true)) {
          nextState.splice(i, 1);
          const updateData = {
            auth_token: getId("auth_token", func),
            streaming_id: getId("streaming_id", func),
            stream_name: this.state.stream_name,
            uid: lastitem.uid,
            status: true,
            type: constants.CHAT_ACCEPTED,
            to_username: lastitem.to_username,
            username: lastitem.username,
            caller: func,
          };

          log.trace('%supdateData-5:%s', func, updateData);
          let errorMessage = null;
          const result = await ch.update(updateData).catch((error) => {
            log.fatal("%serror:%s", func, error);
            errorMessage = error.message;
          })
          log.trace("%sresult:%s", func, result);
          if (result.response === constants.RESULT_ERROR) {
            log.fatal("%sresult.response:%s", func, result.response);
            errorMessage = result.response;
            displayPopup(errorMessage, func);
          }

          log.trace('%sredux dispatch,allowSubscriberPermissionEvent(lastitem.to_username)%s:', func, lastitem.to_username);
          this.props.dispatch(allowSubscriberPermissionEvent(lastitem.to_username));
          log.debug("%sChat accepted successfully in db %s", func, lastitem);
        }


      } catch (error) {
        log.fatal('%serror:%s', func, error);
        setState({ writeError: error.message }, null, this, func);
      }
    }//for
    if (chatMessages.length) {
      log.trace('%schatMessages:%s', func, chatMessages);
      let finalMessage = chatMessages[0];
      //if we have only one message let's process immediatelly
      if (chatMessages.length === 1) {
        this.props.dispatch(notificationContent(finalMessage));
        log.info('%sNotification is displayed:%s', func, finalMessage);
        return;
      }

      //let's make a copy from first item
      // {
      //   "id":5078,
      //   "to_username":"testperformer200-1",
      //   "content":"wait for approval",
      //   "status":false,
      //   "timestamp":1638205326172,
      //   "type":6,
      //   "uid":"36648cde-6aed-11eb-abe9-9dcf1dc0e13a",
      //   "username":"testcustomer100-1",
      //   "created":"2021-11-29T17:02:06.000Z",
      //   "stream_name":"717026b6-9c71-4f6a-a2e4-440a06ee5bbc"
      // }
      let donationUsers = "";
      let leaveUsers = "";
      let waitUsers = "";
      let donationCounter = 0;
      let waitCounter = 0;
      let leaveCounter = 0;

      for (let i = 0; i < chatMessages.length; i++) {

        if (chatMessages[i].type === constants.CHAT_DONATION) {
          donationUsers = donationUsers + chatMessages[i].username;
          if (donationCounter > 1) {
            donationUsers = donationUsers + ",";
          }
          donationCounter++;
        }

        if (chatMessages[i].type === constants.CHAT_LEAVE) {
          leaveUsers = leaveUsers + chatMessages[i].username;
          if (leaveCounter > 1) {
            leaveUsers = leaveUsers + ",";
          }
          leaveCounter++;
        }

        if (chatMessages[i].type === constants.CHAT_WAIT_FOR_APPROVAL) {
          waitUsers = waitUsers + chatMessages[i].username;
          if (waitCounter > 1) {
            waitUsers = waitUsers + ",";
          }
          waitCounter++;
        }
      }//for

      //reset genuine message first before add summarized
      finalMessage.content = [];

      if (donationCounter) {
        finalMessage.content = `Donations received from:${donationUsers}`;
      }
      if (waitCounter) {
        finalMessage.content = `${finalMessage.content}\n\nWaiting for approval:${waitUsers}`;
      }
      if (leaveCounter) {
        finalMessage.content = `${finalMessage.content}\n\nUsers left:${leaveUsers}`;
      }
      log.trace('%sfinalMessage%s:', func, finalMessage);
      this.props.dispatch(notificationContent(finalMessage));
      log.info('%sNotification is displayed:%s', func, finalMessage);
      //overwrite the current chat , with the new processed chat array 
      setState({ chat: nextState }, null, this, func + "(nextState)");
    }//chat messages has length
  }//componentDidUpdate

  /**
   * this function contain dontaion functionality 
   * @async
   * @param {number} value 
   */
  donationHandler = async (value) => {
    const func = "donationHandler(),Chat.js,";
    log.trace('%svalue:%s', func, value);

    const data = {
      streaming_id: getId('streaming_id', func),
      auth_token: getId('auth_token', func),
      stream_name: this.state.stream_name,
      donationAmount: value,
      caller: func,
    }

    log.trace('%sdata:%s', func, data);

    let result = await this.props.dispatch(donation(data)).catch((error) => {
      log.fatal("%s.catch(result),error:%s", func, error);
      result = error;
    });
    log.trace("%sresult:%s", func, result);

    if (result.response === constants.RESULT_ERROR) {
      const pushData = {
        auth_token: getId("auth_token", func),
        streaming_id: getId("streaming_id", func),
        stream_name: this.state.stream_name,
        content: 'Tip Unsuccessful, please top up',
        timestamp: Date.now(),
        uid: Math.floor(Math.random() * 101),
        type: 0,       // 0 - Message
        status: false,
        caller: func,
        username: 'Tip Unsuccessful'
      };
      this.state.chats.push(pushData);
      //this.setState({ chats: pushData });
      //displayPopup(result.message, func, false);
      log.info("%s%s%s tip is unsuccessful", func,value,constants.CURRENCY_SYMBOL);
      log.debug("%s%s tip is unsuccessful", func,pushData);
      return;
    }

    if (result.response === constants.RESULT_SUCCESS) {
      const pushData = {
        auth_token: getId("auth_token", func),
        streaming_id: getId("streaming_id", func),
        stream_name: this.state.stream_name,
        content: 'Tip Successful',
        timestamp: Date.now(),
        uid: Math.floor(Math.random() * 101),
        type: 0,       // 0 - Message
        status: false,
        caller: func,
        username: 'Tip Successful'
      };
      this.state.chats.push(pushData);
      //displayPopup("Donate Successfully", func, true);
      log.info("%s%s%s tip is successful", func,value,constants.CURRENCY_SYMBOL);
      log.debug("%s%s tip is successful", func,pushData);
    }
  }//donationHandler

  handleOutsideClick = (event) => {
    const emojiWindow = document.getElementsByClassName("emoji-picker-react");
    const emojiButton = document.getElementById("btn-emoji");

    //this is happen when customer exited, no event.path available
    if ((!event) || (!event.path)) {
      return;
    }
    if (event.path[1] === emojiButton) {

      return;
    }
    if (this.state.emojiShow) {
      if (!event.path.includes(emojiWindow[0])) {
        if (this.state.emojiIcon === "fa fa-keyboard-o") {
          //alert('clicked outside of box1!');
          this.emojiToggle();
        }

      }
    }
  }//handleOutsideClick

  emojiToggle() {
    const func = "emotionToggle(),Chat.js,";
    log.trace("%stoggle got hit", func);
    let newEmoji = "fa fa-smile-o";
    if (this.state.emojiIcon === "fa fa-keyboard-o") {
      newEmoji = "fa fa-smile-o";
    } else {
      newEmoji = "fa fa-keyboard-o";
    }
    setState(
      {
        emojiShow: !this.state.emojiShow,
        emojiIcon: newEmoji,
      }, null, this, func);
  }

  isNeedToPlaySound(chat) {
    const func = "isNeedToPlaySound(),Chat.js,";
    log.trace("%sisNeedToPlaySound id", func, chat);
    let retVal = true;
    //don't need to play sound at customer side
    if (this.customer) return false;
    //check id aviabilty in array
    for (let i = 0; i < this.#playSound.length; i++) {
      if (this.#playSound[i] === chat.id) {
        retVal = false;
        break;
      }
    }
    //if not yet stored, push it there
    if (retVal) {
      this.#playSound.push(chat.id);
    }
    //performer message don't need to make sound
    if (chat.uid === getId('streaming_id')) {
      retVal = false;
    }
    //return false if don't need to play sound,because already played and this chat id
    //in the controll array
    //return true if chat id not found yet in the controll array and need to play the sound
    return retVal;
  }//isNeedToPlaySound

  render() {
    const func = "render(),Chat.js,";
    log.trace("%srender executed", func);
    log.trace("%sthis.state.chats:%s", func, this.state.chats);
    //detect side of the application customer or performer
    const username = getId("username", func);
    let sideOfChat = "performer";
    if (username) sideOfChat = "customer";
    return (
      <>
        <ChatNotification />
        <div className={`card card-table chat-side-sec sectionBackgroundColor ${this.props.donationButtons === true ? "video-chat-side-sec" : ""}`}>
          <div className="card-body">
            <div className="chat-cont-right" style={this.props.iframeWidth ? { maxWidth: "100%" } : {}}>
              <div className="message"></div>
              <div id="chat-body" className="chat-body" ref="wrap">
                <ScrollableFeed className="feed">
                  <ul className="list-unstyled">
                    {this.state.chats.filter(filteritem => filteritem.type === 0).map((chat, index) => {
                      log.trace("%schat:%s", func, chat);
                      let stripped_username = null;
                      if(chat.alias){
                        stripped_username = chat.alias;
                      } else {
                        stripped_username = chat.username.replace(/-[0-9]*$/mg, "");
                      }
                      //const stripped_username = chat.username.replace(/-[0-9]*$/mg, "");
                      let d = new Date(chat.timestamp);
                      let chatDate = Moment(d).format("DD-MM-YYYY hh:mm");
                      return (
                        <div className="chat-block" key={chat.id}>
                          {this.isNeedToPlaySound(chat) ?
                            <Sound url={"assets/sounds/click.mp3"}
                              playStatus={Sound.status.PLAYING}
                            />
                            :
                            ""
                          }
                          <li >
                            <div >
                              <span className="chat-name">{stripped_username}</span>&nbsp;&nbsp;&nbsp;
                              <span className="chat-date">{chatDate}</span>
                            </div>
                            <div className="chat-content">
                              {chat.content}
                            </div>

                          </li>
                        </div>

                      );
                    })}
                  </ul>


                </ScrollableFeed>
              </div>

                <div className={this.state.stream_name !== "" ? "chat-footer visible" : "chat-footer invisible"}>
                  {this.props.subscriberChat === true ? (

                    <div className="input-group row">
                      <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 chatEmojiDiv input-group-prepend">
                          <i className={this.state.emojiIcon} onClick={(e) => this.emojiToggle()} />
                      </div>
                      <input id="chatInput" type="text" autoComplete="off" className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9 input-msg-send form-control" placeholder="Type something" onChange={(e) => this.handleChange(e)} value={this.state.content} onKeyDown={(e) => this.onKeyDownHandler(e)} onPaste={this.pastehandler} />
                      {this.state.writeError ? <p>{this.state.writeError}</p> : null}
                      <div className="input-group-append col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 chatSubmitDiv">
                        <button type="button" id="btn-send" className="btn msg-send-btn " onClick={(e) => this.handleSubmit(e)}>
                          <i className="fab fa-telegram-plane" />
                        </button>
                      </div>

                    </div>
                  ) : null}
              </div>
              <div ref={this.emojiWindow} className={`emoji-area-${sideOfChat} ${this.state.emojiShow ? "show" : "hide"}`}>
                <Picker onEmojiClick={this.onEmojiClick} />
                {this.props.subscriberChat === true ? (
                  <div><br /><br /><br /></div>
                ) : null}
              </div>

              {this.props.donationButtons === true ? (
                <div className="row streaming-btn-sec">
                  <Link to="#" id="btn-pay_now_10" className="col-xl-3 col-lg-3 col-md-3 col-xs-6 col-sm-6 btn btn-green btn-donation" onClick={() => this.donationHandler(constants.DONATION_AMOUNT_10)}>
                    {errors.PAY_NOW_PREFIX}&nbsp;{constants.CURRENCY_SYMBOL}&nbsp;{constants.DONATION_AMOUNT_10}&nbsp;{errors.PAY_NOW_SUFFIX}
                  </Link>
                  &nbsp;
                  <Link to="#" id="btn-pay_now_25" className="col-xl-3 col-lg-3 col-md-3 col-xs-6 col-sm-6 btn btn-green btn-donation" onClick={() => this.donationHandler(constants.DONATION_AMOUNT_25)}>
                    {errors.PAY_NOW_PREFIX}&nbsp;{constants.CURRENCY_SYMBOL}&nbsp;{constants.DONATION_AMOUNT_25}&nbsp;{errors.PAY_NOW_SUFFIX}
                  </Link>
                  &nbsp;
                  <Link to="#" id="btn-pay_now_50" className="col-xl-3 col-lg-3 col-md-3 col-xs-6 col-sm-6 btn btn-green btn-donation" onClick={() => this.donationHandler(constants.DONATION_AMOUNT_50)}>
                    {errors.PAY_NOW_PREFIX}&nbsp;{constants.CURRENCY_SYMBOL}&nbsp;{constants.DONATION_AMOUNT_50}&nbsp;{errors.PAY_NOW_SUFFIX}
                  </Link>
                  &nbsp;
                  <Link to="#" id="btn-pay_now_100" className="col-xl-3 col-lg-3 col-md-3 col-xs-6 col-sm-6 btn btn-green btn-donation" onClick={() => this.donationHandler(constants.DONATION_AMOUNT_100)}>
                    {errors.PAY_NOW_PREFIX}&nbsp;{constants.CURRENCY_SYMBOL}&nbsp;{constants.DONATION_AMOUNT_100}&nbsp;{errors.PAY_NOW_SUFFIX}
                  </Link>
                </div>

              ) : null}

            </div>
          </div>
        </div>

      </>
    );
  }
}
/**
 * @function mapStateToProps
 * @param {*} state 
 */
const mapStateToProps = (state) => ({
  stream_details: state.StreamingDeatails.stream_details,
  notification_content: state.streamingSubscribe.notification_content,
  block_user_data: state.Dashboard.block_user_data,
  allow_subscriber_permission: state.Dashboard.allow_subscriber_permission,
  deny_subscriber_permission: state.Dashboard.deny_subscriber_permission
});

log.trace("Chat.js,mapStateToProps:%s", mapStateToProps)
const connectedComponent= connect(mapStateToProps)(_chat);
export {connectedComponent as Chat};
