/**
* ErrorMessage Component
* @module ErrorMessage
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import React from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getLocalStorage, removeLocalStorage, setState } from "../store/helpers/common";
import {log} from '../store/helpers/logger';



//import {log} from '../store/helpers/logger';

export class _errorMessage extends React.Component {

    constructor(props) {
        //const func = "constructor(),ErrorMessage.js";
        super(props);
        this.state = {
            popup: true,
            message: ""
        };
    }
    exit() {
        const func = "exit(),ErrorMessage.js";
        removeLocalStorage("lastError", func);
        log.error("redirect to /,error:", func, this.state.message);
        //TODO ??window.location.href = "/";
    }


    componentDidMount() {
        const func = "componentDidMount(),errorMessage.js,";
        setState({
            popup: true,
            message: getLocalStorage("lastError", func)
        }, null, this, func);
    }

    render() {

        return <Modal isOpen={this.state.popup} >
            <ModalHeader >
                Error Message
            </ModalHeader>
            <ModalBody>
                <div className="lastError">{this.state.message}</div>
            </ModalBody>
            <ModalFooter>
                <button id="btn-exit" className="btn btn-info" onClick={() => this.exit()}>Exit</button>
            </ModalFooter>
        </Modal>
    }
}//ErrorMessage

export { _errorMessage as ErrorMessage };
