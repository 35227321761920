/**
* Dashboard Actions
* @module DashboardActions
* @version 1.2
* @copyright Telecom2 Ltd.
* 
*/

import { BaseUrl, apiCallAwait } from '../helpers/common';
import * as actionTypes from '../types/types';
import { ShowLoader, ShowAlert } from './staticActions'
import {log} from '../helpers/logger';
import constants from "../../constants";
import errors from '../../errors';
import { getId } from "../helpers/common";

/**
 * @function StreamCreate
 * @param {JSON} streamData {stream_name,performer_id,streaming_id,auth_token}
 * @returns {JSON} {response,message}
 * @example
 * import { StreamCreate } from "../dashboardActions"
 * ....
 * const streamData = {
 *       stream_name: uuidv4(),
 *       performer_id: getId("performer_id",func),
 *       streaming_id: getId("streaming_id",func),
 *       auth_token: getId("auth_token".func),
 *       }
 *
 * const result = await store.dispatch(StreamCreate(streamData));
 * ....
 * Return Success:
 * ---------------
 * result: {
 *     response: 'Success',
 *     message: {
 *       id: 2601,
 *       stream_name: 'dfe3879c-7e43-4246-90e1-3f6ca36a623b',
 *       performer_id: 7360,
 *       start_datetime: '2021-03-30T09:51:34.905Z',
 *       created: '2021-03-30T09:51:34.905Z',
 *       updated: '2021-03-30T09:51:34.905Z'
 *     }
 *   }
 * 
 * Return Error:
 * -------------
 * result: { response: 'Error', message: 'Create Stream failed' }
 *
 * 
 */
export function StreamCreate(streamData) {
    const func = "StreamCreate(),dashboardActions.js,";
    log.debug("%sstreamData:%s", func, streamData);
    return async dispatch => {
        dispatch(ShowLoader(true));
        const apiUrl = `${BaseUrl}${constants.A_CREATE_STREAM}`;

        log.trace("%sapiUrl:%s", func, apiUrl);
        let retVal = null;

        const createResult = await apiCallAwait(apiUrl, 'POST', streamData).catch((error) => {
            log.fatal("%serror:%s", func, error);
        })

        log.debug("%sResult of create stream:%s", func, createResult);

        if (!createResult) {
            dispatch(ShowLoader(false));
            dispatch({ type: actionTypes.STREAM_DETAILS, payload: {} })
            retVal = { response: constants.RESULT_ERROR, message: errors.CREATE_STREAM };
            log.fatal("%sretVal:%s", func, retVal);
            return retVal;
        }
        dispatch(ShowAlert({ Class: "alert-success", Message: createResult.message, Timeout: 5000 }));
        //dispatch({ type: actionTypes.STREAM_DETAILS, payload: createResult.data })
        dispatch({ type: actionTypes.STREAM_DETAILS, payload: createResult.message })
        retVal = createResult;
        log.debug("%sretVal:%s", func, retVal);
        return retVal;
    }//dispatch


}//StreamCreate


/**
 * @function StreamDelete
 * @param {JSON} streamData {stream_name,performer_id,streaming_id,auth_token}
 * @returns {JSON} {response,message}
 * @example
 * import { StreamCreate } from "../dashboardActions"
 * ....
 * const streamData = {
 *       stream_name: uuidv4(),
 *       performer_id: getId("performer_id",func),
 *       streaming_id: getId("streaming_id",func),
 *       auth_token: getId("auth_token",func),
 *       }
 *
 * const result = await store.dispatch(StreamDelete(streamData));
 * ....
 * Return Success:
 * ---------------
 * result: { response: 'Success', message: { count: 0 } }
 * 
 * Return Error:
 * -------------
 * result: { response: 'Error', message: 'Delete Stream failed' }
 *
 */

export function StreamDelete(streamData) {
    const func = "StreamDelete(),dashboardActions.js,";
    log.debug("%sstreamData:%s", func, streamData.caller);
    return async dispatch => {
        dispatch(ShowLoader(true));
        const apiUrl = `${BaseUrl}${constants.A_DELETE_STREAM}`;

        log.trace("%sapiUrl:%s", func, apiUrl);
        let retVal = null;

        const deleteResult = await apiCallAwait(apiUrl, 'POST', streamData).catch((error) => {
            log.fatal("%serror:%s", func, error);
        })

        if (!deleteResult) {
            retVal = { response: constants.RESULT_ERROR, message: errors.DELETE_STREAM };
            log.fatal("%sretVal:%s", func, retVal);
            return retVal;
        }
        dispatch(ShowAlert({ Class: "alert-success", Message: deleteResult.message, Timeout: 5000 }));
        dispatch({ type: actionTypes.STREAM_DETAILS, payload: {} });
        retVal = deleteResult;
        log.trace("%sretVal:%s", func, retVal);
        return retVal;
    }//dispatch

}//StreamDelete



/**
 * @function blockUser 
 * @param {*} data 
 */
export const blockUser = (data) => {
    const func = "blockUser(),dashboardActions.js,";
    log.debug("%sdata:%s", func, data);
    let retVal = { response: constants.RESULT_ERROR, message: null };

    if (!data) {
        return dispatch => {
            dispatch({ type: actionTypes.BLOCK_USER_DATA, payload: data })
        }

    }
    const postData =
    {
        auth_token: getId("auth_token", func),
        streaming_id: getId("streaming_id", func),
        stream_name: getId("stream_name", func),
        username: data,
        caller:func,

    }
    log.debug("%sBan a customer, parameters:%s", func, postData);
    return async dispatch => {
        dispatch(ShowLoader(true));

        const apiUrl = `${BaseUrl}${constants.A_PERFORMER_CUSTOMER_BAN_PUSH}`;
        log.trace("%sapiUrl:%s", func, apiUrl);
        const result = await apiCallAwait(apiUrl, 'POST', postData).catch((error) => {
            log.fatal("%serror:%s", func, error);
        })

        log.trace("%sresult:%s", func, result);
        if (!result) {
            dispatch(ShowLoader(false));
            retVal.message = "block user insert fail";
            log.fatal("%sretVal:%s", func, retVal);
            return retVal;
        }

        if (result.response === constants.RESULT_ERROR) {
            dispatch(ShowLoader(false));
            retVal.message = result.message;
            log.fatal("%sretVal:%s", func, retVal);
            return retVal;
        }
        retVal = result;
        log.debug("%sResult of customer ban:%s", func, retVal);
        dispatch({ type: actionTypes.BLOCK_USER_DATA, payload: data });
        return retVal;
    }//dispatch
}//blockUser


/**
 * @function unblockUser 
 * @param {*} data 
 */
export const unblockUser = (data) => {
    const func = "unblockUser(),dashboardActions.js,";
    log.trace("%sdata:%s", func, data);
    let retVal = { response: constants.RESULT_ERROR, message: null };

    const postData =
    {
        customer_id: data,
        auth_token: getId("auth_token", func),
        streaming_id: getId("streaming_id", func),
        caller:func,
    }
    log.trace("%sUnblock customer parameters:%s", func, postData);
    return async dispatch => {
        dispatch(ShowLoader(true));

        const apiUrl = `${BaseUrl}${constants.A_PERFORMER_CUSTOMER_BAN_DELETE}`;
        log.trace("%sapiUrl:%s", func, apiUrl);
        const result = await apiCallAwait(apiUrl, 'POST', postData).catch((error) => {
            log.fatal("%serror:%s", func, error);
        })

        log.trace("%sresult:%s", func, result);
        if (!result) {
            dispatch(ShowLoader(false));
            retVal.message = errors.CUSTOMER_BAN_DELETE_FAILED;
            log.fatal("%sretVal:%s", func, retVal);
            return retVal;
        }

        if (result.response === constants.RESULT_ERROR) {
            dispatch(ShowLoader(false));
            retVal.message = result.message;
            log.fatal("%sretVal:%s", func, retVal);
            return retVal;
        }
        retVal = result;
        log.debug("%sResult of unblock customer:%s", func, retVal);
        dispatch({ type: actionTypes.BLOCK_USER_DATA, payload: null });
        log.info("%sCustomer (id:%s) unblock successful by the performer.", func, data);
        return retVal;
    }//dispatch
}//unblockUser


/**
 * @function allowSubcriberPermission
 * @param {*} data 
 */
export const allowSubcriberPermission = (data) => {
    const func = "allowSubcriberPermission(),dashboardActions.js,";
    log.trace("%sdata:%s", func, data);
    return dispatch => {
        dispatch({ type: actionTypes.ALLOW_SUBSCRIBER_PERMISSION_DATA, payload: data })
    }
}//allowSubcriberPermission

/**
 * @function denySubscriberPermission
 * @param {*} data 
 */
export const denySubscriberPermission = (data) => {
    const func = "denySubscriberPermission(),dashboardActions.js,";
    log.trace("%sdata:%s", func, data);
    return dispatch => {
        dispatch({ type: actionTypes.DENY_SUBSCRIBER_PERMISSION_DATA, payload: data })
    }
}//denySubscriberPermissio



/**
 * @function incomeTipListing
 * @param {JSON} streamData {stream_name,streaming_id,auth_token}
 * @returns {JSON} {response,message}
 * @example
 * import { incomeTipListing } from "../dashboardActions"
 * ....
 * const data = {
 *       stream_name: uuidv4(),
 *       streaming_id: getId("streaming_id",func),
 *       auth_token: getId("auth_token",func),
 *       }
 *
 * const result = await store.dispatch(incomeTipListing(data));
 * ....
 * Return Success:
 * ---------------
 * {
 * "response":"Success",
 * "message":
 *  [
 *      {   
 *          "id":1060,
 *          "username":"testcustomer100",
 *          "amount":"25.00",
 *          "connected_time":"2021-04-09T11:51:40.000Z",
 *          "duration":"2021-04-09T11:51:40.000Z",
 *          "gross_pay":"0.00"
 *      }
 *  ]
 * }
 * 
 * Return Error:
 * -------------
 * result: { response: 'Error', message: 'Auth token is NULL' }
 *
 */

export function incomeTipListing(data) {
    const func = "incomeTipListing(),dashboardActions.js,";
    log.trace("%sdata:%s", func, data);

    return async dispatch => {
        dispatch(ShowLoader(true));
        const apiUrl = `${BaseUrl}${constants.A_INCOME_TIPS}`;

        log.trace("%sapiUrl:%s", func, apiUrl);

        const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
            //  log.fatal("%serror:%s", func, error);
        })
        if ((!result) || (result===undefined) || (result.response === constants.RESULT_ERROR)) {
            log.fatal("%sresult:%s", func, result);
            dispatch({ type: actionTypes.INCOME_TIP_LISTING, payload: [] })
            return;
        }

        if (result.message === errors.NO_CONNECTIONS_FOUND) {
            log.trace("%sresult:%s", func, result);
            dispatch({ type: actionTypes.INCOME_TIP_LISTING, payload: [] })
            return;
        }
        if (data.init===true){
            log.trace("%sresult init true:%s", func, result);
        }else
        {
            log.trace("%sresult:%s", func, result);
        }
        dispatch({ type: actionTypes.INCOME_TIP_LISTING, payload: result.message })
        //        return result;
    }//dispatch

}//incomeTipListing


/**
 *
 * @function logToMysql
 * @param {JSON} data {streaming_id,auth_token,message,stream_name,created}
 * @returns {JSON} {response,message}
 * @example
 * import { logToMysql } from "../dashboardActions"
 * ....
 * const data = {
 *  streaming_id: getId("streaming_id",func),
 *  auth_token: getId("auth_token",func),
 *  message: this.state.content,
 *  stream_name: getId('stream_name', func),
 *  created: Date.now(),
 * };
 * 
 * store.dispatch(logToMysql(data));
 * {
 *  "response":"Success",
 *  "message":
 *  {
 *      "id":964,
 *      "user_id":12564,
 *      "room_id":1,
 *      "message":"react test message",
 *      "created":"2021-04-12T20:58:23.264Z",
 *      "stream_session_id":7272
 *  }
 * }
 *
 * Return Error:
 * -------------
 * { response: 'Error', message: 'Auth token is NULL' }
 *  
 */
export function logToMysql(data) {
    const func = "logToMysql(),dashboardActions.js,";
    log.debug('%sInsert chat log to mysql table,data:%s', func, data);

    return async dispatch => {
        dispatch(ShowLoader(true));
        const apiUrl = `${BaseUrl}${constants.A_CHAT_LOG_INSERT}`;

        log.trace("%sapiUrl:%s", func, apiUrl);
        let retVal = null;

        const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
            log.fatal("%serror:%s", func, error);
            retVal = error;
        })

        if (!result) {
            log.fatal("%sretVal:%s", func, retVal);
            return retVal;
        }
        retVal = result;
        log.debug("%sResult of insert:%s", func, retVal);
        return retVal;
    }//dispatch


}//logToMysql
