/**
* @module AppRouter
* @version 1.2
* @copyright Telecom2 Ltd.
* 
*/

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Login } from '../components/Login';
import { FirstUrlRedirect } from './FirstUrlRedirect';
import { Dashboard } from '../components/Dashboard/Dashboard';
import { About } from '../components/About';
import { Red5Streaming } from '../components/Streaming/Red5Streaming';
import { SourceProvider } from '../components/Streaming/SourceProvider';
import { Authentication } from '../components/Authentication'
import { ErrorMessage } from '../components/ErrorMessage';
import { log } from '../store/helpers/logger';

/**
 * @param {*} props 
 */
export const AppRouter = (props) => {
    log.trace("AppRouter,Router.js,props%s", props);
    return (
        <Routes>
            <Route path="/" exact element={<Login />} />
            <Route path="/dashboard" exact element={<Dashboard />} />
            <Route path="/about" exact element={<About />} />
            <Route path="/error" exact element={<ErrorMessage />} />
            <Route path="/live-streaming/:performer/:username/:returnUrl" exact element={<Red5Streaming />} />
            <Route path="/source-provider" exact element={<SourceProvider />} />
            <Route path="/authentication" exact element={<Authentication />} />
            <Route path="/first-login" exact element={<FirstUrlRedirect />} />
        </Routes>
    )
}

