/**
* @module ListingArray
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import constants from "../../constants";
import { log } from '../../store/helpers/logger';


export class ListingArray {
    #newArray = [];
    #oldArray = [];
    returnArray = [];
    isNewRecordAvailable = false;
    constructor(oArray, nArray) {
        const func = "constructor(),ListingArray.js,"
        this.oldArray = oArray;
        this.newArray = nArray;
        log.trace('%sthis.oldArray:%s', func, this.oldArray);
        log.trace('%sthis.newArray:%s', func, this.newArray);
        this.main();
        log.trace('%sthis.returnArray:%s', func, this.returnArray);
        return this.returnArray;
    }

    async main() {
        await this.processor();
        await this.sort();
    }

    // getSortOrder(prop) {
    //     return function (a, b) {
    //         if (a[prop] < b[prop]) {
    //             return -1;
    //         } else if (a[prop] > b[prop]) {
    //             return 1;
    //         }
    //         return 0;
    //     }
    // }

    // async sort() {
    //     this.returnArray.sort(this.getSortOrder("status"));
    // }

    getSortOrder(created) {
        return function (a, b) {
            if (a[created] > b[created]) {
                return -1;
            }
            else if (a[created] < b[created]) {
                return 1;
            }
        }
    }//getSortOrder


    async sort() {
        this.returnArray.sort(this.getSortOrder("created", "status"));
    }//sort

    async processor() {
        const func = "processor(),ListingArray.js,"
        //if list feeding array has data copy them to returned array
        if (this.oldArray.length) {
            this.returnArray = this.oldArray.slice();
        }

        //if no new data return the list feeding array, values already copied at this
        //point
        if (!this.newArray.length) {
            log.trace('%s!this.newArray.length', func);
            return;
        }

        let i = 0;
        while (i < this.newArray.length) {
            let found = false;
            let j = 0;
            while (j < this.returnArray.length) {
                if ((this.returnArray[j].id === this.newArray[i].id) &&
                    (this.returnArray[j].stream_session_id === this.newArray[i].stream_session_id)) {
                    switch (this.newArray[i].status) {
                        case constants.CONNECTION_TIP_STATUS:
                            if (this.returnArray[j].tip_id === this.newArray[i].tip_id) {
                                this.returnArray[j] = this.newArray[i];
                                found = true;
                            }
                            break;
                        default:
                            if (this.returnArray[j].status === constants.CONNECTION_ACCEPTED) {
                                this.returnArray[j] = this.newArray[i];
                                found = true;
                            }
                            if (this.returnArray[j].status === this.newArray[i].status) {
                                this.returnArray[j] = this.newArray[i];
                                found = true;
                            }

                            break;
                    }

                    if (found) {
                        break;
                    }
                }//if ids matches
                j++;
            }//for j
            if (!found) {
                if (this.newArray[i].status === constants.CONNECTION_ACCEPTED) {
                    this.returnArray.unshift(this.newArray[i]);
                } else {
                    this.returnArray.push(this.newArray[i]);
                }
                log.trace('%spush:%s', func, !this.newArray[i]);
            }
            i++;
        }//for i
        return;
    }//main
}
