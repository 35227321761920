/**
* StreamingDetailsReducer module
* @module StreamingDetailsReducer
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import * as types from "./../types/types";
import {log} from '../../store/helpers/logger';

const initalState = {
    stream_details:{}
}

/**
 * @function StreamingDetailsReducer
 * @param {*} state 
 * @param {*} action 
 */
export const StreamingDetailsReducer = (state = initalState, action) => {
    const func = "StreamingDetailsReducer(),StreamingDetailsReducer.js,";
    log.trace("%sinitalState:%s", func, initalState);
    log.trace("%saction:%s", func, action);



    switch (action.type) {
        case types.STREAM_DETAILS:
            log.trace("%sSTREAM_DETAILS:%s", func, action);
            return {
                ...state,
                stream_details: action.payload
            }

        default:
            log.trace("%sdefault:%s", func, action);
            return state
    }
}

