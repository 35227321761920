/**
* StreamingsubscriberReducer module
* @module StreamingsubscriberReducer
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import * as types from "./../types/types";
import {log} from '../../store/helpers/logger';

const initalState = {
    stream_session_listing: [],
    room_listing: [],
    streaming_value: false,
    notification_content: {},
    block_subscriber: null,
    allow_subscriber_permission_event: null,
    deny_subscriber_permission_event: null,
    two_way_cam_request:null,
}

/**
 * @function StreamingsubscriberReducer
 * @param {*} state 
 * @param {*} action 
 */
export const StreamingsubscriberReducer = (state = initalState, action) => {
    const func = "StreamingsubscriberReducer(),StreamingsubscriberReducer.js,";
    log.trace("%sinitalState:%s", func, initalState);
    log.trace("%saction:%s", func, action);

    switch (action.type) {
        case types.STREAM_SESSION_LISTING:
            log.trace("%sSTREAM_SESSION_LISTING:%s", func, action);
            return {
                ...state,
                stream_session_listing: action.payload
            }
        case types.ROOM_LISTING:
            log.trace("%sROOM_LISTING:%s", func, action);
            return {
                ...state,
                room_listing: action.payload
            }
        case types.ROOM_INFO:
            log.trace("%sROOM_INFO:%s", func, action);
            return {
                ...state,
                room_info: action.payload
            }

        case types.STREAMING_SUBSCRIBE_AREA:
            log.trace("%sSTREAMING_SUBSCRIBE_AREA:%s", func, action);
            return {
                ...state,
                streaming_value: action.payload
            }
        case types.NOTIFICATION_CONTENT:
            log.trace("%sNOTIFICATION_CONTENT:%s", func, action);
            return {
                ...state,
                notification_content: action.payload
            }
        case types.BLOCK_SUBSCRIBER:
            log.trace("%sBLOCK_SUBSCRIBER:%s", func, action);
            return {
                ...state,
                block_subscriber: action.payload
            }
        case types.ALLOW_SUBSCRIBER_PERMISSION_EVENT:
            log.trace("%sALLOW_SUBSCRIBER_PERMISSION_EVENT:%s", func, action);
            return {
                ...state,
                allow_subscriber_permission_event: action.payload
            }
        case types.DENY_SUBSCRIBER_PERMISSION_EVENT:
            log.trace("%sDENY_SUBSCRIBER_PERMISSION_EVENT:%s", func, action);
            return {
                ...state,
                deny_subscriber_permission_event: action.payload
            }
            case types.TWO_WAY_CAM_REQUEST:
                log.trace("%sTWO_WAY_CAM_REQUEST:%s", func, action);
                return {
                    ...state,
                    two_way_cam_request: action.payload
                }
    
        default:
            log.trace("%sdefault:%s", func, action);
            return state
    }
}//StreamingsubscriberReducer

