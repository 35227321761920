/**
* MysqlHandler
* @module MysqlHandler
* @version 1.0
* @copyright Telecom2 Ltd.
*/

import {log} from '../store/helpers/logger';
import constants from "../constants";
import { apiCallAwait, getId, BaseUrl } from '../store/helpers/common';


/**
 * MysqlHandler handling class
 * newer code parts use it, olders are not yet
 * 
 */
export class MysqlHandler {

    refreshTimer = null;

    /**
    * Add to a list of data in the database. Every time you push a new node onto a list, 
    * your database generates a unique key, like messages/users/<unique-user-id>/<username>
    * 
    * @param  {JSON} data
    */
    async push(data) {
        const func = "push(" + data.caller + "),MysqlHandler.js,";
        data.caller = func;
        log.trace("%sdata:%s", func, data);

        const apiUrl = `${BaseUrl}${constants.A_CHAT_PUSH}`;
        log.trace("%sapiUrl:%s", func, apiUrl);
        const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
            log.fatal("%serror:%s", func, error);
            throw error;
        })
        log.trace("%sresult:%s", func, result);
        return result;
    }//push

    /**
     * @param  {} data
     */
    async pull(data) {
        const { id, direction, stream_name, caller } = data;
        const func = "pullChat(" + caller + "),MysqlHandler.js,";
        log.trace("%sdata:%s", func, data);

        const pullData =
        {
            auth_token: getId("auth_token", func),
            streaming_id: getId("streaming_id", func),
            stream_name: stream_name,
            id: id,
            direction: direction,
            caller: caller,
        }
        log.trace("%spullData:%s", func, pullData);
        const apiUrl = `${BaseUrl}${constants.A_CHAT_PULL}`;
        const result = await apiCallAwait(apiUrl, 'POST', pullData).catch((error) => {
            log.fatal("%serror:%s", func, error);
            throw error;
        })
        log.trace("%sresult:%s", func, result);
        if (result.response === constants.RESULT_ERROR) {
            log.fatal("%sresult.message:%s", func, result.message);
            return result;
        }
        //if not found any record message is null
        if (result.message) {
            const retVal = this.snapshotToArray(result.message);
            log.trace("%sretVal:%s", func, retVal);
            return retVal;
        }
        return null;
    }//pull

    /**
     * @param  {} data
     */
    async update(data) {
        const func = "update(" + data.caller + "),MysqlHandler.js,";
        //put this func to calling chain
        data.func = func;
        log.trace("%sdata:%s", func, data);
        const apiUrl = `${BaseUrl}${constants.A_CHAT_UPDATE}`;
        const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
            log.fatal("%serror:%s", func, error);
            throw error;
        })
        log.trace("%sresult:%s", func, result);
        return result;
    }//update




    // signedIn(caller, cb) {
    //     const func = "signedIn(" + caller + "),MysqlHandler.js,";
    //     const retVal = getId('streaming_id', func);
    //     log.trace('%sretVal:%s', func, retVal);
    //     return cb(null, retVal);
    // }//signedIn

    /**
    * @param  {} snapshot
    */
    snapshotToArray(snapshot) {
        const func = "snapshotToArray(),MysqlHandler.js,";
        let returnArr = [];
        log.trace("%snapshot:%s", func, snapshot);
        if (!snapshot) {
            return returnArr;
        }
        snapshot.forEach(function (childSnapshot) {
            let item = {};
            item.value = childSnapshot;
            item.key = childSnapshot.uid;
            returnArr.push(item);
        });
        return returnArr;
    }; //snapshotToArray

}//MysqlHandler

export const myHandler = new MysqlHandler();
