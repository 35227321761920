/**
 * 
 * @module PerformerPopup
 * @version 1.2
 * @copyright Telecom2 Ltd.
 * @see https://github.com/riversun/JSFrame.js/
 */

 import React, { Component } from "react";
 import ReactDOM from 'react-dom';
 import { JSFrame } from 'jsframe.js';
 import {
     BaseUrl,
     apiCallAwait,
     red5ErrorLevel,
     getShutDown,
     getId,
     setId,
     removeId,
     setState,
     getUserConfig
 } from "../../store/helpers/common";
 import config from "../../config.json";
 import { log } from '../../store/helpers/logger';
 import { DisplayError } from "../../store/actions/userActions";
 import constants from "../../constants";
 import errors from "../../errors";
 import { Scheduler } from '../../store/helpers/scheduler';
 import { RTCSubscriber, PlaybackView, setLogLevel } from 'red5pro-webrtc-sdk'
 
 /**
  * PerformerPopup component
  * @property {bool} streaming streaming in progress
  * @property {string} stream_name name of stream
  * @property {bool} waitingbuttonstatus status of waiting button
  * @property {object} frame floating window JSFrame object
  */
 export class PerformerPopup extends Component {
     constructor(props) {
         const func = "constructor(),PerformerPopup.jsx,";
         log.trace("%sprops:%s", func, props);
         super(props);
         const userConfig = getUserConfig(func);
         this.state = {
             streaming: false,
             stream_name: props.stream_name,
             waitingbuttonstatus: true,
             frame: props.frame,
             connectionParams: {
                 username: "",
                 password: "",
                 token: ""
             },
             video: {
                 width: userConfig.video.width,
                 height: userConfig.video.height,
             }
         };
         setId(constants.C2C_STREAM_NAME, this.state.stream_name, func);
         this.connTimer = 0;
         this.subscriber = undefined;
         this.viewer = undefined;
         log.trace("%sthis.state:%s", func, this.state);
     }
 
 
     /**
      * Looking for opened connection by customer and if available,
      * show the stream of customer in a floating window
      */
     componentDidMount() {
         const func = "componentDidMount(),PerformerPopup.jsx,";
         setState({
             connectionParams: {
                 username: getId("performerFullName", func),
                 password: "c2c",
                 token: getId("red5_token", func)
             }
         }, null, this, func);
         log.trace("%sthis.state:%s", func, this.state);
 
         if (this.connTimer === 0) {
             const schedulerData = {
                 type: constants.SCHEDULER_PERFORMER_POPUP,
                 refreshRate: 1000,
             }
             const scheduler = new Scheduler(schedulerData, this.refreshConnection);
             this.connTimer = scheduler.getTimer();
             log.debug('%sScheduler started:%s', func, scheduler);
         }
 
     } //componentDidMount
 
     refreshConnection = async (data) => {
         const func = "refreshConnection(" + data.caller + "),PerformerPopup.jsx,";
         const result = await this.getConnection();
         log.trace("%sresult:%s,%s", func, result);
         switch (result) {
             case constants.C2C_OPEN:
                 if (!this.c2cConnected) {
                     this.setState({ streaming: true }, async () => {
                         await this.StartStreaming(func);
 
                     });
                 }
                 break;
             case constants.C2C_CANCEL:
                 await this.handleError(errors.C2C_CUSTOMER_REJECT);
                 //TODO C2C update 
                 const data = {
                     stream_name: this.state.stream_name,
                     streaming_id: getId("streaming_id", func),
                     auth_token: getId("auth_token", func),
                     status: constants.C2C_CLOSED,
                     caller: func,
                 }
 
                 log.trace("%sdata:%s", func, data);
                 const apiUrl = `${BaseUrl}${constants.A_C2C_UPDATE_STREAM}`;
                 log.trace("%sapiUrl:%s", func, apiUrl);
                 const updateResult = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
                     log.error("%serror:%s", func, error);
                 })
 
                 log.trace("%supdateResult:%s", func, updateResult);
                 break;
             default:
                 break;
         }
     }//refreshConnection
 
 
 
     /**
      * Looking for connections in c2c_session table
      * start streaming when open connection is available
      * or close if connection status is canceled
      * @async
      * @return {bool} false | true 
      */
     async getConnection() {
         const func = "getConnection(),PerformerPopup.jsx,";
         let retVal = false;
         const apiUrl = `${BaseUrl}${constants.A_C2C_GET_CONNECTION}`;
         log.trace("%sapiUrl:%s", func, apiUrl);
         const data = {
             stream_name: this.state.stream_name,
             streaming_id: getId("streaming_id", func),
             auth_token: getId("auth_token", func),
             caller: func,
         }
         log.trace("%sdata:%s", func, data);
 
         const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
             log.error("%serror:%s", func, error);
         })
 
         log.trace("%sresult:%s", func, result);
         if (result) {
             if ((result.response === constants.RESULT_SUCCESS) && (result.message !== null)) {
                 if (result.message.stream_name === this.state.stream_name) {
                     retVal = result.message.status;
                 }
             }
         }
         log.trace("%sretVal:%s", func, retVal);
         return retVal;
     }//getConnection
 
     /**
      * Handle errors
      * @param  {string} message error message
      * @param  {string} caller name of caller function 
      */
     async handleError(message, caller) {
         const func = "handleError(),PerformerPopup.jsx,";
         setState({ streaming: false }, null, this, func);
         //!!!this line caused freezing!!
         //!!!await this.unsubscribeStreaming();
         //pass the parent frame for closing  this.state.frame
         DisplayError(message, caller, this.state.frame);
     } //handleError
 
 
     /**
      * Handle subscriber events 
      * @param {event} event subscriber event
      */
     handleSubscriberEvent = async (event) => {
         const func = "handleSubscriberEvent(),PerformerPopup.jsx,";
         if (getShutDown(func)) {
             return;
         }
         log.trace("%sevent.type:%s", func, event.type);
         if (event.type !== "Subscribe.Time.Update") {
             log.trace("%sevent.type:%s", func, event.type);
         }
 
         if (event.type === "Connect.Success") {
             log.info("%s%s", func, event.type);
         } else if (event.type === "Subscribe.Time.Update") {
             log.trace("%sevent.type:%s", func, event.type);
         } else if (event.type === "Subscribe.Play.Unpublish") {
             await this.handleError(errors.C2C_CUSTOMER_CLOSE_CONNECTION, func);
         } else if (event.type === "Subscribe.Connection.Closed") {
             await this.handleError(errors.C2C_CUSTOMER_LOST, func);
         } else if (event.type === "Connect.Failure") {
             await this.handleError(errors.C2C_PERFORMER_CONNECTION_FAILURE, func);
         } else if (event.type === "Subscribe.Stop") {
             await this.handleError(errors.C2C_PERFORMER_CLOSE_CONNECTION, func);
         }
     }; //handleSubscriberEvent
 
     /**
      * Configure red5pro here
      */
     AttachStream = () => {
         const func = "AttachStream(),PerformerPopup.jsx,";
         if (getShutDown(func)) {
             return;
         }
         const { stream_name } = this.state;
         log.trace("%sstream_name:%s", func, stream_name);
         this.subscriber = new RTCSubscriber();
         //set red5 logger
         setLogLevel(red5ErrorLevel());
         this.viewer = new PlaybackView(constants.PERFORMER_POPUP_VIDEO);
         this.viewer.attachSubscriber(this.subscriber);
 
         // Assign new subscription id in off chance server rejects on subscriber already assigned.
         // Subscribers will be cleaned up, but if we try to immediately re-subscribe, we may get rejected.
         const instanceId = Math.floor(Math.random() * 0x10000).toString(16);
 
         const initData = {
             protocol: config.red5.protocol, //"wss",
             host: config.red5.host, //"dev-red5-c7.t2.tl",
             port: config.red5.port, //443,
             app: config.red5.app, //"live",
             streamName: stream_name,
             subscriptionId: 'subscriber-' + instanceId,
             rtcConfiguration: {
                 iceServers: [config.red5.iceServers],
                 //iceServers: [{ urls: "stun:stun2.l.google.com:19302" }],
             }, connectionParams: {
                username: getId("performerFullName", func),
                password: "c2c",
                token: getId("red5_token", func)
            }
         };
         log.debug("%sSubsribe a 2waycams stream,initData:%s", func, initData);
         this.subscriber.init(initData).catch((error) => {
             const errMessage = "init stream error";
             log.error("%s%s:%s", func, errMessage, error);
             this.subscriber = undefined;
             this.viewer = undefined;
 
         });
         log.info("%sPerformer attached to Customer's 2waycams %s stream.", func, stream_name);
     }; //AttachStream
 
     /**
      * Subscribe streaming
      */
     subscribeStreaming = async () => {
         const func = "subscribeStreaming(),PerformerPopup.jsx,";
         if (getShutDown(func)) {
             return;
         }
 
         await this.AttachStream();
         if (this.subscriber) {
             await this.subscriber.subscribe().catch(async (error) => {
                 const errMessage = "subscribe(catch):" + error.message;
                 log.error("%serrMessage:%s", func, errMessage);
                 await this.handleError(errMessage, func);
             });
             this.subscriber.on("*", this.handleSubscriberEvent);
             setState({ waitingbuttonstatus: false }, null, this, func);
             this.c2cConnected = true;
             log.info("%sPerformer is subscribed to customer's stream..", func);
         } else {
             const errMessage = "this.subscriber is empty,not works";
             log.error("%s:%s", func, errMessage);
             await this.handleError(errMessage, func);
         }
     }; //subscribeStreaming
 
     /**
      * Start streaming
      * @async
      */
     StartStreaming = async (caller = "") => {
         const func = "StartStreaming(),PerformerPopup.jsx,";
         log.trace("%scaller:%s", func, caller);
         if (getShutDown(func)) {
             return;
         }
         await this.subscribeStreaming();
     }; //StartStreaming
 
     /**
      * unsubscribe streaming
      */
     unsubscribeStreaming = async () => {
         const func = "unsubscribeStreaming(),PerformerPopup.jsx,";
         if ((this.subscriber !== undefined) && (this.subscriber !== null)) {
             await this.subscriber.unsubscribe().catch(async (error) => {
                 const errMessage = "subscribe(catch):" + error.message;
                 log.error("%serrMessage:%s", func, errMessage);
                 await this.handleError(errMessage, func);
             });
             this.subscriber.off('*', this.handleSubscriberEvent);
         }
 
         setState(
             {
                 streaming: false,
             }, null, this, func
         );
         this.c2cConnected = false;
 
         const stream_name = getId(constants.C2C_STREAM_NAME, func);
         log.trace("%sstream_name:%s", func, stream_name);
         if (!stream_name) return;
         const data = {
             stream_name: stream_name,
             caller: func,
             streaming_id: getId("streaming_id", func),
             auth_token: getId("auth_token", func),
         }
         removeId(constants.C2C_STREAM_NAME, func, true);
         log.debug("%sDelete stream,data:%s", func, data);
 
         const apiUrl = `${BaseUrl}${constants.A_C2C_DELETE_STREAM}`;
         log.trace("%sapiUrl:%s", func, apiUrl);
         const deleteResult = await apiCallAwait(apiUrl, 'POST', data).catch(async (error) => {
             const errMessage = "c2c_delete_stream (catch):" + error.message;
             log.error("%serrMessage:%s", func, errMessage);
             await this.handleError(errMessage, func);
         })
         log.debug("%sResult of delete stream:%s", func, deleteResult);
         if (deleteResult) {
 
             if (deleteResult.response === constants.RESULT_ERROR) {
                 const errMessage = "delete_stream error:" + deleteResult.message;
                 log.error("%serrMessage:%s", func, deleteResult.message);
                 await this.handleError(errMessage, func);
             }
         }
     }; //unsubscribeStreaming
 
     /**
      * Stop streaming
      * @param {string} message reason of stop 
      * @param {string} caller function name of caller
      * @async
      */
     StopStreaming = async (message, caller) => {
         const func = "StopStreaming(" + caller + "),PerformerPopup.jsx,";
         log.info("%sStop streaming executed,message:%s", func, message);
         setState({
             waitingbuttonstatus: false,
         }, null, this, func);
         if (this.connTimer) clearInterval(this.connTimer);
         this.connTimer = 0;
         await this.handleError(message, func);
     }; //StopStreaming
 
     async componentWillUnmount()
     {
         const func = "componentWillUnmount(),PerformerPopup.jsx,";
         log.debug("%sexecuted..", func);
             await this.StopStreaming(errors.STREAMING_IS_STOPPED_BY_USER,func).catch(async(error)=>{
             const errMessage = "stop streaming catch(error):" + error.message;
             log.error("%serrMessage:%s", func, errMessage);
             await this.handleError(errMessage, func);
         });
         await this.unsubscribeStreaming();
     }
     /**
      * render html
      */
     render() {
         const func = "render(),PerformerPopup.jsx,";
         log.trace("%sthis.state:%s", func, this.state);
 
         return (
             <>
 
                 <div className="card preview-section preview-section-iframe">
                     <div className="card-body preview-body performerC2C">
                         <div className="row">
                             <div className="col-md-6">
                                 <div className="camera-section">
                                     <video id="performer-popup-video" width='250' height='250' controls={false} autoPlay={true} disablePictureInPicture>
                                     </video>
                                 </div>
 
                             </div>
                         </div>
                     </div>
                 </div>
             </>
         );
     }
 }
 
 /**
  performerPopup launch perfomer's view window
 */
 export function performerPopup(stream_name, user_name) {
     const func = "performerPopup(),PerformerPopup.jsx,";
     log.trace("%sstream_name:%s", func, stream_name);
     log.trace("%suser_name:%s", func, user_name);
     //Create window
 
     const jsFrame = new JSFrame(
         {
             horizontalAlign: 'right',//You can specify 'left' and 'right' for horizontalAlign.
             verticalAlign: 'top',//You can also specify 'top' and 'bottom' for the verticalAlign
         });
 
     const width = 250;
     const height = 250;
     const margin = 30;
 
     const frame = jsFrame.create({
         name: `performerPopup`,
         title: 'Camera of ' + user_name,
         // Note:
         // If you specify anchor as right or bottom,
         // the window position will still be specified by (left,top)
         left: -width - margin,
         top: margin,
         width, height,
         movable: true,//Enable to be moved  by mouse
         resizable: false,//Enable to be resized by mouse
         html: '<div id="floating_container"></div>'
     });
 
 
 
     ReactDOM.render(<PerformerPopup stream_name={stream_name} frame={frame} />, document.getElementById('floating_container'));
 
     async function closeStream() {
         const func = "closeStream(),PerformerPopup.jsx,";
         //when customer not approve the connection, connection is already closed
         //to avoid useless error message, check here first we have living connection
         //or not
         const stream_name = getId(constants.C2C_STREAM_NAME, func);
         log.trace("%sstream_name:%s", func, stream_name);
         if (!stream_name) {
             const errMessage = "Stream name not retrieved"
             log.error("%serrMessage:%s", func, errMessage);
             DisplayError(errMessage, func);
             return;
         }
         const data = {
             stream_name: stream_name,
             caller: func,
             streaming_id: getId("streaming_id", func),
             auth_token: getId("auth_token", func),
         }
         removeId(constants.C2C_STREAM_NAME, func, true);
         log.trace("%sdata:%s", func, data);
 
         const apiUrl = `${BaseUrl}${constants.A_C2C_DELETE_STREAM}`;
         log.trace("%sapiUrl:%s", func, apiUrl);
         const deleteResult = await apiCallAwait(apiUrl, 'POST', data).catch(async(error) => {
             const errMessage = "c2c_delete_stream (catch):" + error.message;
             log.error("%serrMessage:%s", func, errMessage);
             DisplayError(errMessage, func);
         })
         log.trace("%sdeleteResult:%s", func, deleteResult);
         if (deleteResult) {
             if (deleteResult.response === constants.RESULT_ERROR) {
                 const errMessage = "c2c_delete_stream error:" + deleteResult.message;
                 log.error("%serrMessage:%s", func, errMessage);
                 DisplayError(errMessage, func);
             }
         }
     };
 
     frame.on('closeButton', 'click', async (_frame, evt) => {
         //without this delay applicaton is freezing
         setTimeout(async () => {
             await closeStream();
           }, 3000);
         frame.closeFrame();
     });
     //Show window
     frame.show();
 }
 
 