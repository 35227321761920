/**
* User Actions
* @module UserActions
* @version 1.2
* @copyright Telecom2 Ltd.
* 
*/

import {
  BaseUrl, getShutDown, setShutDown,
  setLocalStorage,
  getId, setId, setPingReady, displayPopup, removeId
} from '../helpers/common';
import Swal from 'sweetalert2';
import { ShowLoader } from './staticActions'
import { log } from '../helpers/logger';
import { apiCallAwait } from '../helpers/common';
import constants from '../../constants';
import config from '../../config.json';
import errors from '../../errors';
//not finished yet
//import {ErrorMessage} from '../../components/ErrorMessage';

/**
*
* @function UserLogin
* @param {JSON} data {"instance_id":1,"username":"testuser","password":"testuser"}
* @return {JSON} 
* @example
* import { UserLogin } from "../store/actions/userActions";
* ....
* const response = await this.props.dispatch(UserLogin(this.state));
* ....
* Return Success:
* {
* "response":"Success",
* "message":"Login was successfull!",
* "auth_token":"c2cbb14dd252ccc99807ed5cd1681fb35c624fe4db5088f91678bb111291ca33284318843369496",
* "streaming_id":"36648cde-6aed-11eb-abe9-9dcf1dc0e13a",
* "email":"testperformer200@telecom2.net",
* "performer_id":7360,
* "username":"testperformer200"
* }
*  
* Return error:
* { response: 'Error', message: 'Login Api call failure' }
*/
export function UserLogin(data) {
  const func = "UserLogin(),userActions.js,";
  log.trace("%sdata:%s", func, data);
  let errorMessage = null;
  return async dispatch => {
    dispatch(ShowLoader(true));

    const apiUrl = `${BaseUrl}${constants.A_LOGIN}`;
    log.trace("%sapiUrl:%s", func, apiUrl);
    let retVal = null;
    const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
      errorMessage = error.message;
      log.error("%serror:%s", func, error);
    })

    log.trace("%sresult:%s", func, result);
    if (!result) {
      dispatch(ShowLoader(false));
      retVal = { response: constants.RESULT_ERROR, message: errorMessage };
      displayPopup(errorMessage, func);
      return retVal;
    }

    if (result.response === constants.RESULT_ERROR) {
      dispatch(ShowLoader(false));
      retVal = { response: constants.RESULT_ERROR, message: result.message };
      displayPopup(result.message, func);
      return retVal;
    }
    retVal = result;
    log.trace("%sretVal:%s", func, retVal);
    return retVal;
  }//dispatch
}//UserLogin


/**
* User logout
* @function UserLogout
* @example
* import { UserLogout } from "../store/actions/userActions";
* ....
* Userlogout();
*/
export function UserLogout() {
  const func = "UserLogout(),userActions.js,";
  RedirectToLogin(errors.LOGOUT, func);
}//UserLogout


//TKOK
/**
* Authenticate user
* @function AuthUser
* @param {JSON} data username,stream_name,auth_token,streaming_id
* @return {JSON} 
* @example
* import { UserLogin } from "../store/actions/userActions";
* ....
* const data={  "username":"testcustomer100",
*               "stream_name":"c90e7cfd-daca-4533-a82d-30d78ae34e10",
*               "auth_token":"368956490c932c65e417a01561286b915c624fe4db5088f91678bb111291ca3326149613293616",
*               "streaming_id":"7d90dbae-62ea-11eb-bc34-39145808f34b"
*            }
* const response = await this.props.dispatch(AuthUser(data));
* ....
* Return Success:
* {
*     "response":"Success",
*     "message":{
*                 "username":"testcustomer100",
*                 "stream_name":"c90e7cfd-daca-4533-a82d-30d78ae34e10",
*                 "auth_token":"368956490c932c65e417a01561286b915c624fe4db5088f91678bb111291ca3326149613293616",
*                 "streaming_id":"7d90dbae-62ea-11eb-bc34-39145808f34b"
*               }
* }
*  
* Return error:
* { response: 'Error', message: 'Login Api call failure' }
*/

export function AuthUser(data) {
  const func = "AuthUser(),userActions.js,";
  log.trace("%sauthdata:%s", func, data);
  data.room_id = null;
  let retVal = null;
  return async dispatch => {
    dispatch(ShowLoader(true));

    const apiUrl = BaseUrl + constants.A_AUTH_USER;
    log.trace("%sapiUrl:%s", func, apiUrl);
    const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
      log.error("%serror:%s", func, error);
    })

    log.trace("%sresult:%s", func, result);
    if (!result) {
      dispatch(ShowLoader(false));
      retVal = { response: constants.RESULT_ERROR, message: errors.AUTH_USER_API };
      //displayPopup(errors.CUSTOMERINFO_API, func);
      log.error("%sretVal:%s", func, retVal);
      return retVal;
    }

    if (result.response === constants.RESULT_ERROR) {
      dispatch(ShowLoader(false));
      retVal = { response: constants.RESULT_ERROR, message: result.message };
      //displayPopup(result.message, func);
      log.error("%sretVal:%s", func, retVal);
      return retVal;
    }
    retVal = result;
    log.trace("%sretVal:%s", func, retVal);
    return retVal;
  }


}// AuthUser


//TKOK
/**
* Authenticate customer
* @function CustomerAuth
* @param {JSON}  data   username performer
* @return {JSON} result see example
* @example
* import { UserLogin } from "../store/actions/userActions";
* ....
* const data= {
*         "username":"testcustomer100",
*         "performer":"36648cde-6aed-11eb-abe9-9dcf1dc0e13a",
*            }
* const response = await this.props.dispatch(CustomerAuth(data));
* ....
* Return Success:
* ---------------
* {
*  "response":"Success",
*  "message":
*     {
*       "username":"testcustomer100",
*       "stream_name":"ed6383c4-38e9-41f0-9aac-a4e2cf93ffca",
*       "auth_token":"368956490c932c65e417a01561286b915c624fe4db5088f91678bb111291ca3326149747568856",
*       "streaming_id":"7d90dbae-62ea-11eb-bc34-39145808f34b"
*      }
*  
* Return error:
* ----------------
* { response: 'Error', message: 'Customerinfo Api call failure' }
*/

export function CustomerAuth(data, fireBaseLogin = true) {
  const func = "CustomerAuth(),userActions.js,";

  log.trace("%sdata:%s", func, data);
  let retVal = null;
  let apiUrl = null;
  let result = null;

  return async dispatch => {
    dispatch(ShowLoader(true));
    apiUrl = BaseUrl + constants.A_CUSTOMER_INFO;
    log.trace("%sapiUrl:%s", func, apiUrl);
    result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
      log.error("%serror:%s", func, error);
      retVal = error;
    })

    log.trace("%sresult of customer info:%s", func, result);
    if (!result) {
      dispatch(ShowLoader(false));
      log.error("%sretVal-1:%s", func, retVal);
      return retVal;
    }

    if (result.response === constants.RESULT_ERROR) {
      dispatch(ShowLoader(false));
      retVal = { response: constants.RESULT_ERROR, message: result.message };
      //displayPopup(result.message, func);
      log.error("%sretVal-2:%s", func, retVal);
      return retVal;
    }

    const customerData = result.message;

    /**
    * Auth user thru auth-user api call
    */
    const authCallData =
    {
      username: customerData.username,
      stream_name: customerData.stream_name,
      auth_token: customerData.auth_token,
      red5_token: customerData.red5_token,
      streaming_id: customerData.streaming_id,
      room_id: customerData.room_id,
      room_name: customerData.room_name,
      moderator: customerData.moderator,
      caller: func,
    };
    log.trace("%sauthCallData:%s", func, authCallData);
    apiUrl = BaseUrl + constants.A_AUTH_USER;
    log.trace("%sapiUrl:%s", func, apiUrl);
    result = await apiCallAwait(apiUrl, 'POST', authCallData).catch((error) => {
      log.error("%serror:%s", func, error);
      retVal = error;
    })
    log.trace("%sresult of auth user:%s", func, result);
    if (!result) {
      dispatch(ShowLoader(false));
      log.error("%sretVal-6:%s", func, retVal);
      return retVal;
    }


    if (result.response === constants.RESULT_ERROR) {
      dispatch(ShowLoader(false));
      retVal = { response: constants.RESULT_ERROR, message: result.message };
      log.error("%sretVal-7:%s", func, retVal);
      return retVal;
    }
    retVal = result;
    retVal['message']['performer_username'] = customerData.performer_username;
    retVal['message']['performer_instance_id'] = customerData.performer_instance_id;
    log.trace("%sretVal-8:%s", func, retVal);
    return retVal;

  }//dispatch
}//CustomerAuth

/**
 * removeAllIds
 */
export async function removeAllIds(caller) {
  //performer identification
  //performer_id: getId("performer_id", func, true),

  //customer identification
  //username: getId("username", func, true),

  let callerType = null;
  if (getId("username", "removeAllIds(" + caller + "),userActions.js,", true)) {
    callerType = constants.CUSTOMER;
  } else if (getId("performer_id", "removeAllIds(" + caller + "),userActions.js,", true)) {
    callerType = constants.PERFORMER;
  }

  const func = "removeAllIds(" + caller + "(" + callerType + ")),userActions.js,";

  switch (callerType) {
    case constants.PERFORMER:
      break;
    case constants.CUSTOMER:
      break;
    default:
      log.trace("Customer or performer not detected!", func);
      break;
  }
  //remove all ids here that is not sensetive for removal either site at init
  removeId('auth_token', func, true);
  removeId('data', func, true);
  removeId('performer', func, true);
  removeId('performer_id', func, true);
  removeId('roomId', func, true);
  removeId('stream_name', func, true);
  removeId('streaming', func, true);
  removeId('streaming_id', func, true);
  removeId('username', func, true);
  removeId('returnUrl', func, true);
  removeId(constants.C2C_STREAM_NAME, func, true);
  removeId('performerName', func, true);
  removeId('performerFullName', func, true);
  removeId('performer_username', func, true);
  removeId('connection_id', func, true);
  removeId('loggerUser', func, true);
  removeId('loggerUuid', func, true);
  log.debug("%sAll IDs stored in local storage are removed.", func);
}//removeAllIds



/**
* CustomerExit
* @function CustomerExit
*/
export async function CustomerExit(exitResult, caller, test) {
  const func = "CustomerExit(" + caller + "),userActions.js,";
  log.trace("%sexitResult:%s", func, exitResult);

}//CustomerExit


/**
* PerformerExit
* @function PerformerExit
*/
export async function PerformerExit(exitResult, caller, test) {
  const func = "PerformerExit(" + caller + "),userActions.js,";
  log.trace("%sexitResult:%s", func, exitResult);

}//PerformerExit





/**
* Clean exit
* @function CleanExit
*/
export async function CleanExit(params) {
  const func = "CleanExit(" + params.caller + "),userActions.js,";
  log.debug("%sstart execution..", func);

  if (getShutDown()) {
    log.trace("%sshutDown already executed..", func);

    return;
  }

  setShutDown(true, func);
  setPingReady(false, func);

  const data = {
    //performer identification cookies
    stream_name: getId('stream_name', func, true),
    performer_id: getId("performer_id", func, true),
    //customer identification cookies
    username: getId("username", func, true),
    performer_username: getId("performer_username", func, true),
    performer: getId("performer", func, true),
    //for token validation
    auth_token: getId("auth_token", func, true),
    streaming_id: getId("streaming_id", func, true),
    status: params.status,
    caller: params.caller,
  }
  log.debug("%sParameters to clean exit call:%s", func, data);

  if (data.username) {
    setId("username", data.username, func);
  }
  if ((!data.stream_name) && (!data.username)) {
    log.trace("%sno username or stream_name, return:%s", func, data);
    return null;
  }

  let result = null;
  if ((data.performer_id || data.username) && (data.auth_token && data.streaming_id)) {
    const apiUrl = `${BaseUrl}${constants.A_CLEAN_EXIT}`;
    log.trace("%sapiUrl:%s", func, apiUrl);
    result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
      log.error("%serror:%s", func, error);
      result = error.message;
    })
    log.info("%sClean exit executed,result:%s", func, result);
    //setShutDown(true, func);
    return result;
  }
}//CleanExit



/**
* Redirect to login
* @function RedirectToLogin
*/
export async function RedirectToLogin(errMessage, caller, error = true) {
  const func = "RedirectToLogin(" + caller + "),userActions.js,";
  //shutdown already in progress don't need to execute this function 
  //over and over again
  log.debug("%sError message:%s", func, errMessage);
  if (getShutDown(func)) {
    return;
  }
  //filter out here messages those are not errors
  switch (errMessage) {
    case errors.LOGOUT:
      log.info("%serrMessage:%s", func, errMessage);
      break;
    default:
      if (error) log.error("%serrMessage:%s", func, errMessage);
      else log.debug("%serrMessage:%s", func, errMessage);
      setLocalStorage("lastError", errMessage, func);
      //shouldn't wait to clean exit,
      //that is executing after user press okay if we have errMessage
      log.debug("%sset shutdown:%s", func, errMessage);
      setShutDown(true, func);
      setPingReady(false, func);
      break;
  }


  switch (errMessage) {
    //to be sure even if programers made mistake calling without errMessage,
    //cleanExit must be executed
    case "":
    case null:
    case undefined:
      await CleanExit(func);
      removeAllIds(func + "_1");
      break;
    case errors.LOGOUT:
      //disable leave confirmation on custom dialog, when user press okay
      window.onbeforeunload = null;
      //need to apply id on the fly, pupeeter didn't work well on classes
      const reactSwal = Swal.mixin({
        willOpen: (toast) => {
          let confirm = document.getElementsByClassName('swal2-confirm swal2-styled swal2-default-outline');
          let cancel = document.getElementsByClassName('swal2-cancel swal2-styled swal2-default-outline');
          confirm[0].setAttribute('id', 'confirm');
          cancel[0].setAttribute('id', 'cancel');
        }
      })


      reactSwal.fire({
        title: "",
        text: `${errMessage}!`,
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        confirmButtonColor: 'red',
        cancelButtonColor: '#b3bc35',
        buttonsStyling: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          const params = {
            caller: func,
            test: false,
            status: constants.CONNECTION_CLOSED_BY_LOGOUT,
          }
          const result = await CleanExit(params);
          log.trace("%sresult:%s", func, result);
          removeAllIds(func + "_2");
          window.location.href = "/";

        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          return;
        }
      })


      break;
    default:
      window.onbeforeunload = null;
      const twspAlert = Swal.mixin(config.sweetAlertError);
      twspAlert.fire('', errMessage).then(async (result) => {
        await CleanExit(func + "ok", false);
        log.trace("%sresult:%s", func, result);
        if (result.isConfirmed) {
          removeAllIds(func + "_3");
          window.location.href = "/";
        }
      });
      break;
  }

}//RedirectToLogin

/**
* CloseCustomer
* @function CloseCustomer
*/
export async function CloseCustomer(errMessage, caller) {
  const func = "CloseCustomer(" + caller + "),userActions.js,";
  log.debug("%sError message:%s", func, errMessage);

  if (getShutDown(func)) {
    return;
  }
  await CleanExit(func);
  log.error("%serrMessage:%s", func, errMessage);
  Swal.fire(`${errMessage}!`, {
    icon: "error",
    showConfirmButton: true,
    showCancelButton: false,
    showDenyButton: false,
    confirmButtonText: "Ok",
    cancelButtonText: "Cancel",
    timer: 5000,
  });
}//CloseCustomer


/**
* Display an Error
* @function DisplayError
*/
export function DisplayError(errMessage, caller, frame = null) {
  const func = "DisplayError(" + caller + "),userActions.js,";
  log.trace("%serrMessage:%s,frame:%s", func, errMessage, frame);
  if (getShutDown(func)) {
    return;
  }
  setLocalStorage("lastError", errMessage, func);
  const twspAlert = Swal.mixin(config.sweetAlertError);
  twspAlert.fire('', errMessage).then(async (result) => {
    if (result.isConfirmed) {
      if (frame) {
        removeId(constants.C2C_STREAM_NAME, func, true);
        frame.closeFrame();
      }
    }
  });
}//DisplayError

/**
* Update token before developer entries
* @TODO this is huge security gap have to remove it before system going to live
* @function UpdateToken
* @param {JSON} data username,streaming_id
* @return {JSON} 
* @example
* import { UpdateToken } from "../store/actions/userActions";
* ....
* const data={  "username":"testcustomer100",
*               "performer":"7d90dbae-62ea-11eb-bc34-39145808f34b"
*            }
* const response = await this.props.dispatch(UpdateToken(data));
* ....
* Return Success:
* {
*     "response":"Success",
*     "message":{
*                 "username":"testcustomer100",
*                 "performer":"7d90dbae-62ea-11eb-bc34-39145808f34b"
*               }
* }
*  
* Return error:
* { response: 'Error', message: 'Login Api call failure' }
*/

export function UpdateToken(data) {
  const func = "UpdateToken(),userActions.js,";
  log.debug("%sUpdateToken parameters:%s", func, data);
  let retVal = null;
  return async dispatch => {
    dispatch(ShowLoader(true));

    const apiUrl = BaseUrl + constants.A_UPDATE_TOKEN;
    log.trace("%sapiUrl:%s", func, apiUrl);

    const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
      log.error("%serror:%s", func, error);
    })

    log.trace("%sresult:%s", func, result);
    if (!result) {
      dispatch(ShowLoader(false));
      retVal = { response: constants.RESULT_ERROR, message: errors.AUTH_USER_API };
      log.error("%sretVal:%s", func, retVal);
      return retVal;
    }

    if (result.response === constants.RESULT_ERROR) {
      dispatch(ShowLoader(false));
      retVal = result;
      log.error("%sretVal:%s", func, retVal);
      return retVal;
    }
    retVal = result;
    log.trace("%sretVal:%s", func, retVal);
    return retVal;
  }

}//UpdateToken



