/**
* Commonly used functions
* @module Common
* @version 1.2
* @copyright Telecom2 Ltd.
*/


import config from '../../config.json';
import packageJson from '../../../package.json';
import { log } from './logger';
import axios from 'axios';
import { PING_READY } from '../types/types';
import Swal from 'sweetalert2';
import constants from '../../constants';
import errors from '../../errors';
import { RedirectToLogin } from "../../store/actions/userActions";
import { ping } from '../../store/helpers/ping';
import { LOG_LEVELS } from 'red5pro-webrtc-sdk'

export const BaseUrl = getBaseUrl();

/**
 * BaseUrl to call NODEJS API
 * BaseUrl
 */
function getBaseUrl() {
    const func = "getBaseUrl(),common.js,";
    let retVal = null;
    const httpString = config.node.ssl ? 'https' : 'http';
    log.trace("%shttpString:%s", func, httpString);
    retVal = `${httpString}://${config.node.host}:${config.node.port}${config.node.restApiRoot}`;
    log.trace("%sretVal:%s", func, retVal);
    return retVal.toString();
}//getBaseUrl

/**
 * @param  {} key
 * @param  {} value
 */
export function getChatUserName(caller) {
    const func = "getChatUserName(" + caller + "),common.js,";

    let retVal = getId('username', func, true);
    if (!retVal) retVal = getId('performerName', func, true);
    if (!retVal) {
        retVal = errors.USERNAME_IS_NULL;
        log.fatal("%s", func, errors.USERNAME_IS_NULL);
    }
    log.trace("%sretVal:%s", func, retVal);
    return retVal;
}//getChatUserName

/**
 * @param  {} script_urls
 */
export async function loadScript(script_urls) {
    const func = "loadScript(),common.js,";
    log.trace("%ssrc:%s", func, script_urls);
    let loaded = new Set();

    function load(script_url) {
        return new Promise((resolve, reject) => {
            if (loaded.has(script_url)) {
                log.trace("%sresolve:%s", func, loaded);
                resolve();
            } else {
                var script = document.createElement('script');
                script.onload = resolve;
                script.src = script_url
                document.head.appendChild(script);
                log.trace("%sadd:%s", func, script_url);
            }
        });
    }//load

    let promises = [];
    for (const script_url of script_urls) {
        promises.push(load(script_url));
    }
    await Promise.all(promises);
    log.trace("%spromise.all", func);
    for (const script_url of script_urls) {
        loaded.add(script_url);
        log.trace("%sloaded:%s", func, script_url);
    }
    let retVal = Array.from(loaded);
    log.trace("%sreturn:%s", func, retVal);
    return retVal;
}//loadScript


/**
 * @param  {} script_urls
 */
export async function unloadScript(script_urls) {
    const func = "unloadScript(),common.js,";
    log.trace("%ssrc:%s", func, script_urls);
    let removed = new Set();

    function load(script_url) {
        return new Promise((resolve, reject) => {
            if (removed.has(script_url)) {
                log.trace("%sresolve:%s", func, removed);
                resolve();
            } else {
                var script = document.createElement('script');
                script.onload = resolve;
                script.src = script_url
                document.head.remove(script);
                log.trace("%sremove:%s", func, script_url);
            }
        });
    }//load

    let promises = [];
    for (const script_url of script_urls) {
        promises.push(load(script_url));
    }
    await Promise.all(promises);
    log.trace("%spromise.all", func);
    for (const script_url of script_urls) {
        removed.add(script_url);
        log.trace("%sremoved:%s", func, script_url);
    }
    let retVal = Array.from(removed);
    log.trace("%sreturn:%s", func, retVal);
    return retVal;
}//unloadScript


/**
 * convertError
 * @param {*} obj
 * @return {string} retVal converted error
 */
export const convertError = (obj) => {
    const func = "convertError(),common.js,";
    log.trace("%sobj:%s", func, obj);

    let retVal = null;
    if (obj !== undefined && obj !== null && obj.constructor === String) {
        retVal = obj;
        log.trace("%sstring retVal:%s", func, retVal);
        return retVal;

    } else if (obj !== undefined && obj !== null) {
        //string let's try to parse to detect json
        try {
            JSON.parse(obj);
            log.trace("%s try parse :%s", func, obj);
        }
        catch (error) {
            //not json return, but detect first may en error object and it has message member
            if (obj.message) {
                retVal = obj.message;
                log.trace("%sretVal:%s", func, retVal);
                return retVal;
            }
            // no message member detected return with object
            retVal = obj;
            log.trace("%sretVal:%s", func, retVal);
            return retVal;
        }
        //json
        if (obj.message) {
            retVal = obj.message;
            log.trace("%sretVal:%s", func, retVal);
            return retVal;
        }
    }
    retVal = "Invalid error message";
    log.trace("%sretVal:%s", func, retVal);
    return retVal;
};//convertError

async function pingCall(caller) {
    const func = "pingCall(" + caller + "),common.js,";
    let now = Date.now();
    log.trace("%snow:%s", func, now);
    const lastPing = parseInt(getLocalStorage('lastPing', func));
    log.trace("%slastPing:%s", func, lastPing);
    const nextPing = lastPing + config.heartBeat.interval;
    log.trace("%snextPing:%s", func, nextPing);
    const diff = nextPing - now;
    log.trace("%sdiff:%s", func, diff);
    if (now > nextPing) {
        now = Date.now();
        setLocalStorage('lastPing', now, func);
        log.trace("%s set lastPing:%s", func, now);

        const retVal = await ping(func).catch((error) => {
            log.fatal("%scatch(error):%s", func, error);
        });
        if (retVal) {
            if (retVal.response === constants.ERROR) {
                log.fatal("%serror-1,retVal:%s", func, retVal);
            }
        } else {
            log.fatal("%serror-2,retVal:%s", func, retVal);
        }
    }
}//pingCall


/**
 * Commonly used functions to call different APIs
 * @param  {string} url API call
 * @param  {string} method request method
 * @param  {json} data post data
 * @example 
 *  const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
 *      log.fatal("%serror:%s", func, error);
 *      });
 *  log.trace("%sresult:%s", func, result);
 */
export function apiCallAwait(url, method, data) {
    let caller = "";
    if (data.caller) {
        caller = data.caller;
    }
    const func = "apiCallAwait(" + caller + "),common.js,";
    log.trace("%surl:%s", func, url);
    log.trace("%smethod:%s", func, method);
    log.trace("%sdata:%s", func, data);
    const cancelTokenSource = axios.CancelToken.source();
    let retVal = null;
    let result = null;
    return new Promise(async (resolve, reject) => {
        result = await axios(url, {
            baseURL: BaseUrl,
            method: method,
            data: data,
            headers: { 'Content-Type': 'application/json' },
            timeout: config.node.timeout,
            cancelToken: cancelTokenSource.token
        }).catch(function (error) {
            let errorMessage = null;
            errorMessage = error.message;

            if (error.response) {
                errorMessage = error.response.data.error.message;
            }


            retVal = { response: constants.RESULT_ERROR, message: errorMessage };
            log.fatal("%scatch error:%s", func, error);
            cancelTokenSource.cancel("Canceling error");
            if (axios.isCancel(error)) {
                retVal = { response: constants.RESULT_ERROR, message: errors.CANCELING };
            }
        })
        if (!result) {
            log.fatal("%sretVal-1:%s", func, retVal);
            if (retVal.message === errors.TOKEN_EXPIRED || retVal.message === errors.TOKEN_VALIDATION) {
                //this is display a message but still streaming until user press okay
                RedirectToLogin(retVal.message, func);
                //this will return to login without any displayed error message    
                //RedirectToLogin("",func);
            }
            return reject(retVal);
        }
        //don't call ping twice
        const httpString = config.webserver.ssl ? 'https' : 'http';
        const urlToCall = `${httpString}://${config.webserver.host}:${config.webserver.port}`;
        if (url.includes(constants.A_PING) || url === urlToCall) {

        } else {
            pingCall(func);
        }
        retVal = result.data;
        log.trace("%sretVal-2:%s", func, retVal);
        return resolve(retVal);
    });//promise
} //apiCallAwait

/**
 * red5ErrorLevel
 * @return {string} retVal converted error
 */
export function red5ErrorLevel() {
    const func = "red5ErrorLevel(),common.js,";
    let retVal = null;
        //turn off red5pro log to console here, except errors that are important
    //and not occupie much space like debug does
    if (config.consolelog.red5pro !== "on") {
        return LOG_LEVELS.ERROR;
    }

    switch (config.log.level) {
        case "trace":
            retVal = LOG_LEVELS.TRACE;
            break;
        case "debug":
            retVal = LOG_LEVELS.DEBUG;
            break;
        case "info":
            retVal = LOG_LEVELS.INFO;
            break;
        case "warn":
            retVal = LOG_LEVELS.WARN;
            break;
        case "error":
            retVal = LOG_LEVELS.ERROR;
            break;
        case "fatal":
            retVal = LOG_LEVELS.FATAL;
            break;
        default:
            log.fatal("%sInvalid config.log.level:%s", func, config.log.level);
            break;
    }
    log.trace("%sretVal:%s", func, retVal);
    return retVal;
};//red5ErrorLevel

/**
 * set shutdown flag to stop not neccessary calls while the system going down
 * @param  {bool} value
 * @param  {sting} caller invoking function
 */
export function setShutDown(value, caller) {
    const func = "setShutDown(),common.js,";
    setLocalStorage(constants.SHUTDOWN, value, func);
    const storageValue = getLocalStorage(constants.SHUTDOWN, func);
    if (String(storageValue) !== String(value)) {
        log.fatal("%sset shutdown failed!,storageValue:%s,value:%s,caller:%s", func, storageValue, value, caller);
        return false;
    }
    log.debug("%sshutdown is activated,set by caller:%s", func, caller);
} //shutDown

/**
 * get shutdown flag value to detect system is going down or not
 * @param  {string} caller invoking function
 */
export function getShutDown(caller) {
    const func = "getShutDown(),common.js,";
    const storageValue = getLocalStorage(constants.SHUTDOWN, func, true);
    if (String(storageValue) === "true") {
        log.debug("%sshutdown is active,caller:%s", func, caller);
        return true;
    }
    else return false;
} //getShutDown

/**
 * set stop flag to stop not neccessary calls while stream is stopped by the performer * @param  {bool} value
 * @param  {sting} caller invoking function
 */
export function setStop(value, caller) {
    const func = "setStop(),common.js,";
    setLocalStorage(constants.STOP, value, func);
    const storageValue = getLocalStorage(constants.STOP, func);
    if (String(storageValue) !== String(value)) {
        log.fatal("%sset stop failed!,storageValue:%s,value:%s,caller:%s", func, storageValue, value, caller);
        return false;
    }
    log.debug("%stop is activated,set by caller:%s", func, caller);
} //setStop

/**
 * get stop flag value to detect streaming is stopped
 * @param  {string} caller invoking function
 */
export function getStop(caller) {
    const func = "getStop(),common.js,";
    const storageValue = getLocalStorage(constants.STOP, func, true);
    if (String(storageValue) === "true") {
        log.debug("%sstop is active,caller:%s", func, caller);
        return true;
    }
    else return false;
} //getStop


/**
 * set ping ready flag to start pinging servers
 * @param  {bool} value
 * @param  {string} caller invoking function
 */
export function setPingReady(value, caller) {
    const func = "setPingReady(),common.js,";
    setLocalStorage(PING_READY, value, func);
    const storageValue = getLocalStorage(PING_READY, func);
    if (String(storageValue) !== String(value)) {
        log.fatal("%sset pingReady failed!,storageValue:%s,value:%s,caller:%s", func, storageValue, value, caller);
        return false;
    }
    log.debug("%spingReady is set by caller:%s", func, caller);
} //setPingReady

/**
 * get ping ready flag
 * @param  {string} caller invoking function
 */
export function getPingReady(caller) {
    const func = "getPingReady(),common.js,";
    const pingReady = getLocalStorage(PING_READY, func, true);
    log.trace("%spingReady:%s", func, caller);
    if (String(pingReady) === "true") {
        log.debug("%spingReady is active,caller:%s", func, caller);
        return true;
    }
    else return false;
} //getPingReady

/**
 * remove shutdown flag
 * @param  {string} caller invoking function
 */
export function removeShutDown(caller) {
    const func = "removeShutDown(),common.js,";
    removeLocalStorage(constants.SHUTDOWN, func, true);
    log.debug("%sshutdown removed:%s", func, caller);
    return true;
} //removeShutDown


/**
 * set traceable status changes
 * @param  {mixed} value
 * @param  {callback} cb
 * @param  {obj} obj invoking object usually current object stored here (this)
 * @param  {string} caller invoking function
 */
export function setState(value, cb, obj, caller) {
    if (!caller) caller = "null:("
    const func = "setState(" + caller + "),common.js,";
    log.trace("%svalue:%s", func, value);
    // log.trace("%sobj:%s", func, obj);
    if (!caller) {
        log.fatal("%caller IS NULL", func);
        return;
    }
    if (!obj) {
        log.fatal("%sobj IS NULL", func);
        return;
    }
    obj.setState(value);
    log.trace("%sstate:%s", func, obj.state);
    if (cb) {
        log.trace("%scb called with value:%s", func, value);
        cb();
    }
} //setState

/**
 * set user config to local storage
 * @param  {JSON} value user config
 * @param  {string} caller invoking function
 */
export function setUserConfig(value, caller) {
    const func = "setUserConfig(),common.js,";
    log.debug("%svalue:%s,caller:%s", func, value, caller);
    return setLocalStorage("userConfig", JSON.stringify(value), caller);
} //setUserConfig

/**
 * retrieve user configuration from local storage
 * @param  {string } caller invoking function
 * @param  {bool} suppressError=false
 */
export function getUserConfig(caller, suppressError = false) {
    const func = "getUserConfig(),common.js,";
    log.trace("%scaller:%s", func, caller);
    let retVal = {};
    const userConfig = getLocalStorage("userConfig", caller, suppressError);
    if (userConfig === undefined || userConfig === null) {
        retVal = {
            keyFramerate: config.publisher.keyFramerate,
            bandwidth: {
                audio: config.publisher.bandwidth.audio,
                video: config.publisher.bandwidth.video,
            },
            video: {
                width: config.publisher.video.width,
                heigth: config.publisher.video.height,
                framerate: config.publisher.video.framerate
            },
            buffer: config.publisher.buffer,
            show: false,
            cameraSource: { value: null, label: null },
            audioSource: { value: null, label: null }

        }
    } else {
        retVal = JSON.parse(userConfig);
    }
    const videoSize = `${retVal.video.width}x${retVal.video.heigth}`;
    retVal.videoBandWidth = { label: `${retVal.bandwidth.video}kbps`, value: retVal.bandwidth.video };
    retVal.videoSize = { label: videoSize, value: videoSize };
    log.debug("%sReturned userConfig:%s", func,retVal);
    return retVal;
} //getUserConfig

/**
 * set an id
 * @param  {string} key
 * @param  {mixed} value
 * @param  {string} caller invoking function
 */
export function setId(key, value, caller) {
    const func = "setId(),common.js,";
    log.trace("%skey:%s,value:%s,caller:%s", func, key, value, caller);
    return setLocalStorage(key, value, caller);
} //setId

/**
 * retrieve an id value
 * @param  {string} key
 * @param  {string} caller invoking function
 * @param  {bool} suppressError=false
 */
export function getId(key, caller, suppressError = false) {
    const func = "getId(),common.js,";
    log.trace("%skey:%s,caller:%s", func, key, caller);
    return getLocalStorage(key, caller, suppressError);
} //getId

/**
 * remove an id
 * @param  {string} key
 * @param  {string} caller invoking function
 * @param  {bool} suppressError=false
 */
export function removeId(key, caller, suppressError = false) {
    const func = "removeId(),common.js,";
    log.trace("%skey:%s,caller:%s", func, key, caller);
    return removeLocalStorage(key, caller, suppressError);
} //removeId

/**
 * set local storage value
 * @param  {string} key
 * @param  {mixed} value 
 * @param  {string} caller invoking function
 */
export function setLocalStorage(key, value, caller) {
    const func = "setLocalStorage(),common.js,";
    log.trace("%skey:%s,value:%s,caller:%s", func, key, value, caller);
    localStorage.setItem(key, value);
    const localStorageValue = localStorage.getItem(key);
    if (String(localStorageValue) !== String(value)) {
        log.fatal("%sset localStorage failed!,key:%s,localStorageValue:%s,value:%s,caller:%s", func, key, localStorageValue, value, caller);
        return false;
    }
    log.trace("%sset localStorage was ,key:%s,value:%s,caller:%s", func, key, value, caller);
    return true;
} //setLocalStorage

/**
 * get a value from local storage
 * @param  {string} key
 * @param  {string} caller invoking function
 * @param  {bool} suppressError=false
 */
export function getLocalStorage(key, caller, suppressError = false) {
    const func = "getLocalStorage(),common.js,";
    log.trace("%skey:%s,caller:%s", func, key, caller);
    let retVal = localStorage.getItem(key);
    if (retVal === undefined) {
        if (!suppressError) log.fatal("%sget localStorage failed!,key:%s is undefined, caller:%s", func, key, caller);
        else log.trace("%sget localStorage failed!,key:%s is undefined, caller:%s", func, key, caller);
    } else if (!retVal) {
        if (!suppressError) log.trace("%sget localStorage warning,key:%s is empty!,caller:%s", func, key, caller);
    } else {
        log.trace("%slocalStorage get was successfull,key:%s,caller:%s", func, key, caller);
    }
    return retVal;
} //getLocalStorage

/**
 * remove a value from local storage
 * @param  {string} key
 * @param  {string} caller invoking function
 * @param  {bool} suppressError=false
 */
export function removeLocalStorage(key, caller, suppressError = false) {
    const func = "removeLocalStorage(" + caller + "),common.js,";
    log.trace("%skey:%s,caller:%s", func, key, caller);
    localStorage.removeItem(key);
    let retVal = localStorage.getItem(key);
    if (retVal !== undefined) {
        if (!suppressError) log.fatal("%sremove localStorage failed!,key:%s is undefined, caller:%s", func, key, caller);
        else log.trace("%sremove localStorage failed!,key:%s is undefined, caller:%s", func, key, caller);
        return false;
    } else {
        log.trace("%slocalStorage removal was successfull,key:%s,value:%s,caller:%s", func, key, caller);
        return true;
    }
}//removeLocalStorage

/**
 * @param  {} message
 * @param  {} caller
 * @param  {} success=false
 */
export function displayPopup(message, caller, success = false) {
    const func = "displayPopup(),common.js,";
    if (success) {
        Swal.fire({ title: "Success!", text: message, icon: "success" });
        log.info("%sfunc,message:%s,caller:%s", func, message, caller);
    } else {
        Swal.fire({ title: "Error!", text: message, icon: "error" });
        log.fatal("%sfunc,message:%s,caller:%s", func, message, caller);

    }
}//displayPopup


/**
 * @param  {} arr
 */
function groupByTypeID(arr) {
    const func = "groupByTypeID(),common.js,";
    log.trace("%sarr:%s", func, arr);
    let retVal = {};
    arr.each(arr, function () {
        let currentCount = retVal[this.TypeID] || 0;
        retVal[this.TypeID] = currentCount + parseInt(this.TypeCount);
    });
    log.trace("%sretVal:%s", func, retVal);
    return retVal;
}//groupByTypeID

//non tested
/**
 * @param  {} array1
 * @param  {} array2
 */
export function compareJSONArrays(array1, array2) {
    const func = "compareJSONArrays(),common.js,";
    log.trace("%sarray1:%s,array2:%s", func, array1, array2);
    let userArrayGroups = groupByTypeID(array1);
    let origArrayGroups = groupByTypeID(array2);

    let retVal = {};
    for (var prop in userArrayGroups) {
        retVal[prop] = userArrayGroups[prop] - origArrayGroups[prop];
    }
    return retVal;
}//compareJSONArrays

/**
 * @param  {string} str
 */
export function urldecode(str) {
    return decodeURIComponent((str + '').replace(/\+/g, '%20'));
}



/**
 * @param  {string} str
 */
export function getCurrentTheme(value, caller) {
    //const func = "getCurrentTheme(),common.js,";
    let retVal = "/assets/templates/" + config.theme + value;
    return retVal;
}

/**
 * @param  {} caller
 * https://stackoverflow.com/questions/51207570/how-to-clear-browser-cache-in-reactjs
 */
export async function deleteBrowserCache(caller) {
    const func = "deleteBrowserCache(" + caller + "),common.js,";
    //log.trace("%sdata:%s", func, data);
    let version = getLocalStorage('version');
    log.debug("%sversion from local storage:%s", func, version);
    log.debug("%spackage.json version:%s", func, packageJson.version);
    if (version !== packageJson.version) {
        log.info("%supdated from version:%s to version:%s", func, version, packageJson.version);
        if ('caches' in window) {
            caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                    log.debug("%sDeleted %s from browser cache.", func, name);
                })
            });
            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload(true);
            log.debug("%sReload is set to true, after browser cache deleted", func);
        }
        setLocalStorage('version', packageJson.version);
    }
}//deleteBrowserCache

/**
 * Sleep in ms usefull wait before exit to all pending executions like write log etc.
 * @param {int} ms sleep time
 */
export function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export function getFunc(obj, name) {
    return `${name}(),${obj.fileName}`;
}
