/* eslint-disable no-multi-str */
/**
* LiveStreaming component Publisher
* @module LiveStreaming
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import React, { Component } from 'react';
import Select from 'react-select';
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { StreamCreate, StreamDelete } from "../../store/actions/dashboardActions";
import { connect } from "react-redux";
import { red5ErrorLevel, getShutDown, displayPopup, getUserConfig } from "../../store/helpers/common";
import Modal from "react-bootstrap/Modal";
import SimpleReactValidator from "simple-react-validator";
import config from '../../config.json';
import {
  setId,
  getId,
  removeId,
  setState,
  setUserConfig,
  setPingReady,
  setShutDown,
  setStop,
} from "../../store/helpers/common";
import { RTCPublisher, PublisherView, setLogLevel } from 'red5pro-webrtc-sdk'
import {
  removeAllIds,
  CleanExit
} from "../../store/actions/userActions";
import {
  notificationContent
} from "../../store/actions/streamingsubscriberActions";
import { log } from '../../store/helpers/logger';
import constants from '../../constants';
import errors from '../../errors';
import Swal from 'sweetalert2';
import Bowser from "bowser"

/**
* LiveStreaming component
*/
export class _liveStreaming extends Component {
  cameraSourceOptions = null;
  audioSourceOptions = null;
  constructor(props) {
    const func = "constructor(),LiveStreaming.js,";
    log.info("%sprops:%s", func, props);
    super(props);
    const userConfig = getUserConfig(func);
    log.trace("%suserConfig:%s", func, userConfig);
    /**
     * @property {bool} startStreamingValue used for storing value of either streaming start or stop
     * @property {string} stream_name used for storing stream Name
     */
    this.state = {
      startStreamingValue: false,
      streamingStarted: 0,
      stream_name: "",                      //used for storing stream Name
      performer_id: "",                    //used for storing performer id of user
      playValue: false,                    //used for storing value of either stream play or pause
      muteValue: false,                    //used for storing value of either stream mute or unmute
      show: false,                          //used for storing value of either modal open or close
      fullScreenMode: false,                 //used for storing value of either fullScreenMode on or off
      bandwidthAudio: userConfig.bandwidth.audio,
      bandwidthVideo: userConfig.bandwidth.video,
      keyFramerate: userConfig.keyFramerate,
      videoWidth: userConfig.video.width,                         //used for storing video width
      videoHeigth: userConfig.video.heigth,                        //used for storing video height
      videoFramerate: userConfig.video.framerate,                       //used for storing video framerate
      verboseLogging: true,
      proxy: "streammanager",
      streamMode: "live",
      buffer: userConfig.buffer,
      useAudio: true,
      useVideo: true,
      publisherFailoverOrder: "rtc,rtmp",
      subscriberFailoverOrder: "rtc,rtmp,hls",
      cameraSource: userConfig.cameraSource,
      audioSource: userConfig.audioSource,
      videoSize: userConfig.videoSize,
      videoSizeOptions: config.publisher.video.sizeOptions,
      videoBandWidth: userConfig.videoBandWidth,
      videoBandWidthOptions: config.publisher.video.bandWidthOptions,
      cameraSourceOptions: "",
      audioSourceOptions: "",
      connectionParams: {
        username: "",
        password: "",
        token: ""
      },
      settingsClass: "btn d-block btn-orange",
      settingsAction: () => this.modalOpen()
    };
    this.publisher = undefined; //hold RTCPublisher when straming start
    this.viewer = undefined;
    this.validator = new SimpleReactValidator();
    this.checkBroswer()
    log.trace("%sthis.state:%s", func, this.state);
  }//constructor

  checkBroswer= async () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    console.log('browser.getPlatformType():', browser.getPlatformType());
    console.log('browser.getBrowserName() :', browser.getBrowserName());
    //get device
    if(browser.getPlatformType() !== 'desktop')
    {
      if(browser.getBrowserName() !== 'Chrome' || browser.getBrowserName() !== 'Safari' ||  browser.getBrowserName() !== 'Firefox')
      {
        const twspAlert = Swal.mixin(config.sweetAlertError);
        //catch was not recognised
        // const result = await twspAlert.fire('', message).catch((error) => {
        //     log.error("%s,twspAlert.fire,catch(error):%s", func, error.message);
        // });
        const result = await twspAlert.fire('', 'You are using a unsupported browser, please use one of the following; <br>Chrome<br>Safari<br>Firefox');
      
        if (result.isConfirmed) {
          window.top.location.href = window.location.host;
        }
      }

    }

  }//checkBroswer

  /**
   * extract available web cams from device list
   * @param  {JSON} devices
   */
  async listDevices(devices) {
    const func = "listDevices(),LiveStreaming.js,";
    let retVal = { cameras: null, microphones: null };
    const cameras = devices.filter(function (item) {
      return item.kind === 'videoinput';
    })
    log.trace("%scameras:%s", func, cameras);

    const microphones = devices.filter(function (item) {
      return item.kind === 'audioinput';
    })
    log.trace("%smicrophones:%s", func, microphones);

    const camOptions = cameras.map(function (camera, index) {
      return { value: camera.deviceId, label: camera.label + index }
    });
    const micOptions = microphones.map(function (microphone, index) {
      return { value: microphone.deviceId, label: microphone.label + index }
    });

    retVal.cameras = camOptions;
    retVal.microphones = micOptions;
    log.info("%sretVal:%s", func, retVal);
    return retVal;
  }//list devices

  /**
   * get webcams to select list in settings
   */
  deviceSource = async () => {
    const func = "deviceSource(),LiveStreaming.js,";
    //ask first for permission    
    try {
      //retrieve available devices from browser agent

      const result = await navigator.mediaDevices.enumerateDevices().catch((error) => {
        if (error) log.error("%serror:%s", func, error);
      });
      let cameraSourceOptions = null;
      let audioSourceOptions = null;
      if (result) {
        const options = await this.listDevices(result);
        audioSourceOptions = options.microphones;
        cameraSourceOptions = options.cameras;
      }
      log.trace("%sresult:%s", func, result);
      setState({
        cameraSourceOptions: cameraSourceOptions,
        audioSourceOptions: audioSourceOptions,
      }, null, this, func);

      log.trace("%saudioSourceOptions:%s", func, audioSourceOptions);
      log.trace("%scameraSourceOptions:%s", func, cameraSourceOptions);
    } catch (e) {
      await this.handleErrorEvent("deviceSource:" + e.message, func);
      log.error("%serror(catch):%s", func, e);
    }
  }//deviceSource


  UNSAFE_componentWillUnMount() {
    const func = "UNSAFE_componentWillUnMount(),LiveStreaming.js,";
    //log.trace("%sred5Scripts:%s", func, red5Scripts);
    this.refreshTimer = 0;
    clearInterval(this.refreshTimer);
    removeAllIds(func);
    setShutDown(true, func);
    setPingReady(false, func);
  } //UNSAFE_componentWillMount


  /**
   * 
   */
  componentDidMount() {
    const func = "componentDidMount(),LiveStreaming.js,";
    log.trace("%sthis.props:%s", func, this.props);
    const performerId = getId("performer_id", func);

    setState({
      performer_id: performerId,
      connectionParams: {
        username: getId("performerFullName", func),
        password: getId("performerFullName", func),
        token: getId("red5_token", func)
      }
    }, null, this, func);
    log.trace("%sthis.state:%s", func, this.state);
    setTimeout(async () => {
      this.setState({
        startStreamingValue: true,
      }, async () => {
        log.debug("%sConnect to the camera..", func);
        await this.connectToCamera();
      })
    }, 1000);

  }//componentDidMount

  /**
  *
  * @param {*} eventType
  */
  async handleErrorEvent(message, caller) {
    const func = "handleErrorEvent(" + caller + "),LiveStreaming.jsx,";
    log.fatal("%smessage:%s", func, message);

    await this.stopStreaming(message, func);
    const params =
    {
      caller: func,
      test: false,
      status: constants.CONNECTION_CLOSED_BY_CLEAN_EXIT
    }
    await CleanExit(params);
    const twspAlert = Swal.mixin(config.sweetAlertError);
    const result = await twspAlert.fire('', message).catch((error) => {
      log.fatal("%s,twspAlert.fire,catch(error):%s", func, error.message);
    });
    if (result.isConfirmed) {
      log.debug("%sconfirmed", func);
      log.trace("%sredirect here,message:%s", func, message);
      const redirectUrl = getId('returnUrl', func);
      log.debug("%s,Redirected to this url:%s", func, redirectUrl);
      window.top.location.href = redirectUrl;
    }


  } //handleErrorEvent

  /**
   * this function contain subscriber event
   * @param {event} event
   */
  handlePublisherEvent = (event) => {
    const func = "handlePublisherEvent(),LiveStreaming.jsx,";
    log.trace("%sevent.type:%s", func, event.type);

    if (event.type === "Connect.Success") {
      log.info("%sevent.type:%s", func, event.type);
    } else if (event.type === "Publisher.Connection.Closed") {
      if (this.state.streamName) {
        this.handleErrorEvent(errors.RED5PRO_PUBLISHER_CONNECTION_CLOSED, func);
      }
    } else if (event.type === "Connect.Failure") {
      this.handleErrorEvent(errors.RED5PRO_CONNECT_FAILURE, func);
    }
  }; //handlePublisherEvent

  async connectToCamera() {
    const func = "connectToCamera(),LiveStreaming.js,";
    try {

      //sometimed usb camera get stuck due Chrome get stuck in background
      //this while cycles helps to make to detect it and use displaypopup custom message
      //BUSY_CAMERA
      log.trace("%sthis.publisher-before:%s", func, this.publisher);
      this.publisher = new RTCPublisher();
      this.publisher.on('*', this.handlePublisherEvent);
      this.viewer = new PublisherView('red5pro-publisher');

      //set red5 loglevel
      setLogLevel(red5ErrorLevel());



      await this.viewer.attachPublisher(this.publisher);
      //{ video: { deviceId: myPreferredCameraDeviceId } }
      //const media = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      const userConfig = getUserConfig(func);
      log.trace("%suserConfig:%s", func, userConfig);
      let media = null;
      if (userConfig.cameraSource.value !== null) {
        media = await navigator.mediaDevices.getUserMedia(
          {
            video:
            {
              width: {
                exact: Number(userConfig.video.width),
              },
              height: {
                exact: Number(userConfig.video.heigth),
              },
              deviceId:
              {
                exact: userConfig.cameraSource.value
              }, audio: true
            },
          }
        ).catch((error) => {
          log.error("%serror(getUserMedia-1):%s", func, error);
          displayPopup(errors.BUSY_CAMERA, func);
        });
      } else {
        media = await navigator.mediaDevices.getUserMedia({ video: true, audio: true }).catch((error) => {
          log.error("%serror(getUserMedia-2):%s", func, error.message);
          displayPopup(errors.BUSY_CAMERA, func);
        });
      }

      // Upon access of user media,
      // 1. Attach the stream to the publisher.
      // 2. Show the stream as preview in view instance.
      //this.publisher.attachStream(media);
      this.publisher._mediaStream = media;
      await this.viewer.preview(media, true);
      log.trace("%scamera connected..", func);
      setState({
        streamingStarted: 1,
      }, null, this, func);

    }
    catch (error) {
      log.fatal("%serror(catch):%s", func, error.message);
      switch (error.message) {
        case "Could not start video source":
          displayPopup(errors.BUSY_CAMERA, func);
          break;
        case errors.RED5PRO_API_NOT_LOADED:
          await this.handleErrorEvent(error.message, func);
          break;
        default:
          //await this.handleErrorEvent(error.message, func);
          displayPopup(errors.BUSY_CAMERA, func);
          break;
      }
    }
  }//connectToCamera

  /**
   * Start streaming
   * @async
   */
  startStreaming = async () => {
    const func = "startStreaming(),LiveStreaming.js,";
    const stream_name = uuidv4();

    if (getShutDown(func)) {
      return;
    }
    setStop(false, func);
    log.info("%sPublisher stream started,stream_name:%s", func, stream_name);
    this.setState({
      streamingStarted: 2,
    });


    //retrieve user video configuaration from local storage
    const userConfig = getUserConfig(func);
    log.debug("%sUser config:%s", func, userConfig);
    const initData = {
      protocol: config.red5.protocol,//"wss",
      host: config.red5.host,//"dev-red5-c7.t2.tl",
      port: config.red5.port,//443,
      app: config.red5.app,//"live",
      streamName: stream_name,
      rtcConfiguration: {
        iceServers: [config.red5.iceServers],
        //iceServers: [{ urls: "stun:stun2.l.google.com:19302" }],
      },
      keyFramerate: userConfig.keyFramerate,
      bandwidth: {
        audio: Number(userConfig.bandwidth.audio),
        video: Number(userConfig.bandwidth.video)
      },
      mediaConstraints: {
        audio: true,
        video: {
          width: {
            exact: Number(userConfig.video.width),
          },
          height: {
            exact: Number(userConfig.video.heigth),
          },
          frameRate: {
            min: Number(userConfig.video.framerate)
          },
          //set which webcamera will be used in stream session, if not yet set use system default
          deviceId: { exact: userConfig.cameraSource !== "" ? userConfig.cameraSource.value : null },
        }
      }, connectionParams: this.state.connectionParams
    };//initData

    log.debug("%sinitData:%s", func, initData);

    try {
      this.publisher
        .init(initData)
        .catch(function (error) {
          // A fault occurred while trying to initialize and subscribe to the stream.
          log.fatal("%sinit stream error:%s", func, error);

          this.publisher = undefined;
          this.viewer = undefined;
        });
    } catch (error) {
      displayPopup("init error catch:" + error.message, func);
    }
    if (this.publisher) {
      setTimeout(async () => {
        if (this.publisher._mediaStream !== undefined) {
          try {
            this.publisher.publish();

            const data = {
              stream_name: stream_name,
              performer_id: this.state.performer_id,
              streaming_id: getId("streaming_id", func),
              auth_token: getId("auth_token", func),
              caller: func,
            }
            log.debug("%sStreamCreate called from Node with these parameters to start publishing:%s", func, data);
            const createResult = await this.props.dispatch(StreamCreate(data)).catch((error) => {
              log.fatal("%s.catch(createResult),error:%s", func, error);
            });
            log.debug("%sCreate stream result from Node:%s", func,createResult);

            if (createResult.response === constants.RESULT_ERROR) {
              log.fatal("%sCreate stream unsuccessful!:%s", func, createResult);
              if (this.publisher._mediaStream !== undefined) {
                const tracks = this.publisher._mediaStream.getTracks();
                log.trace("%stracks:%s", func, tracks);
                tracks.forEach(track => track.stop())
              }

              this.publisher.unpublish();
              setState({
                startStreamingValue: false,
                stream_name: "",
                streamingStarted: 1,
              }, null, this, func);
              log.trace("%spublisher.unpublish(),this.state:%s", func, this.state);
              removeId('stream_name', func);

              this.handleErrorEvent(createResult.message, func);
              return;

            }
            setId('stream_name', stream_name, func);
            this.setState({
              //startStreamingValue: true,
              stream_name: stream_name,
              settingsClass: "btn d-block btn-disabled",
              settingsAction: ""
            }, () => {
              log.trace("%sstartStreaming..", func);
              this.props.setStateOfParent({
                stream_name: stream_name
              });
            })


            this.setState({
              streamingStarted: 3,
            });


          } catch (error) {
            log.fatal("%serror:%s", func, error);
          }
        }
        else {
          setState({
            startStreamingValue: false,
            streamingStarted: 1,
          }, null, this, func);
          log.trace('%ssetState:%s', func, this.state);
          displayPopup("Please allow Camera and Microphone access!", func);
        }
      }, 5000);
    }//if this.publisher
  }//startStreaming


  /**
   * Stop streaming
   * @async
   */
  stopStreaming = async (errMessage, caller) => {
    const func = "stopStreaming(" + caller + "),LiveStreaming.js,";
    const stream_name = getId('stream_name');
    setStop(true, func);
    //it was a mistake to use it here, still need to ping while perfomer has no living stream
    //due token validity
    //setPingReady(false, func);
    if (this.publisher) {

      try {
        const data = {
          auth_token: getId('auth_token'),
          streaming_id: getId('streaming_id'),
          stream_name: stream_name,
          performer_id: getId('performer_id'),
          caller: func,
        }
        log.debug("%sStreamDelete called from Node with these parameters %s to stop streaming.", func, data);
        log.trace("%sthis.state:%s", func, this.state);
        const streamDelete = await this.props.dispatch(StreamDelete(data)).catch((error) => {
          log.fatal("%serror:%s", func, error);
        });
        log.debug("%sStreamDelete call result from Node:%s", func, streamDelete);

        if (!streamDelete) {
          log.fatal("%sStreamDelete call failed, stream not stopped properly:%s", func);
        }
        this.publisher.unpublish();
        this.publisher.off('*', this.handlePublisherEvent);

        if (streamDelete.response === constants.RESULT_SUCCESS) {
          log.info("%sPerformer stoped %s stream successfully!", func, stream_name);

        }
        //this will remove notification content if connection not approved yet and still appeaer
        const pushData = {
          auth_token: getId("auth_token", func),
          streaming_id: getId("streaming_id", func),
          stream_name: this.state.stream_name,
          content: "",
          timestamp: Date.now(),
          uid: getId("streaming_id", func),
          type: constants.CHAT_PERFORMER_EXIT,
          to_username: null,
          status: false,
          caller: func,
        };
        log.trace('%spushData:%s', func, pushData);
        setState({
          startStreamingValue: false,
          streamingStarted: 1,
          playValue: false,
          muteValue: false,
          stream_name: "",
          settingsClass: "btn d-block btn-orange",
          settingsAction: () => this.modalOpen()
        }, null, this, func);
        removeId('stream_name', func, true);
        log.trace("%sunpublish done,this.state:%s", func, this.state);
        await this.props.dispatch(notificationContent(pushData));

        if (errMessage !== errors.STREAMING_IS_STOPPED_BY_PERFORMER) {
          this.handleErrorEvent(errMessage, func);
        }


      } catch (error) {
        log.fatal("%serror:%s", func, error);
      }

      this.publisher.off('*', this.handlePublisherEvent);
      if (this.publisher._mediaStream !== undefined) {
        const tracks = this.publisher._mediaStream.getTracks();
        tracks.forEach(track => track.stop())
        log.trace("%stracks.stop:%s", func, tracks);
      }

    }
    setTimeout(async () => {
      this.setState({
        startStreamingValue: true,
      }, () => {
        log.debug("%sAfter stream stopped connect back to the camera.", func);
        this.connectToCamera();
      })
    }, 1000);

  }//stopStreaming


  /**
   * Stop playing function
   */
  StopPlay = () => {
    const func = "StopPlay(),LiveStreaming.js,";
    log.info("%sStop play pressed..", func);

    if (this.publisher) {
      const video = document.getElementById("red5pro-publisher");
      try {
        this.publisher.muteVideo();
        log.trace("%svideo muted", func);
        this.publisher.muteAudio();
        log.trace("%saudio muted", func);
        video.pause();
        log.trace("%svideo paused", func);
        setState({
          playValue: true
        }, null, this, func);
        log.trace("%sthis.state", func, this.state);
      }
      catch (error) {
        displayPopup(error.message, func);
      }
    }
  }//StopPlay

  //this function contain start play streaming functionality
  StartPlay = () => {
    const func = "StartPlay(),LiveStreaming.js,";
    if (getShutDown(func)) {
      return;
    }

    log.info("%sStart play pressed..", func);
    if (this.publisher) {
      let video = document.getElementById("red5pro-publisher");
      try {
        this.publisher.unmuteVideo();
        log.trace("%svideo unmuted", func);
        this.publisher.unmuteAudio();
        log.trace("%saudio unmuted", func);
        video.play();
        log.trace("%svideo play", func);
        setState({
          playValue: false
        }, null, this, func);
        log.trace("%sthis.state", func, this.state);
      }
      catch (error) {
        displayPopup(error.message, func);
      }
    }
  }//Start Play

  /**
   *  mute function
   */
  mute = () => {
    const func = "mute(),LiveStreaming.js,";
    if (getShutDown(func)) {
      return;
    }
    log.info("%sMute function executed..", func);
    if (this.publisher) {
      try {
        this.publisher.muteAudio();
        log.trace("%saudio muted", func);
        setState({
          muteValue: true
        }, null, this, func);
        log.trace("%sthis.state", func, this.state);
      }
      catch (error) {
        log.error("%serror:%s", func, error);
      }
    }
  }//mute

  /**
   * unmute function
   */
  unmute = () => {
    const func = "mute(),LiveStreaming.js,";
    if (getShutDown(func)) {
      return;
    }
    log.info("%sUnmute function executed..", func);

    if (this.publisher) {
      try {
        this.publisher.unmuteAudio();
        log.trace("%saudio unmuted", func);
        setState({
          muteValue: false
        }, null, this, func);
        log.trace("%sthis.state", func, this.state);
      }
      catch (error) {
        log.error("%serror:%s", func, error);
      }
    }
  }//unmute



  /**
   * switch to fullscreen
   */
  fullScreen = () => {
    const func = "fullScreen(),LiveStreaming.js,";
    if (getShutDown(func)) {
      return;
    }
    log.info("%sSwitched to full screen", func);
    const elem = document.getElementById("red5pro-publisher");
    log.trace("%selem:%s", func, elem);

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    } else {
      log.error("requestFullscreen() not executed..");
    }
  }//fullScreen

  /**
   * Close modal panel
   */
  handleClose = () => {
    const func = "handleClose(),LiveStreaming.js,";
    setState({
      show: false
    }, null, this, func);
    log.trace("%sthis.state", func, this.state);
  }//handleClose

  /**
   * Open modal panel
   */
  modalOpen = async () => {
    const func = "modalOpen(),LiveStreaming.js,";
    if (getShutDown(func)) {
      return;
    }
    await this.deviceSource();

    if (this.state.cameraSource) {
      await this.checkRes(this.state.cameraSource, func + "(1)");
    }
    else {
      await this.checkRes(this.state.cameraSourceOptions[0], func + "(2)");
    }

    setState({
      show: true
    }, null, this, func);
    log.info("%sCamera settings panel opened..", func);
  }//modalOpen


  /**
   * This function take value from modal and update state
   * @param {event} e 
   */
  onChangeHandler = e => {
    const func = "onChangeHandler(),LiveStreaming.js,";
    setState({ [e.target.name]: e.target.value }, null, this, func);
  }//onChangeHandler

  /**
   * Save configuration
   * @param {event} e 
   */
  ConfigrationSaveHandler = (e) => {
    const func = "ConfigrationSaveHandler(),LiveStreaming.js,";
    e.preventDefault()
    const videoSize = this.state.videoSize.value.split("x");
    const userConfig =
    {
      bandwidth: {
        audio: this.state.bandwidthAudio,
        video: this.state.videoBandWidth.value,
      },
      keyFramerate: this.state.keyFramerate,
      video: {
        width: videoSize[0],
        heigth: videoSize[1],
        framerate: this.state.videoFramerate
      },
      show: false,
      cameraSource: this.state.cameraSource,
      audioSource: this.state.audioSource,
      buffer: this.state.buffer,
    }

    setState({
      bandwidthAudio: this.state.bandwidthAudio,
      bandwidthVideo: this.state.videoBandWidth.value,
      keyFramerate: this.state.keyFramerate,
      videoWidth: videoSize[0],
      videoHeigth: videoSize[1],
      videoFramerate: this.state.videoFramerate,
      show: false,
      cameraSource: this.state.cameraSource,
      audioSource: this.state.audioSource,
      buffer: this.state.buffer,
    }, null, this, func);
    setUserConfig(userConfig, func);
    this.connectToCamera();
    log.info("%sUser config saved by Settings:%s", func, userConfig);
  }//ConfigrationSaveHandler

  handleCameraSource = cameraSource => {
    const func = "cameraSource(),LiveStreaming.js,";
    setState({ cameraSource }, async () => {
      await this.checkRes(cameraSource, func);
    }, this, func);


    log.debug("%sselected cameraSource:%s:", func, cameraSource);
  };

  handleAudioSource = audioSource => {
    const func = "audioSource(),LiveStreaming.js,";
    setState({ audioSource }, null, this, func);
    log.debug("%sselected audioSource:%s:", func, audioSource);
  };


  handleVideoSize = videoSize => {
    const func = "handleVideoSize(),LiveStreaming.js,";
    setState({ videoSize }, null, this, func);
    log.trace("%sselected videoSize:%s:", func, videoSize);
  };

  handleVideoBandwidth = videoBandWidth => {
    const func = "handleVideoBandwidth(),LiveStreaming.js,";
    setState({ videoBandWidth }, null, this, func);
    log.trace("%sselected videoBandWidth:%s:", func, videoBandWidth);
  };

  async checkRes(cameraSource, caller) {
    const func = "checkRes(" + caller + "),LiveStreaming.js,";
    setState({
      videoSize: ''
    }, null, this, func);
    //let constraints = { audio: true, video: { width: 1280, height: 720 } }; 
    let sizeOptions = config.publisher.video.sizeOptions;
    let correctSizeOptions = config.publisher.video.sizeOptions;

    let counter = 0;
    while (counter < correctSizeOptions.length) {
      correctSizeOptions[counter].isDisabled = true;
      counter++;
    }

    sizeOptions.forEach(async (sizeOptions) => {
      let videodimension = sizeOptions.value.split("x");
      try {

        const getUserMedia = await navigator.mediaDevices
          .getUserMedia(
            {
              video:
              {
                width: { exact: videodimension[0] },
                height: { exact: videodimension[1] },
                deviceId:
                {
                  exact: cameraSource.value
                },
                audio: true
              },
            });
        log.debug("%sResolution check executed,getUserMedia:%s", func, getUserMedia);
        let counter = 0;
        while (counter < correctSizeOptions.length) {
          if (videodimension[0] + 'x' + videodimension[1] === correctSizeOptions[counter].label) {
            correctSizeOptions[counter].isDisabled = false;
            break;
          }
          counter++;
        }

        log.trace("%scorrectSizeOptions:%s", func, correctSizeOptions);

        setState({
          videoSizeOptions: correctSizeOptions
        }, null, this, func);

        if (this.state.userConfig === null) {
          setState({
            videoSize: [{ label: 'Please choose an size' }, { value: '' }]
          }, null, this, func);
        }

      }
      catch (error) {
        //log to console first 
        log.debug("%s:error:%s", func, error.name);
        //const errMessage = 'does not works ' + videodimension[0] + 'x' + videodimension[1];
        //log.error("%s:errMessage:%s", func, errMessage);
        if (error.name === "NotFoundError" || error.name === "DevicesNotFoundError") {
          //required track is missing 
        } else if (error.name === "NotReadableError" || error.name === "TrackStartError") {
          //webcam or mic are already in use 
        } else if (error.name === "OverconstrainedError" || error.name === "ConstraintNotSatisfiedError") {
          //constraints can not be satisfied by avb. devices 
        } else if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
          //permission denied in browser 
        } else if (error.name === "TypeError" || error.name === "TypeError") {
          //empty constraints object 
        } else {
          //other errors 
        }
      };
    });
  }//


  render() {
    const func = "render(),LiveStreaming.js,";
    log.trace("%sthis.state:%s", func, this.state);
    const {
      startStreamingValue,
      streamingStarted,
      muteValue,
      show,
      audioSource,
      audioSourceOptions,
      cameraSource,
      videoSize,
      videoBandWidth,
      videoSizeOptions,
      videoBandWidthOptions,
      cameraSourceOptions,
    } = this.state;
    return (
      <>
        <div className="card preview-section">
          <div className="card-body preview-body">
            <div className="camera-section">
              {startStreamingValue === false ? (
                <div id="no-connect">
                  <img src="assets/img/black.png" className="img-fluid" alt="" />
                </div>
              ) : (
                <>
                  <video id="red5pro-publisher" muted={true} autoPlay={true} disablePictureInPicture></video>
                  <div className="video-controler-ico-row">

                    <div className="col-12 video-controler-right-ico text-right">
                      <Link to="#" id="mute-unmute-button" onClick={() => (!muteValue ? this.mute() : this.unmute())}>{!muteValue ? <i className="fas fa-volume-up"></i> : <i className="fas fa-volume-mute"></i>}</Link>
                      <Link to="#" onClick={() => this.fullScreen()}><i className="fas fa-expand"></i></Link>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="row streaming-btn-sec">
              <div className="col-md-5">
                <Link to="#" data-toggle="modal" data-target="#appt_details" className={this.state.settingsClass} onClick={this.state.settingsAction}>Settings</Link>
              </div>
              <div className="col-md-7 text-right">
                {
                  streamingStarted === 0 ? (
                    <Link to="#" id="btn-waiting" className="btn d-block btn-orange" >{errors.WAITING_FOR_CAMERA}</Link>
                  ) :
                    streamingStarted === 1 ? (
                      <Link to="#" id="btn-start-streaming" className="btn d-block btn-green" onClick={() => this.startStreaming()}>Start Streaming</Link>
                    ) : streamingStarted === 2 ? (
                      <>
                        <Link to="#" id="btn-starting" className="btn d-block btn-green" >Starting...</Link>
                      </>
                    ) : (
                      <>
                        <Link to="#" id="btn-stop-streaming" className="btn d-block btn-green" onClick={async () => await this.stopStreaming(errors.STREAMING_IS_STOPPED_BY_PERFORMER, func)}>Stop Streaming</Link>
                      </>
                    )
                }
              </div>
            </div>

          </div>
        </div>

        <Modal show={show} onHide={() => this.handleClose()}>
          <Modal.Header closeButton style={{ borderBottom: 'none' }}>
            <h5 className="modal-title">Settings</h5>
          </Modal.Header>
          <Modal.Body >
            <form className="setting-from">
              <div className="form-group row">
                <label htmlFor="cameraSource" className="col-sm-5 align-self-center col-form-label text-right">Video Device</label>
                <div className="col-sm-7">
                  <Select id="cameraSource"
                    value={cameraSource}
                    onChange={this.handleCameraSource}
                    options={cameraSourceOptions}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="videoSize" className="col-sm-5 align-self-center col-form-label text-right">Video Size</label>
                <div className="col-sm-7">

                  <Select id="videoSize"
                    value={videoSize}
                    onChange={this.handleVideoSize}
                    options={videoSizeOptions}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="videoBandWidth" className="col-sm-5 align-self-center col-form-label text-right">Video Bandwidth</label>
                <div className="col-sm-7">
                  <Select id="videoBandWidth"
                    value={videoBandWidth}
                    onChange={this.handleVideoBandwidth}
                    options={videoBandWidthOptions}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="audioSource" className="col-sm-5 align-self-center col-form-label text-right">Audio Device</label>
                <div className="col-sm-7">
                  <Select id="audioSource"
                    value={audioSource}
                    onChange={this.handleAudioSource}
                    options={audioSourceOptions}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-sm-5"></div>
                <div className="col-sm-7">
                  <Link to="#" id="btn-save" className="btn btn-orange d-block" onClick={(e) => this.ConfigrationSaveHandler(e)}>Save</Link>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}//render

const connectedComponent = connect()(_liveStreaming);
export { connectedComponent as LiveStreaming };
