/**
* Chat database wrapper
* @module ChatHandler
* @version 1.0
* @copyright Telecom2 Ltd.
*/

import {log} from '../store/helpers/logger';
import { myHandler } from './MysqlHandler';
import constants from '../constants';
//import WebSocketHandler from './WebSocketHandler';

/**
 * ChatHandler handling class
 * newer code parts use it, olders are not yet
 * 
 */
export class ChatHandler {

    //count reconnect try in this property
    reConnectCounter = 0;
    handler = null;
    handlerName = null;


    constructor() {
        this.setHandler(constants.CHAT_HANDLER_MYSQL);
    }

    getHandler() {
        return this.handlerName;
    }
    /**
     * set database handler
     * @param  {} handler
     */
    setHandler(handler) {
        const func = "setHandler(),ChatHandler.js,";
        log.trace("%shandler is:%s", func, handler);
        this.handlerName = handler;
        switch (handler) {
            case constants.CHAT_HANDLER_MYSQL:
                this.handler = myHandler;
                break;

            default:
                log.fatal("%sinvalid handler:%s", func, handler);
                break;
        }
    }//setHandler

    /**
     * @param  {} data
     */
    async push(data) {
        const func = "push("+data.caller+"),ChatHandler.js,";
        data.caller=func;
        log.trace("%sdata:%s", func, data);
        const result = await this.handler.push(data).catch((error) => {
            log.fatal("%serror:%s", func, error);
            throw error;
        })
        log.trace("%sreturn:%s", func, result);
        return result;
    }//push

    /**
     * @param  {} data
     */
    async pull(data) {
        const func = "pull(),ChatHandler.js,";
        log.trace("%sdata:%s", func, data);
        const result = await this.handler.pull(data).catch((error) => {
            log.fatal("%serror:%s", func, error);
            throw error;
        })
        log.trace("%sreturn:%s", func, result);
        return result;
    }//pull

    /**
     * @param  {} data
     */
    async update(data) {
        const func = "update(),ChatHandler.js,";
        //put this func to calling chain
        data.func = func;
        log.trace("%sdata:%s", func, data);
        const result = await this.handler.update(data).catch((error) => {
            log.fatal("%serror:%s", func, error);
            throw error;
        })
        log.trace("%sreturn:%s", func, result);
        return result;
    }//update
}//ChatHandler

export const ch = new ChatHandler();
