/**
* CustomerPopup component
* @module CustomerPopup
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { JSFrame } from 'jsframe.js';
import Select from 'react-select';
import {
  //loadScript,
  red5ErrorLevel,
  getShutDown,
  displayPopup,
  getUserConfig,
  getId,
  setId,
  removeId,
  BaseUrl,
  apiCallAwait,
  setState, setUserConfig
} from "../../store/helpers/common";
import Modal from "react-bootstrap/Modal";
import SimpleReactValidator from "simple-react-validator";
import config from '../../config.json';
import { DisplayError } from "../../store/actions/userActions";
import { log } from '../../store/helpers/logger';
import constants from '../../constants';
import errors from "../../errors";
import { Scheduler } from '../../store/helpers/scheduler';
import { RTCPublisher, PublisherView, setLogLevel } from 'red5pro-webrtc-sdk'


/**
* CustomerPopup component
*/
export class CustomerPopup extends Component {
  cameraSourceOptions = null;
  constructor(props) {
    const func = "constructor(),CustomerPopup.jsx,";
    log.trace("%sprops:%s", func, props);
    super(props);
    const userConfig = getUserConfig(func);
    log.trace("%suserConfig:%s", func, userConfig);

    /**
     * @property {bool} startStreamingValue used for storing value of either streaming start or stop
     * @property {string} stream_name used for storing stream Name
     */
    this.state = {
      startStreamingValue: false,
      streamingStarted: 1,
      stream_name: props.stream_name,
      frame: props.frame,
      performer_id: "",
      playValue: false,
      muteValue: false,
      show: false,
      fullScreenMode: false,
      audio: userConfig.audio,
      video: userConfig.video,
      keyFramerate: userConfig.keyFramerate,
      videoWidth: userConfig.videoWidth,
      videoHeigth: userConfig.videoHeigth,
      videoFramerate: userConfig.videoFramerate,
      verboseLogging: true,
      proxy: "streammanager",
      streamMode: "live",
      buffer: userConfig.buffer,
      bandwidthAudio: userConfig.bandwidth.audio,
      bandwidthVideo: userConfig.bandwidth.video,
      useAudio: true,
      useVideo: true,
      publisherFailoverOrder: "rtc,rtmp",
      subscriberFailoverOrder: "rtc,rtmp,hls",
      cameraSource: userConfig.cameraSource,
      audioSource: userConfig.audioSource,
      videoSize: userConfig.videoSize,
      videoSizeOptions: config.publisher.video.sizeOptions,
      videoBandWidth: userConfig.videoBandWidth,
      videoBandWidthOptions: config.publisher.video.bandWidthOptions,
      cameraSourceOptions: "",
      audioSourceOptions: "",
      connectionParams: {
        username: "",
        password: "",
        token: ""
      }
    };
    setId(constants.C2C_STREAM_NAME, this.state.stream_name, func);
    this.publisher = undefined;
    this.viewer = undefined;
    this.connTimer = 0;
    this.c2cConnected = false;
    this.validator = new SimpleReactValidator();
    log.trace("%sthis.state:%s", func, this.state);
  }//constructor


  /**
   * extract available web cams from device list
   * @param  {JSON} devices
   */
  async listDevices(devices) {
    const func = "listDevices(),LiveStreaming.js,";
    let retVal = { cameras: null, microphones: null };
    const cameras = devices.filter(function (item) {
      return item.kind === 'videoinput';
    })
    log.trace("%scameras:%s", func, cameras);

    const microphones = devices.filter(function (item) {
      return item.kind === 'audioinput';
    })
    log.trace("%smicrophones:%s", func, microphones);

    const camOptions = cameras.map(function (camera, index) {
      return { value: camera.deviceId, label: camera.label + index }
    });
    const micOptions = microphones.map(function (microphone, index) {
      return { value: microphone.deviceId, label: microphone.label + index }
    });

    retVal.cameras = camOptions;
    retVal.microphones = micOptions;
    log.trace("%sretVal:%s", func, retVal);
    return retVal;
  }//list devices

  /**
   * get webcams to select list in settings
   */
  deviceSource = async () => {
    const func = "deviceSource(),LiveStreaming.js,";
    //ask first for permission    
    try {
      //retrieve available devices from browser agent

      const result = await navigator.mediaDevices.enumerateDevices().catch(async (error) => {
        if (error) log.error("%serror:%s", func, error);
        const errMessage = "enumerateDevices(catch):" + error.message;
        log.error("%serrMessage:%s", func, errMessage);
        await this.handleError(errMessage, func);

      });
      let cameraSourceOptions = null;
      let audioSourceOptions = null;
      if (result) {
        const options = await this.listDevices(result).catch(async (error) => {
          const errMessage = "enumerateDevices(catch):" + error.message;
          log.error("%serrMessage:%s", func, errMessage);
          await this.handleError(errMessage, func);
        });
        audioSourceOptions = options.microphones;
        cameraSourceOptions = options.cameras;
      }
      log.debug("%sResult of listDevices:%s", func, result);
      setState({
        cameraSourceOptions: cameraSourceOptions,
        audioSourceOptions: audioSourceOptions,
      }, null, this, func);

      log.trace("%saudioSourceOptions:%s", func, audioSourceOptions);
      log.trace("%scameraSourceOptions:%s", func, cameraSourceOptions);
    } catch (e) {
      const errMessage = "(catch):" + e.message;
      log.error("%serrMessage:%s", func, errMessage);
      await this.handleError(errMessage, func);
    }
  }//deviceSource



  /**
   * monitor c2c for closed connections by performer and close popup here as well
   */
  async getConnection() {
    const func = "getConnection(),CustomerPopup.jsx,";
    let retVal = false;
    const apiUrl = `${BaseUrl}${constants.A_C2C_GET_CONNECTION}`;
    log.trace("%sapiUrl:%s", func, apiUrl);
    const data = {
      stream_name: this.state.stream_name,
      streaming_id: getId("streaming_id", func),
      auth_token: getId("auth_token", func),
      caller: func,
    }

    log.trace("%sdata:%s", func, data);

    const result = await apiCallAwait(apiUrl, 'POST', data).catch(async (error) => {
      const errMessage = "(catch):" + error.message;
      log.fatal("%serrMessage:%s", func, errMessage);
      await this.handleError(errMessage, func);
    })

    log.trace("%sresult:%s", func, result);
    if (result) {
      if ((result.response === constants.RESULT_SUCCESS) && (result.message !== null)) {
        if (result.message.stream_name === this.state.stream_name) {
          retVal = result.message.status;
        }
      }
    }
    log.trace("%sretVal:%s", func, retVal);
    return retVal;
  }//getConnection


  /**
   * 
   */
  componentDidMount() {
    const func = "componentDidMount(),CustomerPopup.jsx,";
    log.trace("%sthis.props:%s", func, this.props);
    const performerId = getId("performer_id", func);

    setState({
      performer_id: performerId,
      connectionParams: {
        username: getId("username", func),
        password: "c2c",
        token: getId("red5_token", func)
      }
    }, null, this, func);
    log.trace("%sthis.state:%s", func, this.state);
    if (this.connTimer === 0) {
      const schedulerData = {
        type: constants.SCHEDULER_CUSTOMER_POPUP,
        refreshRate: 1000,
      }
      const scheduler = new Scheduler(schedulerData, this.refreshConnection);
      this.connTimer = scheduler.getTimer();
      this.checkBroswerPermissions();
      log.debug('%sScheduler started:%s', func, scheduler);
    }
  }//componentDidMount

  refreshConnection = async (data) => {
    const func = "refreshConnection(),CustomerPopup.jsx,";
    const result = await this.getConnection();
    log.trace("%sresult:%s", func, result);
    if (result) {
      switch (result) {
        case constants.C2C_CLOSED:
        case constants.C2C_CANCEL:
          this.setState({ streaming: false }, async () => {
            await this.stopStreamingHandler(errors.STREAMING_IS_STOPPED_BY_USER);
          });

          break;
        default:
          break;
      }
    }
  }//refreshConnection

  /**
   * start streaming handler
   */
  startStreamingHandler = () => {
    const func = "startStreamingHandler(),CustomerPopup.jsx,";
    this.setState({
      startStreamingValue: true,
    }, async () => {
      log.debug("%sStart streaming of 2wayCams..", func);
     await this.startStreaming();
    })
  }//startStreamingHandler

  /**
   * stop streaming handler
   */
  stopStreamingHandler = () => {
    const func = "stopStreamingHandler(),CustomerPopup.jsx,";
    this.setState({
      startStreamingValue: false,
    }, async () => {
      log.debug("%sStop streaming of 2WayCams..", func);
      await this.handleError(errors.STREAMING_IS_STOPPED_BY_USER, func, false);
    })
  }//stopStreamingHandler

  /**
  * handle errors
  * @param {*} eventType
  */
  async handleError(message, caller, error = true) {
    const func = "handleError(" + caller + "),CustomerPopup.jsx,";
    if (error) log.fatal("%smessage:%s", func, message);
    else log.debug("%smessage:%s", func, message);
    await this.stopStreaming();
    if (this.connTimer) clearInterval(this.connTimer);
    this.connTimer = 0;
    DisplayError(message, func, this.state.frame);
  } //handleError

  /**
   * this function contain subscriber event
   * @param {event} event
   */
  eventHandler = async (event) => {
    const func = "eventHandler(),CustomerPopup.jsxx,";
    log.trace("%sevent.type:%s", func, event.type);
    if (event.type === "Connect.Success") {
      log.info("%sevent.type:%s", func, event.type);
    } else if (event.type === "Publisher.Connection.Closed") {
      await this.handleError(errors.RED5PRO_PUBLISHER_CONNECTION_CLOSED, func);
    } else if (event.type === "Connect.Failure") {
      await this.handleError(errors.RED5PRO_CONNECT_FAILURE, func);
    }
  }; //eventHandler

  async connectToCamera() {
    const func = "connectToCamera(),LiveStreaming.js,";
    try {

      //sometimed usb camera get stuck due Chrome get stuck in background
      //this while cycles helps to make to detect it and use displaypopup custom message
      //BUSY_CAMERA
      log.trace("%sthis.publisher-before:%s", func, this.publisher);
      this.publisher = new RTCPublisher();
      this.publisher.on('*', this.handlePublisherEvent);
      this.viewer = new PublisherView(constants.CUSTOMER_POPUP_VIDEO);
      setLogLevel(red5ErrorLevel());
      await this.viewer.attachPublisher(this.publisher);
      //{ video: { deviceId: myPreferredCameraDeviceId } }
      //const media = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      const userConfig = getUserConfig(func);
      let media = null;
      if (userConfig.cameraSource.value !== null) {
        media = await navigator.mediaDevices.getUserMedia(
          {
            video:
            {
              /*width: {
                ideal: 250,
              },
              height: {
                ideal: 250,
              },*/
              deviceId:
              {
                exact: userConfig.cameraSource.value
              }, audio: true
            },
          }
        ).catch((error) => {
          log.error("%serror(getUserMedia-1):%s", func, error);
          displayPopup(errors.BUSY_CAMERA, func);
        });
      } else {
        media = await navigator.mediaDevices.getUserMedia({ video: true, audio: true }).catch((error) => {
          log.error("%serror(getUserMedia-2):%s", func, error.message);
          displayPopup(errors.BUSY_CAMERA, func);
        });
      }

      // Upon access of user media,
      // 1. Attach the stream to the publisher.
      // 2. Show the stream as preview in view instance.
      //this.publisher.attachStream(media);
      this.publisher._mediaStream = media;
      await this.viewer.preview(media, true);
      log.debug("%sCamera of customer connected to perform 2waycams stream..", func);
      setState({
        streamingStarted: 1,
      }, null, this, func);

    }
    catch (error) {
      log.fatal("%serror(catch):%s", func, error.message);
      switch (error.message) {
        case "Could not start video source":
          displayPopup(errors.BUSY_CAMERA, func);
          break;
        default:
          //await this.handleError(error.message, func);
          displayPopup(errors.BUSY_CAMERA, func);
          break;
      }
    }
  }//connectToCamera

  /**
   * Start streaming
   * @async
   */
  startStreaming = async () => {
    const func = "startStreaming(),CustomerPopup.jsx,";
    if (getShutDown(func)) {
      return;
    }
    const { stream_name } = this.state;
    this.setState({
      streamingStarted: 2,
    });

    document.getElementById('customerC2CControlsStartBtn').hidden = true;

    this.publisher = new RTCPublisher();
    this.publisher.on('*', this.eventHandler);


    this.viewer = new PublisherView(constants.CUSTOMER_POPUP_VIDEO);
    setLogLevel(red5ErrorLevel());
    this.viewer.attachPublisher(this.publisher);
    //retrieve user video configuaration from local storage
    const userConfig = getUserConfig(func);
    // eslint-disable-next-line no-unused-vars
    let media = null;
    if (userConfig.cameraSource.value !== null) {
      media = await navigator.mediaDevices.getUserMedia(
        {
          video:
          {
            deviceId:
            {
              exact: userConfig.cameraSource.value
            }, audio: true
          },
        }
      ).catch((error) => {
        log.error("%serror(getUserMedia-1):%s", func, error);
        displayPopup(errors.BUSY_CAMERA, func);
      });
    } else {
      // eslint-disable-next-line no-unused-vars
      media = await navigator.mediaDevices.getUserMedia({ video: true, audio: true }).catch((error) => {
        log.error("%serror(getUserMedia-2):%s", func, error.message);
        displayPopup(errors.BUSY_CAMERA, func);
      });
    }


    log.trace("%suserConfig:%s", func, userConfig);
    const initData = {
      protocol: config.red5.protocol,//"wss",
      host: config.red5.host,//"dev-red5-c7.t2.tl",
      port: config.red5.port,//443,
      app: config.red5.app,//"live",
      streamName: stream_name,
      rtcConfiguration: {
        iceServers: [config.red5.iceServers],
        //iceServers: [{ urls: "stun:stun2.l.google.com:19302" }],
      },
      keyFramerate: userConfig.keyFramerate !== "" ? userConfig.keyFramerate : 3000,
      bandwidth: {
        audio: 56,
        video: 512
      },
      mediaConstraints: {
        audio: true,
        video: {
          width: {
            exact: userConfig.videoWidth !== "" ? userConfig.videoWidth : 640
          },
          height: {
            exact: userConfig.videoHeigth !== "" ? userConfig.videoHeigth : 480
          },
          frameRate: {
            min: userConfig.videoFramerate !== "" ? userConfig.videoFramerate : 8
          },
          //set which webcamera will be used in stream session, if not yet set use system default
          deviceId: { exact: userConfig.cameraSource !== "" ? userConfig.cameraSource.value : null },
        }
      }, connectionParams: this.state.connectionParams
    };//initData
    log.debug("%s2WayCams stream, initData:%s", func, initData);
    await this.publisher.init(initData).catch(async (error) => {
      const errMessage = "init stream catch(error):" + error.message;
      log.fatal("%s%s", func, errMessage);
      this.publisher = undefined;
      this.viewer = undefined;
      await this.handleError(errMessage, func);
    });

    if (this.publisher) {
      setTimeout(async () => {
        if (this.publisher._mediaStream !== undefined) {
          await this.publisher.publish().catch(async (error) => {
            const errMessage = "publish catch(error):" + error.message;
            log.fatal("%s%s", func, errMessage);
            this.publisher = undefined;
            this.viewer = undefined;
            await this.handleError(errMessage, func);

          });
          const data = {
            stream_name: this.state.stream_name,
            performer: getId("performer", func),
            username: getId("username", func),
            streaming_id: getId("streaming_id", func),
            auth_token: getId("auth_token", func),
            status: constants.C2C_OPEN,
            caller: func,
          }

          log.debug("%sCreate stream to 2waycams,data:%s", func, data);

          const apiUrl = `${BaseUrl}${constants.A_C2C_CREATE_STREAM}`;
          log.trace("%sapiUrl:%s", func, apiUrl);
          const createResult = await apiCallAwait(apiUrl, 'POST', data).catch(async (error) => {
            const errMessage = "create stream catch(error):" + error.message;
            log.fatal("%s%s", func, errMessage);
            await this.handleError(errMessage, func);
          });
          log.debug("%sResult of 2waycams create stream :%s", func, createResult);
          this.c2cConnected = true;

          if (createResult.response === constants.RESULT_ERROR) {
            log.fatal("%screateResult:%s", func, createResult);
            if (this.publisher._mediaStream !== undefined) {
              const tracks = this.publisher._mediaStream.getTracks();
              log.trace("%stracks:%s", func, tracks);
              tracks.forEach(track => track.stop())
            }
            this.c2cConnected = false;

            await this.publisher.unpublish().catch(async (error) => {
              const errMessage = "unpublish catch(error):" + error.message;
              log.fatal("%s%s", func, errMessage);
              await this.handleError(errMessage, func);
            })
            return;
          }

          this.setState({
            streamingStarted: 3,
          });

          document.getElementById('customerC2CControls').hidden = true;

        }
        else {
          setState({
            startStreamingValue: false,
            streamingStarted: 1,
          }, null, this, func);
          log.trace('%ssetState:%s', func, this.state);
          displayPopup("Please allow Camera and Microphone access!", func);
        }
      }, 5000);
    }//if this.publisher
    log.info('%Customer 2waycams %s stream started...', func,stream_name);
  }//startStreaming


  /**
   * Stop streaming
   * @async
   */
  stopStreaming = async () => {
    const func = "stopStreaming(),CustomerPopup.jsx,";
    if (this.publisher) {
      this.publisher.off('*', this.eventHandler);
      if (this.publisher._mediaStream !== undefined) {
        const tracks = this.publisher._mediaStream.getTracks();
        tracks.forEach(track => track.stop())
        log.trace("%stracks.stop:%s", func, tracks);
      }

      await this.publisher.unpublish().catch(async (error) => {
        const errMessage = "unpublish catch(error):" + error.message;
        log.fatal("%s%s", func, errMessage);
        await this.handleError(errMessage, func);
      })
      this.publisher.off('*', this.eventHandler);
    }
    setState(
      {
        streaming: false,
        twoWayCamRequestStatus: false,
      }, null, this, func
    );
    this.c2cConnected = false;
    log.info("%sCustomer did stop 2waycams streaming...", func);
    //closing is done at showModal at the end of file  
  }//stopStreaming


  /**
   * Stop playing function
   */
  StopPlay = () => {
    const func = "StopPlay(),CustomerPopup.jsx,";
    log.trace("%sStop play pressed..", func);

    if (this.publisher) {
      const video = document.getElementById(constants.CUSTOMER_POPUP_VIDEO);
      try {
        this.publisher.muteVideo();
        log.trace("%svideo muted", func);
        this.publisher.muteAudio();
        log.trace("%saudio muted", func);
        video.pause();
        log.trace("%svideo paused", func);
        setState({
          playValue: true
        }, null, this, func);
        log.trace("%sthis.state", func, this.state);
      }
      catch (error) {
        displayPopup(error.message, func);
      }
    }
  }//StopPlay

  //this function contain start play streaming functionality
  StartPlay = () => {
    const func = "StartPlay(),CustomerPopup.jsx,";
    if (getShutDown(func)) {
      return;
    }

    log.trace("%sStart play pressed..", func);
    if (this.publisher) {
      let video = document.getElementById(constants.CUSTOMER_POPUP_VIDEO);
      try {
        this.publisher.unmuteVideo();
        log.trace("%svideo unmuted", func);
        this.publisher.unmuteAudio();
        log.trace("%saudio unmuted", func);
        video.play();
        log.trace("%svideo play", func);
        setState({
          playValue: false
        }, null, this, func);
        log.trace("%sthis.state", func, this.state);
      }
      catch (error) {
        displayPopup(error.message, func);
      }
    }
  }//Start Play

  /**
   *  mute function
   */
  mute = () => {
    const func = "mute(),CustomerPopup.jsx,";
    if (getShutDown(func)) {
      return;
    }
    log.trace("%s", func);
    if (this.publisher) {
      try {
        this.publisher.muteAudio();
        log.trace("%saudio muted", func);
        setState({
          muteValue: true
        }, null, this, func);
        log.trace("%sthis.state", func, this.state);
      }
      catch (error) {
        log.error("%serror:%s", func, error);
      }
    }
  }//mute

  /**
   * unmute function
   */
  unmute = () => {
    const func = "mute(),CustomerPopup.jsx,";
    if (getShutDown(func)) {
      return;
    }
    log.trace("%s", func);

    if (this.publisher) {
      try {
        this.publisher.unmuteAudio();
        log.trace("%saudio unmuted", func);
        setState({
          muteValue: false
        }, null, this, func);
        log.trace("%sthis.state", func, this.state);
      }
      catch (error) {
        log.error("%serror:%s", func, error);
      }
    }
  }//unmute



  /**
   * switch to fullscreen
   */
  fullScreen = () => {
    const func = "fullScreen(),CustomerPopup.jsx,";
    if (getShutDown(func)) {
      return;
    }
    const elem = document.getElementById(constants.CUSTOMER_POPUP_VIDEO);
    log.trace("%selem:%s", func, elem);

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    } else {
      log.error("requestFullscreen() not executed..");
    }
  }//fullScreen

  /**
   * Close modal panel
   */
  handleClose = () => {
    const func = "handleClose(),CustomerPopup.jsx,";
    setState({
      show: false
    }, null, this, func);
    log.trace("%sthis.state", func, this.state);
  }//handleClose

  /**
 * Open modal panel
 */
  modalOpen = async () => {
    const func = "modalOpen(),LiveStreaming.js,";
    if (getShutDown(func)) {
      return;
    }
    await this.deviceSource();
    setState({
      show: true
    }, null, this, func);
    log.trace("%sthis.state", func, this.state);
  }//modalOpen



  /**
   * This function take value from modal and update state
   * @param {event} e 
   */
  onChangeHandler = e => {
    const func = "onChangeHandler(),CustomerPopup.jsx,";
    setState({ [e.target.name]: e.target.value }, null, this, func);
  }//onChangeHandler

  /**
   * This function checks the broswer has camera permissions
   */
  checkBroswerPermissions = () => {
    navigator.getUserMedia(
      // constraints
      {
        video: true,
        audio: true
      },

      // successCallback
      function (localMediaStream) {
        var video = document.querySelector('video');
        video.src = window.URL.createObjectURL(localMediaStream);
        video.onloadedmetadata = function (e) {
          // Do something with the video here.
        };
      },

      // errorCallback
      function (err) {
        if (err === 'PERMISSION_DENIED') {
          // Explain why you need permission and how to update the permission setting
        }
      }
    );
  }

  /**
 * Save configuration
 * @param {event} e 
 */
  ConfigrationSaveHandler = (e) => {
    const func = "ConfigrationSaveHandler(),LiveStreaming.js,";
    e.preventDefault()
    const videoSize = this.state.videoSize.value.split("x");
    const userConfig =
    {
      bandwidth: {
        audio: this.state.bandwidthAudio,
        video: this.state.videoBandWidth.value,
      },
      keyFramerate: this.state.keyFramerate,
      video: {
        width: videoSize[0],
        heigth: videoSize[1],
        framerate: this.state.videoFramerate
      },
      show: false,
      cameraSource: this.state.cameraSource,
      audioSource: this.state.audioSource,
      buffer: this.state.buffer,
    }

    setState({
      bandwidthAudio: this.state.bandwidthAudio,
      bandwidthVideo: this.state.videoBandWidth.value,
      keyFramerate: this.state.keyFramerate,
      videoWidth: videoSize[0],
      videoHeigth: videoSize[1],
      videoFramerate: this.state.videoFramerate,
      show: false,
      cameraSource: this.state.cameraSource,
      audioSource: this.state.audioSource,
      buffer: this.state.buffer,
    }, null, this, func);
    setUserConfig(userConfig, func);
    log.debug("%sCustomer 2waycams configuration saved:%s", func, this.state);
  }//ConfigrationSaveHandler

  handleCameraSource = cameraSource => {
    const func = "cameraSource(),LiveStreaming.js,";
    setState({ cameraSource }, null, this, func);
    log.trace("%sselected cameraSource:%s:", func, cameraSource);
  };

  handleAudioSource = audioSource => {
    const func = "audioSource(),LiveStreaming.js,";
    setState({ audioSource }, null, this, func);
    log.trace("%sselected audioSource:%s:", func, audioSource);
  };


  handleVideoSize = videoSize => {
    const func = "handleVideoSize(),LiveStreaming.js,";
    setState({ videoSize }, null, this, func);
    log.trace("%sselected videoSize:%s:", func, videoSize);
  };

  handleVideoBandwidth = videoBandWidth => {
    const func = "handleVideoBandwidth(),LiveStreaming.js,";
    setState({ videoBandWidth }, null, this, func);
    log.trace("%sselected videoBandWidth:%s:", func, videoBandWidth);
  };

  render() {
    const func = "render(),CustomerPopup.jsx,";
    log.trace("%sthis.state:%s", func, this.state);
    const {
      startStreamingValue,
      streamingStarted,
      show,
      cameraSource,
      audioSource,
      cameraSourceOptions,
      audioSourceOptions,
    } = this.state;
    return (
      <>
        <div className="card preview-section">
          <div className="card-body preview-body customerC2C">
            <div className="camera-section">
              {startStreamingValue === false ? (
                <div id="no-connect">
                  <img src="assets/img/black.png" className="img-fluid" alt="" />
                </div>
              ) : (
                <>
                  <video id={constants.CUSTOMER_POPUP_VIDEO} width='250' height='250' muted={true} autoPlay={true} disablePictureInPicture></video>
                  {/* <div className="constants.CUSTOMER_POPUP_VIDEO-controler-ico-row">
                    <div className="col-8 video-controler-left-ico">
                      <button id="play-pause-button" onClick={() => (!playValue ? this.StopPlay() : this.StartPlay())}>{!playValue ? <i className="fas fa-pause"></i> : <i className="fas fa-play"></i>}</button>
                    </div>
                    <div className="col-4 video-controler-right-ico text-right">
                      <button id="mute-unmute-button" onClick={() => (!muteValue ? this.mute() : this.unmute())}>{!muteValue ? <i className="fas fa-volume-up"></i> : <i className="fas fa-volume-mute"></i>}</button>
                      <button onClick={() => this.fullScreen()}><i className="fas fa-expand"></i></button>
                    </div>
                  </div> */}
                </>
              )}
            </div>

            <div id='customerC2CControls' className="row streaming-btn-sec">
              <div id='customerC2CControlsStartBtn' className="col-md-6">
                {streamingStarted === 1 ? (
                  <>
                    <button data-toggle="modal" data-target="#appt_details" className="btn d-block btn-orange" onClick={() => this.modalOpen()}>Settings</button>
                  </>
                ) : (
                  <>
                  </>
                )
                }
              </div>
              <div className="col-md-6 text-right">
                {streamingStarted === 1 ? (
                  <button id="btn-start-streaming" className="btn d-block btn-green" onClick={() => this.startStreamingHandler()}>Start</button>
                ) : streamingStarted === 2 ? (
                  <>
                    <button id="btn-starting" className="btn d-block btn-green" >Starting...</button>
                  </>
                ) : (
                  <>

                  </>
                )
                }
              </div>
              <div className="col-md-12 text-right c2cPopupText">
                <p>Select your camera and microphone by clicking settings</p>
              </div>
            </div>

          </div>
        </div>
        <Modal show={show} onHide={() => this.handleClose()}>
          <Modal.Header closeButton style={{ borderBottom: 'none' }}>
            <h5 className="modal-title">Settings</h5>
          </Modal.Header>
          <Modal.Body >
            <form className="setting-from">
              <div className="form-group row">
                <label htmlFor="cameraSource" className="col-sm-5 align-self-center col-form-label text-right">Video Device</label>
                <div className="col-sm-7">
                  <Select id="cameraSource"
                    value={cameraSource}
                    onChange={this.handleCameraSource}
                    options={cameraSourceOptions}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="audioSource" className="col-sm-5 align-self-center col-form-label text-right">Audio Device</label>
                <div className="col-sm-7">
                  <Select id="audioSource"
                    value={audioSource}
                    onChange={this.handleAudioSource}
                    options={audioSourceOptions}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-sm-5"></div>
                <div className="col-sm-7">
                  <button id="btn-save" className="btn btn-orange d-block" onClick={(e) => this.ConfigrationSaveHandler(e)}>Save</button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}//render

/**
  customerPopup launch customer's publish window
*/
export function customerPopup(stream_name) {
  const func = "customerPopup(),CustomerPopup.jsx,";
  log.trace("%sstream_name:%s", func, stream_name);

  const jsFrame = new JSFrame(
    {
      horizontalAlign: 'right',//You can specify 'left' and 'right' for horizontalAlign.
      verticalAlign: 'top',//You can also specify 'top' and 'bottom' for the verticalAlign
    });

  const width = 250;
  const height = 250;
  const margin = 30;

  const frame = jsFrame.create({
    name: `customerPopup`,
    title: 'Window of your webcamera',
    // Note:
    // If you specify anchor as right or bottom,
    // the window position will still be specified by (left,top)
    left: -width - margin,
    top: margin,
    width, height,
    movable: true,//Enable to be moved  by mouse
    resizable: false,//Enable to be resized by mouse
    html: '<div id="floating_container"></div>'
  });





  ReactDOM.render(<CustomerPopup stream_name={stream_name} frame={frame} />, document.getElementById('floating_container'));

  async function closeStream() {
    const func = "closeStream(),CustomerPopup.jsx,";
    const data = {
      stream_name: getId(constants.C2C_STREAM_NAME, func),
      caller: func,
      streaming_id: getId("streaming_id", func),
      auth_token: getId("auth_token", func),
    }
    removeId(constants.C2C_STREAM_NAME, func);
    log.trace("%sdata:%s", func, data);

    const apiUrl = `${BaseUrl}${constants.A_C2C_DELETE_STREAM}`;
    log.trace("%sapiUrl:%s", func, apiUrl);
    const deleteResult = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
      log.fatal("%serror:%s", func, error.message);
      DisplayError(error.message, func);
    })
    log.trace("%sdeleteResult:%s", func, deleteResult);
    if (deleteResult) {
      if (deleteResult.response === constants.RESULT_ERROR) {
        log.fatal("%sdeleteResult:%s", func, deleteResult.message);
        DisplayError(deleteResult.message, func);
      }
    }
    frame.closeFrame();
  };

  frame.on('closeButton', 'click', (_frame, evt) => {
    closeStream();
  });

  //Show window
  frame.show();
}

