/**
* Dashboard component
* @module Dashboard
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Header } from "../../layout/Header";
import { Footer } from "../../layout/Footer";
import { Chat } from './Chat';
import { LiveStreaming } from './LiveStreaming';
import { connect } from "react-redux";
import { blockUser, unblockUser, allowSubcriberPermission, denySubscriberPermission } from "../../store/actions/dashboardActions";
import { connectedUserListing } from "../../store/actions/ConnectedUsersActions";
import { IncomeTip } from './IncomeTip';
import { ch } from "../../ChatHandler/ChatHandler";
import { IdleMonitor } from "../IdleMonitor";
import { log } from '../../store/helpers/logger';
import { getShutDown, getStop, getId, setState } from "../../store/helpers/common";
import config from "../../config.json";
import constants from '../../constants';
import {
  roomUpdate,
  statusUpdate,
  notificationContent
} from "../../store/actions/streamingsubscriberActions";
import { performerPopup } from "../../components/TwoWayCams/PerformerPopup.jsx";
import { v4 as uuidv4 } from 'uuid';
import { Scheduler } from '../../store/helpers/scheduler';

/**
* Dashboard component
* @property {*} connectionRefreshID
*/
export class _dashboard extends Component {
  /**
   * @constructor
   * @param {*} props 
   */
  constructor(props) {
    const func = "constructor(),Dashboard.js,";
    log.trace('%sprops:%s', func, props);
    super(props);
    this.state = {
      stream_details: {},             //used for storing the stream detail
      connectedUsers: [],             //used for storing the stream detail
      stream_name: undefined,          //used for storing the stream name
      subscriberData: [],              //used for storing all subscriber data 
      connected_Subscriber_data: [],    //used for storing connected subscriber data
      subscriberChat: false, //used for chat either enable or disable
      twoWayCamsStream: null,
      inviteToPrivate: null
    };
    this.connectionRefreshID = 0;
    this.setStateOfParent.bind(this);
    const performer_id = getId("performer_id", func, true);
    log.debug('%sperformer_id%s:', func, performer_id);
    if (performer_id === null) {
      log.debug('%sperformer_id not exists, redirect to login', func);
      window.location.href = "/";
      return;
    }
  }//constructor
  /**
   * @function UNSAFE_componentWillReceiveProps
   * @memberof Dashboard#
   * @param {*} newProps 
   * @return {void} void
   */
  UNSAFE_componentWillReceiveProps(newProps) {
    const func = "UNSAFE_componentWillReceiveProps(),Dashboard.js,";
    if (getShutDown(func)) {
      return;
    }

    log.trace('%snewProps:%s', func, newProps);

    if (newProps.stream_details !== undefined) {
      this.setState({
        stream_details: newProps.stream_details
      }, () => {
        this.setState({
          stream_name: newProps.stream_details.stream_name
        })
      });
      log.trace('%sstream_details:%s', func, newProps.stream_details);
      log.trace('%sstream_name:%s', func, newProps.stream_details.stream_name);
      log.trace('%sconfig.refresh.dashboardLists:%s', func, config.refresh.dashboardLists);
      if (Object.keys(newProps.stream_details).length > 0 && !getStop(func)) {
        if (this.connectionRefreshID === 0) {
          const schedulerData = {
            type: constants.SCHEDULER_CONNECTION_REFRESH,
            refreshRate: config.refresh.dashboardLists,
          }
          const scheduler = new Scheduler(schedulerData, this.getConnectedUserList);
          this.connectionRefreshID = scheduler.getTimer();
          log.trace('%s connectionRefresh scheduler:%s', func, scheduler);
        }
      }
    }

    if (newProps.connectedUsers !== undefined) {
      setState({ connectedUsers: newProps.connectedUsers }, null, this, func);
    }

    const twoWayCamsStream = getId(constants.C2C_STREAM_NAME, func);
    log.trace('%stwoWayCamsStream:%s', func, twoWayCamsStream);
    if ((twoWayCamsStream === undefined) || (twoWayCamsStream === null)) {
      setState(
        {
          twoWayCamsStream: null,
        }, null, this, func);
    }else
    {
      setState(
        {
          twoWayCamsStream: twoWayCamsStream,
        }, null, this, func);
        log.debug('%sTwo way cams stream available%s:', func, this.state.twoWayCamsStream);
    }
  }//UNSAFE_componentWillReceiveProps


  componentWillUnmount() {
    const func = "componentWillUnmount(),Dashboard.js,";
    log.trace("%sstop connection list", func);
    clearInterval(this.connectionRefreshID);
    this.connectionRefreshID = 0;
  }//componentWillUnmount

  setStateOfParent = (data) => {
    this.setState(data);
  }

  /**
  * Get connected user list 
  * @function getConnectedUserList
  * @memberof Dashboard#
  * @param {string} stream_name
  * @return {json} user list   
  */
  getConnectedUserList = (data) => {
    const func = "getConnectedUserList(" + data.caller + "),Dashboard.js,";
    if (getShutDown(func)) {
      return;
    }
    const stream_name = this.state.stream_name;
    log.trace('%sstream_name:%s', func, stream_name);
    //if I return here connection list not get refreshed
    // if ((stream_name === "") || (stream_name === undefined) || (stream_name === null)) {
    //   log.trace('%serror:%s', func, errors.STREAM_NAME_IS_NULL);
    //   return;
    // }
    const listData = {
      auth_token: getId('auth_token'),
      stream_name: stream_name,
      streaming_id: getId('streaming_id'),
      caller: func,
    }
    this.props.dispatch(connectedUserListing(listData));
  }//getConnectedUserList


  /**
  * Get block user action call
  * @function unblockUserHandler
  * @memberof Dashboard#
  * @param {*} userID
  * @return {json} block user action call   
  */

  unblockUserHandler = (userID) => {
    const func = "unblockUserHandler(),Dashboard.js,";
    if (getShutDown(func)) {
      return;
    }

    log.trace('%suserID:%s', func, userID);
    const res = unblockUser(userID);
    log.debug('%s%s unblocked by performer', func, userID);
    this.props.dispatch(res);
  }//unblockUserHandler


  /**
  * this function contain allow subscriber permission action call 
  * @function allowPermissionHandler
  * @memberof Dashboard#
  * @param {*} value
  */

  allowPermissionHandler = async (data) => {
    const func = "allowPermissionHandler(),Dashboard.js,";
    if (getShutDown(func)) {
      return;
    }
    log.trace('%sdata:%s', func, data);
    const updateData = {
      auth_token: getId("auth_token", func),
      streaming_id: getId("streaming_id", func),
      stream_session_id: data.stream_session_id,
      room_id: data.room_id,
      username: data.username,
      status: constants.CONNECTION_ACCEPTED,
      caller: func,
    };
    log.debug('%sConnection approved by the performer,updateData:%s', func, updateData);
    this.props.dispatch(roomUpdate(updateData));
    const pushData = {
      auth_token: getId("auth_token", func),
      streaming_id: getId("streaming_id", func),
      stream_name: this.state.stream_name,
      content: constants.ACCEPTED,
      timestamp: Date.now(),
      uid: getId("streaming_id", func),
      type: constants.CHAT_ACCEPTED,     // 1- Accepted
      to_username: data.username,
      status: false,
      caller: func,
      room_id: data.room_id,
    };
    log.trace('%spushData:%s', func, pushData);
    // eslint-disable-next-line no-unused-vars
    let errorMessage = null;
    this.props.dispatch(notificationContent(pushData));
    const result = await ch.push(pushData).catch((error) => {
      errorMessage = error.message;
      log.fatal("%serror:%s", func, error);
    })
    log.debug('%sNotification sent,pushData:%s,result:%s', func, pushData,result);
    if (result.response === constants.RESULT_ERROR) {
      errorMessage = result.message;
    }

    if (errorMessage) {
      log.fatal("%serrorMessage:%s", func, errorMessage);
    }
    const res = allowSubcriberPermission(data.username);
    setState(
      {
        subscriberChat: true,
      }, null, this, func
    );
    log.info('%sPerformer approved a connection request from %s to this stream %s', func, data.username,this.state.stream_name);
    this.props.dispatch(res);
  }//allowPermissionHandler


  /**
  * twoWayCamsHandler  
  * @function twoWayCamsHandler
  * @memberof Dashboard#
  * @param {*} value
  */
  twoWayCamsHandler = async (data) => {
    const func = "twoWayCamsHandler(),Dashboard.js,";
    if (getShutDown(func)) {
      return;
    }
    log.trace('%sdata:%s', func, data);
    const pushData = {
      auth_token: getId("auth_token", func),
      streaming_id: getId("streaming_id", func),
      stream_name: this.state.stream_name,
      content: uuidv4(),
      timestamp: Date.now(),
      uid: getId("streaming_id", func),
      type: constants.CHAT_TWO_WAY_CAM_REQUEST,
      to_username: data.username,
      status: false,
      caller: func,
    };
    log.trace('%spushData:%s', func, pushData);
    // eslint-disable-next-line no-unused-vars
    let errorMessage = null;
    const result = await ch.push(pushData).catch((error) => {
      errorMessage = error.message;
      log.fatal("%serror:%s", func, error);
    })
    log.debug('%sTwoWay cams request sent by the performer,pushData:%s,result:%s', func, pushData,result);
    if (result.response === constants.RESULT_ERROR) {
      errorMessage = result.message;
    }

    if (errorMessage) {
      log.fatal("%serrorMessage:%s", func, errorMessage);
    }
    log.info('%sPerformer started a 2way cam request to %s', func, data.username);
    performerPopup(pushData.content, data.username);
  }//twoWayCamsHandler

  /**
  * connectDirectlyToRoom  
  * @function connectDirectlyToRoom
  * @memberof Dashboard#
  * @param {*} value
  */
  connectDirectlyToRoom = async (data) => {
    const func = "connectDirectlyToRoom(),Dashboard.js,";
    if (getShutDown(func)) {
      return;
    }
    log.trace('%sdata:%s', func, data);

    
    const pushData = {
      auth_token: getId("auth_token", func),
      streaming_id: getId("streaming_id", func),
      stream_name: this.state.stream_name,
      content: '{"performerId": '+getId("performer_id", func, true)+ ', "roomType": 3}',
      timestamp: Date.now(),
      uid: getId("streaming_id", func),
      type: constants.CHAT_CONNECT_DIRECTLY_TO_ROOM,
      to_username: data.username,
      status: false,
      caller: func,
    };
    log.trace('%spushData:%s', func, pushData);
    // eslint-disable-next-line no-unused-vars
    let errorMessage = null;
    const result = await ch.push(pushData).catch((error) => {
      errorMessage = error.message;
      log.fatal("%serror:%s", func, error);
    })
    log.debug('%Connect directly to room request sent by the performer,pushData:%s,result:%s', func, pushData,result);
    if (result.response === constants.RESULT_ERROR) {
      errorMessage = result.message;
    }

    if (errorMessage) {
      log.fatal("%serrorMessage:%s", func, errorMessage);
    }
    log.info('%sPerformer started a 2way cam request to %s', func, data.username);
  }//connectDirectlyToRoom

  /**
  * Deny subscriber permission action call 
  * @function denyPermissionHandler
  * @memberof Dashboard#
  * @param {*} data
  */
  denyPermissionHandler = async (customerUsername) => {
    const func = "denyPermissionHandler(),Dashboard.js,";
    if (getShutDown(func)) {
      return;
    }
    log.trace('%customerUsername:%s', func, customerUsername);
    const pushData = {
      auth_token: getId("auth_token", func),
      streaming_id: getId("streaming_id", func),
      stream_name: this.state.stream_name,
      content: constants.DENIED,
      timestamp: Date.now(),
      uid: getId("streaming_id", func),
      type: constants.CHAT_DENIED,     // 1- Accepted
      to_username: customerUsername,
      status: false,
      caller: func,
    };
    log.trace('%spushData:%s', func, pushData);
    // eslint-disable-next-line no-unused-vars
    let errorMessage = null;
    this.props.dispatch(notificationContent(pushData));
    const result = await ch.push(pushData).catch((error) => {
      errorMessage = error.message;
      log.fatal("%serror:%s", func, error);
    })

    log.debug('%sDeny request sent,pushData:%s,result:%s', func, pushData,result);
    if (result.response === constants.RESULT_ERROR) {
      errorMessage = result.message;
    }

    if (errorMessage) {
      log.fatal("%serrorMessage:%s", func, errorMessage);
    }
    const connectionUpdateData = {
      auth_token: getId("auth_token", func),
      streaming_id: getId("streaming_id", func),
      username: customerUsername,
      stream_name: this.state.stream_name,
      status: constants.CONNECTION_DENIED,
      caller: func,
    };
    log.trace("%sconnectionUpdateData:%s", func, connectionUpdateData);
    const connectionUpdate = await this.props.dispatch(statusUpdate(connectionUpdateData));
    log.trace("%sconnectionUpdate:%s", func, connectionUpdate);
    setState({ writeError: null }, null, this, func);
    this.props.dispatch(denySubscriberPermission(customerUsername));
    log.info('%sPerformer dennied a connection from %s ', func, customerUsername);

  } //denyPermissionHandler

  /**
  * Block subscriber  
  * @function blockUserHandler
  * @memberof Dashboard#
  * @param {*} customerUsername
  */
  blockUserHandler = async (customerUsername) => {
    const func = "blockUserHandler(),Dashboard.js,";
    if (getShutDown(func)) {
      return;
    }
    log.trace('%customerUsername:%s', func, customerUsername);
    const pushData = {
      auth_token: getId("auth_token", func),
      streaming_id: getId("streaming_id", func),
      stream_name: this.state.stream_name,
      content: constants.BLOCKED,
      timestamp: Date.now(),
      uid: getId("streaming_id", func),
      type: constants.CHAT_BLOCKED,
      username: getId('performerFullName'),
      to_username: customerUsername,
      status: false,
      caller: func,
    };
    log.trace('%spushData:%s', func, pushData);
    // eslint-disable-next-line no-unused-vars
    let errorMessage = null;
    this.props.dispatch(notificationContent(pushData));
    const result = await ch.push(pushData).catch((error) => {
      errorMessage = error.message;
      log.fatal("%serror:%s", func, error);
    })
    log.debug('%sBlock request sent,pushData:%s,result:%s', func, pushData,result);
    if (result.response === constants.RESULT_ERROR) {
      errorMessage = result.message;
    }

    if (errorMessage) {
      log.fatal("%serrorMessage:%s", func, errorMessage);
    }
    setState({ writeError: null }, null, this, func);
    this.props.dispatch(blockUser(customerUsername));
    log.info('%sPerformer block a connection from %s ', func, customerUsername);
  } //blockUserHandler


  /**
   * count connected and banned users to display the numbers
   * @function countUsers
   * @param void
   * @return {number} retVal counted users 
   */
  countUsers() {
    const func = "countUsers(),Dashboard.js,";
    let retVal = { connected: 0, banned: 0 };
    for (let i = 0; i < this.state.connectedUsers.length; i++) {
      if (this.state.connectedUsers[i].status !== constants.CONNECTION_BLOCK) {
        retVal.connected = retVal.connected + 1;
      }
      if (this.state.connectedUsers[i].banned === true) {
        retVal.banned = retVal.banned + 1;
      }

    }
    log.trace('%sretVal:', func, retVal);
    return retVal;
  }//countUsers

  /**
  * 
  * @function render
  * @memberof Dashboard#
  * @param {*} value
  */
  render() {
    const func = "render(),Dashboard.js,";
    log.trace('%srender start', func);
    log.trace('%sthis.state.twoWayCamsStream:%s', func,this.state.twoWayCamsStream);
    const usersCount = this.countUsers();
    return (
      <>
        <IdleMonitor />
        <div className="main-wrapper">
          <Header {...this.props} />
          <div className="min-height-area">
            <div className="main-section">

              <section className="section dashboard-section min-height-area">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      <LiveStreaming setStateOfParent={this.setStateOfParent} />
                    </div>

                    <div className="accordion col-md-6" id="usersAccordion">
                      <div className="card">
                        <div className="card-header" id="ConnectedUsersHeading">
                          <h5>Connected Users: ({usersCount.connected})
                            <button id="btn-down-connected" className="btn btn-link text-right" type="button" data-toggle="collapse" data-target="#collapseConnectedUsers" aria-expanded="true" aria-controls="collapseConnectedUsers">
                              <i className="fas fa-angle-down rotate-icon"></i>
                            </button></h5>
                        </div>
                        <div id="collapseConnectedUsers" className="collapse show" aria-labelledby="ConnectedUsersHeading" data-parent="#usersAccordion">
                          <div className="card connect-user-table">
                            <div className="card-body">
                              <div className="table-responsive">
                                <table className="table table-hover table-center mb-0">
                                  <thead>
                                    <tr>
                                      <th>Username</th>
                                      <th>Room</th>
                                      <th>IP</th>
                                      <th className="text-center">Balance</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {this.state.connectedUsers.map((option, index) => {
                                      log.trace('%soption connectedUsers:%s', func, option);
                                      return (
                                        <tr key={index} style={{ cursor: 'pointer' }}>

                                          {(option.status !== constants.CONNECTION_BLOCK)
                                            ? (
                                              <>
                                                <td>{option.stripped_username}</td>
                                                <td>{option.room_info === "FREE(null)" ?
                                                  "FREE(£0.00)"
                                                  :
                                                  option.room_info
                                                }</td>
                                                <td>{option.ip_address}</td>
                                                <td className="text-center">{constants.CURRENCY_SYMBOL}{option.balance}</td>
                                                <td>
                                                  {option.status === 0 ? (
                                                    <>
                                                      <Link to="#">
                                                        <i title={constants.TITLE_APPROVE} className="fa fa-thumbs-up"
                                                          onClick={
                                                            (e) => this.allowPermissionHandler(
                                                              {
                                                                username: option.username,
                                                                room_id: option.room_id,
                                                                stream_session_id: option.stream_session_id
                                                              }
                                                            )
                                                          }></i>
                                                      </Link>
                                                      <Link to="#">
                                                        <i title={constants.TITLE_DENY} className="fa fa-thumbs-down" onClick={(e) => this.denyPermissionHandler(option.username)}></i>
                                                      </Link>
                                                    </>
                                                  ) : option.status === constants.CONNECTION_ACCEPTED ? (
                                                    <>
                                                      <Link to="#" onClick={(e) => this.blockUserHandler(option.username)}>
                                                        <i title={constants.TITLE_BAN} className="fa fa-ban"></i>
                                                      </Link>
                                                      &nbsp;
                                                      <Link to="#">
                                                        <i title={constants.TITLE_DENY} className="fa fa-thumbs-down" onClick={(e) => this.denyPermissionHandler(option.username)}></i>
                                                      </Link>
                                                    </>
                                                  ) : option.status === constants.CONNECTION_WAIT_FOR_CUSTOMER_APPROVAL ? (
                                                    <>
                                                      <Link to="#" onClick={(e) => this.blockUserHandler(option.username)}>
                                                        <i title={constants.TITLE_WAITING + " " + constants.TITLE_BAN} className="fa fa-pause-circle"></i>
                                                      </Link>
                                                      &nbsp;
                                                      <Link to="#">
                                                        <i title={constants.TITLE_WAITING + " " + constants.TITLE_DENY} className="fa fa-pause-circle" onClick={(e) => this.denyPermissionHandler(option.username)}></i>
                                                      </Link>
                                                    </>

                                                  ) : null}
                                                  {this.state.twoWayCamsStream === null ?
                                                    (
                                                      <>
                                                        &nbsp;
                                                        <Link to="#">
                                                          <i title={constants.TITLE_CAMS} className="fa fa-video" onClick={(e) => this.twoWayCamsHandler(
                                                            {
                                                              username: option.username,
                                                              room_id: option.room_id,
                                                              stream_session_id: option.stream_session_id
                                                            }
                                                          )}></i>
                                                        </Link>
                                                      </>
                                                    ) : this.state.twoWayCamsStream !== null ? (<>
                                                      &nbsp;
                                                      <Link to="#">
                                                        <i title={constants.TITLE_CAMS} className="fa fa-video disabled" ></i>
                                                      </Link>

                                                    </>) : null}
                                                      <>
                                                        &nbsp;
                                                        <Link to="#">
                                                          <i title={constants.DIRECT_TO_ROOM} className="fa fa-star" onClick={(e) => this.connectDirectlyToRoom(
                                                            {
                                                              username: option.username,
                                                              room_id: option.room_id,
                                                              stream_session_id: option.stream_session_id
                                                            }
                                                          )}></i>
                                                        </Link>
                                                      </>
                                                </td>
                                              </>
                                            ) : null}

                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* BannedUsersHeading  */}
                      <div className="card">
                        <div className="card-header" id="BannedUsersHeading">
                          <h5>Banned Users: ({usersCount.banned})
                            <button id="btn-down-banned" className="btn btn-link text-right" type="button" data-toggle="collapse" data-target="#collapseBannedUsers" aria-expanded="false" aria-controls="collapseBannedUsers">
                              <i className="fas fa-angle-down rotate-icon"></i>
                            </button>
                          </h5>
                        </div>
                        <div id="collapseBannedUsers" className="collapse" aria-labelledby="BannedUsersHeading" data-parent="#usersAccordion">
                          <div className="card banned-user-table">
                            <div className="card-body">
                              <div className="table-responsive">
                                <table id="table-banned" className="table table-hover table-center mb-0">
                                  <thead>
                                    <tr>
                                      <th>Username</th>
                                      <th>Date of Ban</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {this.state.connectedUsers.map((option, index) => {
                                      return (
                                        <tr key={index} style={{ cursor: 'pointer' }}>
                                          {option.banned === true ? (
                                            <>
                                              <td>{option.stripped_username}</td>
                                              <td>{option.created}</td>
                                              <td>
                                                <Link to="#" id={"unblock-" + option.username} >
                                                  <i title={constants.TITLE_UNBLOCK} className="fa fa-thumbs-up" onClick={(e) => this.unblockUserHandler(option.customer_id)}></i>
                                                </Link>
                                              </td>
                                            </>
                                          ) : null}
                                        </tr>

                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="col-md-6">
                      <IncomeTip />
                    </div>
                    <div className="col-md-6">
                      <Chat
                        stream_name={this.state.stream_name}
                        subscriberChat={this.state.subscriberChat}
                      />
                    </div>

                  </div>
                </div>

              </section>
            </div>
          </div>
          <Footer {...this.props} />
        </div>

      </>
    )
  }
}//class

/**
* @function mapStateToProps
* @property {*} stream_details
          * @property {*} connectedUsers
          * @property {*} income_tip_list
          * @param {*} state
          */
const mapStateToProps = (state) => ({
  stream_details: state.StreamingDeatails.stream_details,
  connectedUsers: state.ConnectedUsers.connected_user_listing,
  income_tip_list: state.Dashboard.income_tip_list,
});

log.trace("Dashboard.js,mapStateToProps:%s", mapStateToProps)
const connectedComponent = connect(mapStateToProps)(_dashboard);
export { connectedComponent as Dashboard };