/**
* @module ping
* @version 1.2
* @copyright Telecom2 Ltd.
*/
import { BaseUrl, apiCallAwait, getId, setId, getPingReady, getShutDown } from '../helpers/common';
import config from '../../config.json';
import {log} from './logger';
import { RedirectToLogin } from "../actions/userActions";
import errors from "../../errors";
import constants from "../../constants";


/**
 * Ping node background service
 */
export function pingNode(data) {
  const func = "pingNode(" + data.caller + "),ping.js,";
  log.trace("%stype:%s", func, data.caller_type);
  log.trace("%sdata init:%s", func, data);

  let retVal = {
    response: constants.RESULT_ERROR,
    message: null
  };

  return new Promise(async (resolve, reject) => {
    if (getShutDown(func)) {
      retVal.message = errors.SHUTDOWN;
      log.trace("%shutDown true,retVal:%s", func, retVal);
      return reject(retVal);
    }
    if (!getPingReady(func)) {
      retVal.message = errors.PING_NOT_READY;
      log.trace("%pingReady false,retVal:%s", func, retVal);
      return reject(retVal);
    }

    switch (data.caller_type) {
      case constants.PERFORMER:
        data.username = null;
        if (!config.heartBeat.performer) {
          retVal.message = errors.PING_IS_DISABLED;
          return reject(retVal);
        }

        if (!data.performer_id) {
          retVal.message = errors.PERFORMER_ID_IS_NULL;
          log.fatal("%serror:%s", func, retVal.message);
          return reject(retVal);
        }
        break;
      case constants.CUSTOMER:
        if (!config.heartBeat.customer) {
          retVal.message = errors.PING_IS_DISABLED;
          return reject(retVal);
        }
        if (!data.username) {
          retVal.message = errors.USERNAME_IS_NULL
          log.fatal("%serror:%s", func, retVal.message);
          return reject(retVal);
        }
        if (!data.performer) {
          retVal.message = errors.PERFORMER_IS_NULL;
          log.fatal("%serror:%s", func, retVal.message);
          return reject(retVal);
        }
        if (!data.stream_name) {
          retVal.message = errors.STREAM_NAME_IS_NULL;
          log.fatal("%serror:%s", func, retVal.message);
          return reject(retVal);
        }

        break;
      default:

        break;
    }

    if (!data.auth_token) {
      retVal.message = errors.AUTH_TOKEN_IS_NULL;
      log.fatal("%serror:%s", func, retVal.message);
      return reject(retVal);
    }
    if (!data.streaming_id) {
      retVal.message = errors.STREAMING_ID_IS_NULL;
      log.fatal("%serror:%s", func, retVal.message);
      return reject(retVal);
    }

    const apiUrl = BaseUrl + constants.A_PING;
    log.trace("%sapiUrl:%s", func, apiUrl);
    log.trace("%sdata:%s", func, data);
    const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
      log.fatal("%serror:%s", func, error);
      retVal = error;
    })

    if (!result) {
      log.fatal("%sretVal:%s", func, retVal);
      return reject(retVal);
    }
    retVal = result;
    setId('auth_token', result.message.auth_token, func);
    setId('red5_token', result.message.red5_token, func);
    setId('streaming_id', result.message.streaming_id, func);
    log.trace("%sretVal:%s", func, retVal);
    return resolve(retVal);
  }).catch((error) => {
    log.fatal("%serror:%s", func, error);
  });
}//pingNode

/**
 * ping webserver to control current connection
 */
async function pingWebserver() {
  const func = "pingWebserver(),ping.js,";
  //verify webserver still working as well
  if (getShutDown(func)) { return; }
  if (!getPingReady(func)) { return; }
  if (!config.heartBeat.webserver) { return; }

  const httpString = config.webserver.ssl ? 'https' : 'http';
  const urlToCall = `${httpString}://${config.webserver.host}:${config.webserver.port}`;
  log.trace("%surl:%s", func, urlToCall);
  const data = {
    caller: func,
  };
  log.trace("%sdata:%s", func, data);

  const result = await apiCallAwait(urlToCall, 'GET', data).catch((error) => {
    log.fatal("%serror:%s", func, error);
    RedirectToLogin(error.message, func);
  })
  log.trace("%sresult:%s", func, result);
  if (result) {
    if (result.status !== 200) {
      // const errMessage = `response status != 200,result: ${result}`;
      // RedirectToLogin(errMessage, func);
    }
  } else {
    RedirectToLogin("Webserver not respond!", func);
  }

  // axios('/', {
  //   baseURL: urlToCall,
  //   method: 'GET',
  //   headers: { 'Content-Type': 'html/text' },
  //   timeout: config.node.timeout,
  // })
  //   .then((response) => {
  //     log.trace("%sresponse:%s", func, response);
  //     if (response.status !== 200) {
  //       const errMessage = `response status != 200,response: ${response}`;
  //       RedirectToLogin(errMessage, func + "-2");
  //     }
  //   })
  //   .catch(function (error) {
  //     const errMessage = error.message;
  //     RedirectToLogin(errMessage, func + "-3");
  //   });
}//pingWebserver


/**
 * @function ping
 */
export async function ping(caller) {
  const func = "ping(" + caller + "),ping.js,";
  if (getShutDown(func)) {
    return { response: constants.RESULT_SUCCESS, message: "shut down in progress" };
  }
  if (!getPingReady(func)) {
    return { response: constants.RESULT_SUCCESS, message: "ping not ready" };
  }

  //not logged in yet , don't bother with ping
  const data =
  {
    auth_token: getId('auth_token', func),
    streaming_id: getId('streaming_id', func),
    username: getId('username', func, true),
    performer: getId('performer', func, true),
    stream_name: getId('stream_name', func, true),
    performer_id: getId('performer_id', func, true),
    caller: func,
    caller_type: '',
  }
  log.trace("%sexecution is start,data:%s", func, data);
  log.trace("%sauth_token:%s", func, getId('auth_token'));
  log.trace("%sstreaming_id:%s", func, getId('streaming_id'));
  let retVal = null;
  if (data.username && data.performer) {
    data.caller_type = constants.CUSTOMER;
    retVal = await pingNode(data);
  }
  //don't check here stream_name, we need token refresh before streaming is started
  else if (data.performer_id) {
    data.caller_type = constants.PERFORMER;
    retVal = await pingNode(data);
  }
  pingWebserver();
  log.trace("%sretVal:%s", func, retVal);
  return retVal;
}//ping


