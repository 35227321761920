/**
 * Constant definitions
 * @version 1.2
 * @copyright Telecom2 Ltd.
 *
*/


module.exports = {
  //api calls
  A_AUTH_USER: "/users/authUser",
  A_C2C_CREATE_STREAM: '/c2cSessions/createStream',
  A_C2C_UPDATE_STREAM: '/c2cSessions/updateStream',
  A_C2C_DELETE_STREAM: '/c2cSessions/deleteStream',
  A_C2C_GET_CONNECTION: '/c2cSessions/getConnection',
  A_CHARGE_STREAM: "/transactionLog/chargeStream",
  A_CHAT_LOG_INSERT: "/chatLog/insert",
  A_CHAT_PULL: "/chat/pull",
  A_CHAT_PUSH: "/chat/push",
  A_CHAT_UPDATE: "/chat/update",
  A_CLEAN_EXIT: "/users/cleanExit",
  A_CREATE_STREAM: "/streamSessions/createStream",
  A_CREATE_USER: "/users/createUser",
  A_CREDIT_CHECK: "/users/creditCheck",
  A_CONNECT_DIRECT_TO_ROOM: "/users/connectDirectToRoom",
  A_CUSTOMER_INFO: "/users/customerInfo",
  A_DELETE_STREAM: "/streamSessions/deleteStream",
  A_GET_ROOM: "/room/getRoom",
  A_INCOME_TIPS: "/streamSessions/incomeTips",
  A_LOGIN: "/users/login",
  A_MAKE_DONATION: "/transactionLog/makeDonation",
  A_PING: "/users/ping",
  A_PERFORMER_CUSTOMER_BAN_PUSH: "/performerCustomerBan/push",
  A_PERFORMER_CUSTOMER_BAN_DELETE: '/performerCustomerBan/delete',
  A_STREAMLOG_INSERT: "/streamLog/insert",
  A_SUBSCRIBE_STREAM: "/streamSessions/subscribeStream",
  A_SUBSCRIBE_STREAM_LIST: "/streamSessions/subscribeList",
  A_UPDATE_STATUS: "/streamSessions/updateStatus",
  A_UPDATE_TOKEN: "/users/updateToken",
  A_UPDATE_ROOM: '/streamSessions/updateRoom',
  A_COMMAND_CENTER_PULL: '/commandCenter/pull',

  ACCEPTED: "Accepted",
  BLOCKED: "blocked",
  C2C_OPEN: 0,
  C2C_ERROR: 1,
  C2C_CANCEL: 2,
  C2C_CLOSED: 3,
  CHARGE_AT_BLOCK_STREAMING: 5,
  CHARGE_AT_CLEAN_EXIT: 2,
  CHARGE_AT_LOOP: 1,
  CHARGE_STOP_BY_CUSTOMER: 3,
  CHARGE_STOP_BY_PERFORMER: 4,
  CHAT_ACCEPTED: 1,
  CHAT_BLOCKED: 3,
  CHAT_DENIED: 2,
  CHAT_HANDLER_MYSQL: "Mysql",
  CHAT_JOIN: 4,
  CHAT_MESSAGE: 0,
  CHAT_TWO_WAY_CAM_REQUEST: 5,
  CHAT_WAIT_FOR_APPROVAL: 6,
  CHAT_DONATION: 7,
  CHAT_LEAVE: 8,
  CHAT_PERFORMER_EXIT: 9,
  CHAT_PERFORMER_UNAVAILABLE: 21,
  CHAT_PERFORMER_IN_PRIVATE: 22,
  CHAT_AUTO_ACCEPTED: 10,
  CHAT_KILL_OLD_CONNECTION: 11,
  CHAT_CONNECT_DIRECTLY_TO_ROOM: 20,
  CHAT_DIRECTION_UP: 'up',
  CHAT_DIRECTION_DOWN: 'down',
  CHAT_DIRECTION_PAUSE: 'pause',
  CHAT_DIRECTION_REFRESH: 'refresh',
  CHAT_DIRECTION_FLOW_ONLY: 'flow',
  CONNECTION_ACCEPTED: 1,
  CONNECTION_BLOCK: 3,
  CONNECTION_CLOSED_BY_CLEAN_EXIT: 8,
  CONNECTION_CLOSED_BY_DELETE_STREAM: 9,
  CONNECTION_CLOSED_BY_CREATE_STREAM: 12,
  CONNECTION_CLOSED_BY_CHARGE_STREAM: 13,
  CONNECTION_CLOSED_BY_LOGOUT: 16,
  CONNECTION_CLOSED_BY_NEW_CONNECTION: 17,
  CONNECTION_CLOSED_BY_NEW_LOGIN: 18,
  CONNECTION_TIP_STATUS:19,
  
  CONNECTION_CLOSED_BY_PING: 6,
  CONNECTION_CLOSED_BY_PRIVATE: 11,
  CONNECTION_CLOSED_BY_RECONNECT: 7,
  CONNECTION_CLOSED_BY_UNSUBSCRIBE: 5,
  CONNECTION_DENIED: 2,
  CONNECTION_DISCONNECTED: 4,
  CONNECTION_OPEN: 0,
  CONNECTION_ROOM_SELECTED: 10,
  CONNECTION_WAIT_FOR_CUSTOMER_APPROVAL: 14,
  CONNECTION_JOIN_TO_ROOM_REJECTED: 15,
  //this is not in the db need for react tip list processor,must be part of the connections statuses
  CONNECTION_TIP: 16,
  CONNECTION_CONNECT_DIRECTLY_TO_ROOM: 21,
  CONNECTION_UNABLE_TO_JOIN_ROOM: 22,
  CUSTOMER: "customer",
  CURRENCY_SYMBOL: "£",
  DENIED: "Denied",
  PERFORMER_EXIT: "Performer exit",
  DONATION: "donation",
  DONATION_AMOUNT_10: 10,
  DONATION_AMOUNT_25: 25,
  DONATION_AMOUNT_50: 50,
  DONATION_AMOUNT_100: 100,

  FREE_ROOM: "FREE",
  GROUP_ROOM: "GROUP",
  JOINED: "joined",
  LEAVE: "leave",
  PERFORMER: "performer",
  PRIVATE_ROOM: "PRIVATE",
  PUBLISHER: "publisher",
  RESULT_ERROR: "Error",
  RESULT_SUCCESS: "Success",
  STOP_STREAMING: "Streaming is stopped",
  STOP: "stop",
  SHUTDOWN: "shutDown",
  TIP: "TIP",
  TITLE_APPROVE: "approve connection",
  TITLE_BAN: "ban user",
  TITLE_UNBLOCK: "unblock",
  TITLE_CAMS: "Initiate two ways cam",
  DIRECT_TO_ROOM: "Invite Customer to private room",
  TITLE_DENY: "deny connection",
  TITLE_WAITING: "waiting for customer approval",
  TWO_WAY_CAM_REQUEST: "twoWayCamRequest",
  CONNECT_DIRECTLY_TO_ROOM: "connectDirectlyToRoom",
  CUSTOMER_POPUP_VIDEO: "customer-popup-video",
  PERFORMER_POPUP_VIDEO: "performer-popup-video",
  C2C_STREAM_NAME: "c2c_stream_name",
  SCHEDULER_REFRESH_CHAT: 1,
  SCHEDULER_DURATION: 2,
  SCHEDULER_INCOME_TIPLIST: 3,
  SCHEDULER_CONNECTION_REFRESH: 4,
  SCHEDULER_REFRESH_CHAT_CUSTOMER: 5,
  SCHEDULER_CUSTOMER_POPUP: 6,
  SCHEDULER_PERFORMER_POPUP: 7,
  SCHEDULER_COMMAND_CENTER: 8,
  MODULE_CHAT: 'chat',
  MODULE_LOGIN: 'login'

}