/**
* @module IdleMonitor
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import React, { Component } from 'react';
import { Navigate } from "react-router-dom"
import { UserLogin, removeAllIds } from "../store/actions/userActions";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import { log } from '../store/helpers/logger';
import config from '../config.json';
import {
  getId,
  setId,
  removeLocalStorage,
  getLocalStorage,
  setState,
  removeShutDown,
  setPingReady,
  //setCookie,
  deleteBrowserCache
} from "../store/helpers/common";
import constants from '../constants';
import Bowser from "bowser"
import Swal from 'sweetalert2';


/**
* Login component
*/
export class _login extends Component {

  constructor(props) {
    const func = "constructor(),Login.js,";
    super(props);
    this.state = { redirect: false, username: '', instance_id: '', password: '', Class: '', Message: '', TimeOut: 100000, lastError: getLocalStorage("lastError", func, true) }
    log.trace("%sthis.state:%s", func, this.state);
    this.validator = new SimpleReactValidator();
    setPingReady(false, func);
    removeAllIds(func);
  }

  async componentDidMount() {
    const func = "componentDidMount(),Login.js,";
    await deleteBrowserCache(func);
    this.checkBroswer()
  }

  checkBroswer= async () => {
    const func = "checkBroswer(),Login.js,";
    const browser = Bowser.getParser(window.navigator.userAgent);
    console.log('browser.getPlatformType():', browser.getPlatformType());
    console.log('browser.getBrowserName() :', browser.getBrowserName());
    //get device
    if(browser.getPlatformType() !== 'desktop')
    {
      if(browser.getBrowserName() !== 'Chrome' || browser.getBrowserName() !== 'Safari' ||  browser.getBrowserName() !== 'Firefox')
      {
        const twspAlert = Swal.mixin(config.sweetAlertError);
        //catch was not recognised
        // const result = await twspAlert.fire('', message).catch((error) => {
        //     log.error("%s,twspAlert.fire,catch(error):%s", func, error.message);
        // });
        await twspAlert.fire('', 'You are using a unsupported browser, please use one of the following; <br>Chrome<br>Safari<br>Firefox');
      }
    }

  }//checkBroswer

  /**
  * This function contain login functionality
  * @async 
  * @function submitHandler
  * @memberof Login#
  * @return {void} void  
  */
  submitHandler = async () => {
    const func = "submitHandler(),Login.js,";
    log.trace("%sthis.props:%s", func, this.props);
    const validator = this.validator;
    let redirectUrl='/dashboard';

    if (validator.allValid()) {                       //this is check all validation
      log.trace("%svalid", func);
      const result = await this.props.dispatch(UserLogin(this.state));

      if (result.response === constants.RESULT_SUCCESS) {
        if (result.first_login === true) {
          //redirect here from config
          if (config.firstLogin.redirect === "on") {
            //window.top.location.href = config.firstLogin.url this is not working well
            //cause flashing on screen before redirect
            redirectUrl='/first-login';
          }
        }
        const state = { username: '', instance_id: '', password: '', Class: 'success', Message: result.message,redirectUrl:redirectUrl };
        setState(state, null, this, func);
        removeLocalStorage("lastError", func, true);
        removeShutDown(func);
        setPingReady(true, func);
        
        log.trace("%sstate:%s", func, state);
        //setCookie('loggerUser', result.username, func);
        setId('performerName', result.username, func);
        setId('performerFullName', result.username + '-' + result.instance_id, func);
        setId('loggerUser', result.username + '-' + result.instance_id, func);

        setId('auth_token', result.auth_token, func);
        setId('red5_token', result.red5_token, func);
        setId('streaming_id', result.streaming_id, func);
        setId('performer_id', result.performer_id, func);
        log.trace("%sauth_token:%s", func, getId('auth_token'));
        log.trace("%sstreaming_id:%s", func, getId('streaming_id'));
        log.trace("%sperformer_id:%s", func, getId('performer_id'));
        setState({ redirect: true }, null, this, func);
        log.debug("%sUser login result is stored in local storage:%s", func, result);
        log.info("%sPerformer login successful:%s", func, result.username);
      } else {
        setState({ Class: 'danger', Message: constants.RESULT_ERROR }, null, this, func);
      }
    } else {
      log.error("%serror:%s", func);

      validator.showMessages();
      this.forceUpdate();
    }
    
    //fatal test log.fatal("%sxxx text",func);
  }//submitHandler

  /**
  * @function render
  * @memberof Login#
  * @return {void} void  
  */
  render() {
    const func = "render(),Login.js,";
    log.trace("%s", func);

    return (
      <div className="account-page login-page">
        {/* Page Content */}
        <div className="content">
          <div className="container-fluid">

            {config.developer.lastError ? <div className="col-12 lastError">{this.state.lastError}</div> : ""}
            {config.developer.authentication ? <Link id="btn-authentication" to="/authentication" className="btn btn-orange btn-auth btn-lg" >Authentication</Link> : ""}

            <div className="row">
              <div className="col-md-6 offset-md-3">
                {/* Login Tab Content */}
                <div className="account-content">
                  <div className="col-md-12 col-lg-6 login-right">
                    <div className="login-header">
                    </div>
                    <form action="index.html">
                      <div className="form-group form-focus">
                        <input type="text" className="form-control floating" value={this.state.instance_id} onChange={e => setState({ instance_id: e.target.value }, null, this, func)} />
                        <label className="focus-label">Instance Id</label>
                        {this.validator.message(
                          "Instance Id",
                          this.state.instance_id,
                          "required"
                        )}
                      </div>
                      <div className="form-group form-focus">
                        <input type="text" className="form-control floating" value={this.state.username} onChange={e => setState({ username: e.target.value }, null, this, func)} />
                        <label className="focus-label">UserName</label>
                        {this.validator.message(
                          "UserName",
                          this.state.username,
                          "required|min:3,string|max:30,string"
                        )}
                      </div>
                      <div className="form-group form-focus">
                        <input type="password" className="form-control floating" value={this.state.password} onChange={e => setState({ password: e.target.value }, null, this, func)} />
                        <label className="focus-label">Password</label>
                        {this.validator.message("Password",
                          this.state.password,
                          "required|min:6,string"
                        )}
                      </div>
                      <Link id="btn-login" className="btn btn-orange btn-block btn-lg" to="#" onClick={e => this.submitHandler()}>Login</Link>
                    </form>
                  </div>
                </div>
              </div>
              {this.state.redirect && <Navigate to={this.state.redirectUrl} replace={true} />}
              {/* /Login Tab Content */}
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>

    )
  }
}//class

const connectedComponent = connect()(_login);
export { connectedComponent as Login };
