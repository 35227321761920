/**
* Streaming subsriber actions
* @module StreamingsubscriberActions
* @version 1.2
* @copyright Telecom2 Ltd.
* 
*/

import { BaseUrl } from '../helpers/common';
import * as actionTypes from '../types/types';
import { ShowLoader } from './staticActions'
import { log } from '../helpers/logger';
import constants from '../../constants';
import { apiCallAwait } from '../helpers/common';
import Swal from 'sweetalert2';

/**
 * @function AuthenticateSubscription
 * @param {JSON} streamData {stream_name,performer_id,streaming_id,auth_token}
 * @returns {JSON} {response,message}
 * @example
 * import { AuthenticateSubscription } from "../store/actions/streamingsubscriberActions"
 * ....
 * 
 * const data =
 * {"auth_token":"368956490c932c65e417a01561286b915c624fe4db5088f91678bb111291ca3378465588215040",
 * "username":"testcustomer100",
 * "streaming_id":"7d90dbae-62ea-11eb-bc34-39145808f34b",
 * "stream_name":"57ea4bc9-6e25-4bcf-9e7f-725fd942bcc5",
 * "room_id":21957
 * }|
 *
 * const result = await store.dispatch(AuthenticateSubscription(data));
 * ....
 * Return Success: 
 * ---------------
 * {
 * "response":"Success",
 * "message":
 * {"id":828,
 * "customer_id":12564,
 * "stream_session_id":2633,
 * "created":"2021-04-06T12:56:10.617Z",
 * "updated":"2021-04-06T12:56:10.617Z",
 * "ip_address":"::ffff:10.0.2.2",
 * "start_datetime":"2021-04-06T12:56:10.617Z",
 * "room_id":21957,"status":0
 * }
 * }
 * 
 * Return Error:
 * -------------
 * result { response: 'Error', message: <error message> }
 *
 */
export function AuthenticateSubscription(data) {
    const func = "AuthenticateSubscription(),streamingsubscriberActions.js,";
    log.debug("%sParameters of subscribe a stream,data:%s", func, data);
    return async dispatch => {
        dispatch(ShowLoader(true));
        const apiUrl = `${BaseUrl}${constants.A_SUBSCRIBE_STREAM}`;

        log.trace("%sapiUrl:%s", func, apiUrl);
        let retVal = null;

        const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
            log.fatal("%serror:%s", func, error);
            retVal = error;
        })

        if (!result) {
            dispatch(ShowLoader(false));
            log.fatal("%sretVal:%s", func, retVal);
            return retVal;
        }
        log.trace("%sResult of stream subsribe:%s", func, result);
        retVal = result;
        log.trace("%sretVal:%s", func, retVal);
        return retVal;
    }//dispatch
}// AuthenticateSubscription


/**
 * @function roomListing
 * @param {JSON} data {auth_token,streaming_id,stream_name}
 * @returns {JSON} {response,message}
 * @example
 * import { roomListing } from "../store/actions/streamingsubscriberActions"
 * ....
 * const data = {
 *  "auth_token":"368956490c932c65e417a01561286b915c624fe4db5088f91678bb111291ca33130804929081560",
 *  "streaming_id":"7d90dbae-62ea-11eb-bc34-39145808f34b",
 *  "stream_name":"57faafb8-fa38-472f-9f86-117fd49cfad0",
 * }
 * 
 * const result = await store.dispatch(roomListing(data));
 * ....
 * Return Success: 
 * ---------------
 * {
 * "response":"Success",
 * "message":
 * [
 * {
 *  "room_type":"FREE",
 *  "cost":null,
 *  "room_id":21956},
 * {
 *  "room_type":"GROUP",
 *  "cost":1.99,
 *  "room_id":21957
 * },
 * {
 *  "room_type":"PRIVATE",
 *  "cost":2.99,
 *  "room_id":21958
 * }
 * ]
 * }
 * 
 * Return Error:
 * -------------
 * result { response: 'Error', message: <error message> }
 *
 */
export function roomListing(data) {
    const func = "roomListing(),streamingsubscriberActions.js,";
    log.debug("%sParameters of list rooms:%s", func, data);

    return async dispatch => {
        dispatch(ShowLoader(true));
        const apiUrl = `${BaseUrl}${constants.A_GET_ROOM}`;
        log.trace("%sapiUrl:%s", func, apiUrl);

        let retVal = null;
        const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
            retVal = error;
            log.fatal("%serror:%s", func, error);
        })

        if (!result) {
            dispatch(ShowLoader(false));
            log.fatal("%sretVal:%s", func, retVal);
            dispatch({ type: actionTypes.ROOM_LISTING, payload: [] })
            return retVal;
        }
        log.trace("%sResult of rooms list:%s", func, result);
        retVal = result;
        log.trace("%sretVal:%s", func, retVal);
        dispatch({ type: actionTypes.ROOM_LISTING, payload: result.message })
        return retVal;
    }//dispatch
}//roomListing

/**
 * @function chargeStream
 * @param {JSON} data {auth_token,streaming_id,stream_name}
 * @returns {JSON} {response,message}
 * @example
 * import { chargeStream } from "../store/actions/streamingsubscriberActions"
 * ....
 * const data = {
 *  "auth_token":"368956490c932c65e417a01561286b915c624fe4db5088f91678bb111291ca33130804929081560",
 *  "streaming_id":"7d90dbae-62ea-11eb-bc34-39145808f34b",
 *  "stream_name":"57faafb8-fa38-472f-9f86-117fd49cfad0",
 * }
 * 
 * const result = await store.dispatch(chargeStream(data));
 * ....
 * Return Success: 
 * ---------------
 * {
 * "response":"Success",
 * "message":
 * {
 *  "id":13983,
 *  "customer_id":12564,
 *  "increment":1.99,
 *  "source":"balance",
 *  "stream_connection_id":2389,
 *  "transaction_carried_forward":1,
 *  "created":"2021-04-13T12:44:02.680Z",
 *  "updated":"2021-04-13T12:44:02.680Z"
 * }
 * }
 * 
 * Return Error:
 * -------------
 * result { response: 'Error', message: <error message> }
 *
 */
export function chargeStream(data) {
    const func = "chargeStream(),streamingsubscriberActions.js,";
    log.debug("%sParameters od charge a stream:%s", func, data);

    return async dispatch => {
        dispatch(ShowLoader(true));
        const apiUrl = `${BaseUrl}${constants.A_CHARGE_STREAM}`;
        log.trace("%sapiUrl:%s", func, apiUrl);

        let retVal = null;
        const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
            retVal = error;
            log.fatal("%serror:%s", func, error);
        })

        if (!result) {
            dispatch(ShowLoader(false));
            log.fatal("%sretVal:%s", func, retVal);
            return retVal;
        }
        retVal = result;
        log.debug("%sResult of stream charging:%s", func, retVal);
        return retVal;
    }//dispatch
}//chargeStream

/**
 * donate an amount to the performer
 * @function donation
 * @param {JSON} data {auth_token,streaming_id,stream_name,donationAmount}
 * @returns {JSON} {response,message}
 * @example
 * import { donation } from "../store/actions/streamingsubscriberActions"
 * ....
 * const data = {
 *  "auth_token":"368956490c932c65e417a01561286b915c624fe4db5088f91678bb111291ca33130804929081560",
 *  "streaming_id":"7d90dbae-62ea-11eb-bc34-39145808f34b",
 *  "stream_name":"57faafb8-fa38-472f-9f86-117fd49cfad0",
 * }
 * 
 * const result = await store.dispatch(donation(data));
 * ....
 * Return Success: 
 * ---------------
 * {
 * "response":"Success",
 * "message":
 * {
 *  "id":13983,
 *  "customer_id":12564,
 *  "increment":10,
 *  "source":"donation",
 *  "stream_connection_id":2389,
 *  "transaction_carried_forward":1,
 *  "created":"2021-04-13T12:44:02.680Z",
 *  "updated":"2021-04-13T12:44:02.680Z"
 * }
 * }
 * 
 * Return Error:
 * -------------
 * result { response: 'Error', message: <error message> }
 *
 */

export function donation(data) {
    const func = "donation(),streamingsubscriberActions.js,";
    log.debug("%sParameters of donation:%s", func, data);

    return async dispatch => {
        dispatch(ShowLoader(true));
        const apiUrl = `${BaseUrl}${constants.A_MAKE_DONATION}`;
        log.trace("%sapiUrl:%s", func, apiUrl);

        let retVal = null;
        const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
            retVal = error;
            log.fatal("%serror:%s", func, error);
        })

        if (!result) {
            dispatch(ShowLoader(false));
            log.fatal("%sretVal:%s", func, retVal);
            return retVal;
        }
        retVal = result;
        log.debug("%sResult of donation:%s", func, retVal);
        return retVal;
    }//dispatch

}//donation


/**
 * @function streamingSubscribeArea
 * @param {*} data 
 */
export function streamingSubscribeArea(data) {
    const func = "streamingSubscribeArea(),streamingsubscriberActions.js,";
    log.trace("%sdata:%s", func, data);

    return dispatch => {
        dispatch({ type: actionTypes.STREAMING_SUBSCRIBE_AREA, payload: data })
    }
}//streamingSubscribeArea

/**
 * @function notificationContent
 * @param {*} data 
 */
export const notificationContent = (data) => {
    const func = "notificationContent(),streamingsubscriberActions.js,";
    log.trace("%sdata:%s", func, data);
    return dispatch => {
        dispatch({ type: actionTypes.NOTIFICATION_CONTENT, payload: data })
    }
}//notificationContent


/**
 * @function blockSubscriberEvent
 * @param {*} data 
 */
export const blockSubscriberEvent = (data) => {
    const func = "blockSubscriberEvent(),streamingsubscriberActions.js,";
    log.debug("%sBlock a subscriber parameters:%s", func, data);

    return dispatch => {
        dispatch({ type: actionTypes.BLOCK_SUBSCRIBER, payload: data })
    }
}//blockSubscriberEvent

/**
 * @function allowSubscriberPermissionEvent
 * @param {*} data 
 */
export const allowSubscriberPermissionEvent = (data) => {
    const func = "allowSubscriberPermissionEvent(),streamingsubscriberActions.js,";
    log.debug("%sParameters of allow a subscriber:%s", func, data);
    return dispatch => {
        dispatch({ type: actionTypes.ALLOW_SUBSCRIBER_PERMISSION_EVENT, payload: data })
    }
}//allowSubscriberPermissionEvent

/**
 * @function denySubscriberPermissionEvent
 * @param {*} data 
 */
export const denySubscriberPermissionEvent = (data) => {
    const func = "denySubscriberPermissionEvent(),streamingsubscriberActions.js,";
    log.debug("%sDeny a subscriber parameters:%s", func, data);
    return dispatch => {
        dispatch({ type: actionTypes.DENY_SUBSCRIBER_PERMISSION_EVENT, payload: data })
    }
}//denySubscriberPermissionEvent

/**
 * @function twoWayCamRequestEvent
 * @param {*} data 
 */
export const twoWayCamRequestEvent = (data) => {
    const func = "twoWayCamRequestEvent(),streamingsubscriberActions.js,";
    log.debug("%s2waycams request parameters:%s", func, data);
    return dispatch => {
        dispatch({ type: actionTypes.TWO_WAY_CAM_REQUEST, payload: data })
    }
}//twoWayCamRequestEvent

/**
 * @function connectDirectlyToRoomEvent
 * @param {*} data 
 */
export const connectDirectlyToRoomEvent = (data) => {
    const func = "twoWayCamRequestEvent(),streamingsubscriberActions.js,";
    log.debug("%s2waycams request parameters:%s", func, data);
    return dispatch => {
        dispatch({ type: actionTypes.CONNECT_DIRECTLY_TO_ROOM, payload: data })
    }
}//connectDirectlyToRoomEvent

/**
 * @function joinApproval
 * @param {*} data 
 */
export const joinApproval = (data) => {
    const func = "joinApproval(),streamingsubscriberActions.js,";
    log.trace("%sJoin approval parameters:%s", func, data);
    return new Promise(async (resolve, reject) => {
        Swal.fire({
            title: data.title,
            icon: "info",
            text: data.text,
            showConfirmButton: true,
            showCancelButton: false,
            showDenyButton: true,
            confirmButtonText: data.buttons.ok,
            denyButtonText: data.buttons.cancel,
        }).then((result) => {
            log.trace("%sresult:%s", func, result);

            if (result.isConfirmed) {
                log.trace("%sconfirmed", func);
                return resolve(true);

            } else if (result.isDenied) {
                log.trace("%sdenied", func);
                return reject(false);

            }
        });
    });//promise
}//joinApproval

/**
 * @function twoWayCamApproval
 * @param {*} data 
 */
export async function twoWayCamApproval() {
    const func = "twoWayCamApproval(),streamingsubscriberActions.js,";
    let title = `Two Way Camera Request`;
    const retVal = await Swal.fire({
        title: "Do you accept this request?",
        icon: "info",
        text: title,
        showConfirmButton: true,
        showCancelButton: true,
        showDenyButton: false,
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
    });
    //tested works
    log.debug("%sResult of 2waycams popup question:%s", func, retVal);
    return retVal;
}//twoWayCamApproval

/**
 * @function connectDirectlyToRoomApproval
 * @param {*} data 
 */
export async function connectDirectlyToRoomApproval() {
    const func = "connectDirectlyToRoomApproval(),streamingsubscriberActions.js,";
    let title = `Connect Directly to Private`;
    const retVal = await Swal.fire({
        title: "Do you accept this request?",
        icon: "info",
        text: title,
        showConfirmButton: true,
        showCancelButton: true,
        showDenyButton: false,
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
    });
    //tested works
    log.debug("%sResult of connectDirectlyToRoom popup question:%s", func, retVal);
    return retVal;
}//connectDirectlyToRoomApproval

/**
 * @function statusUpdate
 * @param {JSON} data {auth_token,streaming_id,stream_name,status}
 * @returns {JSON} {response,message}
 * @example
 * import { statusUpdate } from "../store/actions/streamingsubscriberActions"
 * ....
 * 
 * const data = {
 *  "auth_token":"368956490c932c65e417a01561286b915c624fe4db5088f91678bb111291ca33130804929081560",
 *  "streaming_id":"7d90dbae-62ea-11eb-bc34-39145808f34b",
 *  "stream_name":"57faafb8-fa38-472f-9f86-117fd49cfad0",
 *  "status": constants.CONNECTION_ACCEPTED
 * }
 * 
 * const result = await store.dispatch(statusUpdate(data));
 * ....
 * Return Success: 
 * ---------------
 * {
 * "response":"Success",
 * "message":
 *  {
 *      "id":1279,
 *      "customer_id":12564,
 *      "stream_session_id":4861,
 *      "created":"2021-04-10T17:50:08.000Z",
 *      "updated":"2021-04-10T17:50:50.600Z",
 *      "ping":"2021-04-10T17:50:34.000Z",
 *      "ip_address":"::ffff:10.0.2.2",
 *      "start_datetime":"2021-04-10T17:50:08.000Z",
 *      "end_datetime":"2021-04-10T17:50:50.600Z",
 *      "room_id":21957,
 *      "status":1
 * }
 * 
 * 
 * Return Error:
 * -------------
 * result { response: 'Error', message: <error message> }
 *
 */
export function statusUpdate(data) {
    let caller = "";
    if (data.caller) {
        caller = data.caller;
    }
    const func = "statusUpdate(" + caller + "),streamingsubscriberActions.js,";
    log.debug("%sStatus update parameters:%s", func, data);

    return async dispatch => {
        dispatch(ShowLoader(true));
        const apiUrl = `${BaseUrl}${constants.A_UPDATE_STATUS}`;
        log.trace("%sapiUrl:%s", func, apiUrl);

        let retVal = null;
        const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
            retVal = error;
            log.fatal("%serror:%s", func, error);
        })

        if (!result) {
            dispatch(ShowLoader(false));
            log.fatal("%sretVal:%s", func, retVal);
            return retVal;
        }
        retVal = result;
        log.debug("%sresult of update status:%s", func, retVal);
        return retVal;
    }//dispatch

}//statusUpdate

/**
 * @function roomUpdate
 * @param {JSON} data {auth_token,streaming_id,stream_name,room_id}
 * @returns {JSON} {response,message}
 * @example
 * import { roomUpdate } from "../store/actions/streamingsubscriberActions"
 * ....
 * 
 * const data = {
 *  "auth_token":"368956490c932c65e417a01561286b915c624fe4db5088f91678bb111291ca33130804929081560",
 *  "streaming_id":"7d90dbae-62ea-11eb-bc34-39145808f34b",
 *  "stream_name":"57faafb8-fa38-472f-9f86-117fd49cfad0",
 *  "room_id": 1234
 * }
 * 
 * const result = await store.dispatch(roomUpdate(data));
 * ....
 * Return Success: 
 * ---------------
 * {
 * "response":"Success",
 * "message":
 *  {
 *      "id":1279,
 *      "customer_id":12564,
 *      "stream_session_id":4861,
 *      "created":"2021-04-10T17:50:08.000Z",
 *      "updated":"2021-04-10T17:50:50.600Z",
 *      "ping":"2021-04-10T17:50:34.000Z",
 *      "ip_address":"::ffff:10.0.2.2",
 *      "start_datetime":"2021-04-10T17:50:08.000Z",
 *      "end_datetime":"2021-04-10T17:50:50.600Z",
 *      "room_id":21957,
 *      "status":1
 * }
 * 
 * 
 * Return Error:
 * -------------updateRoom
 * result { response: 'Error', message: <error message> }
 *
 */
export function roomUpdate(data) {
    const func = "roomUpdate(),streamingsubscriberActions.js,";
    log.trace("%sRoom update parameters:%s", func, data);

    return async dispatch => {
        dispatch(ShowLoader(true));
        const apiUrl = `${BaseUrl}${constants.A_UPDATE_ROOM}`;
        log.trace("%sapiUrl:%s", func, apiUrl);

        const result = await apiCallAwait(apiUrl, 'POST', data).catch((error) => {
            //not always error, for example if no connection yet
            log.debug("%serror:%s", func, error);
        })
        log.debug("%sResult of room update:%s", func, result);
        if (result.response === constants.RESULT_SUCCESS) {
            dispatch({ type: actionTypes.ROOM_INFO, payload: result })
        } else if (result.response === constants.RESULT_ERROR) {
            dispatch(ShowLoader(false));
            dispatch({ type: actionTypes.ROOM_INFO, payload: null })
        } else {
            dispatch(ShowLoader(false));
            dispatch({ type: actionTypes.ROOM_INFO, payload: null })
        }
    }//dispatch

}//roomUpdate


