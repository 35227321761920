/**
* StaticReducer module
* @module StaticReducer
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import * as types from "./../types/types";
import {log} from '../../store/helpers/logger';

const initalState = {
    Class: "",
    Message: "",
    Timeout: 2500,
    loader: false
}//initalState

/**
 * @function getLoader
 * @return {*} initalState.loader
 */
export const getLoader = () => {
    const func = "getLoader(),staticReducer.js,";
    log.trace("%sinitalState:%s", func, initalState);
    return initalState.loader;
}//getLoader

/**
 * @function setLoader
 * @param {*} value 
 * @return {state} state
 */
export const setLoader = (value) => {
    const func = "setLoader(),staticReducer.js,";
    log.trace("%svalue:%s", func, value);
    return {
        ...initalState,
        loader: value
    }
}//setLoader

/**
 * @function staticReducer
 * @param {state} state 
 * @param {action} action
 * @return {state} state 
 */
export const staticReducer = (state = initalState, action) => {
    const func = "setLoader(),staticReducer.js,";
    log.trace("%saction:%s", func, action);

    switch (action.type) {
        case types.SHOW_LOADER:
            log.trace("%sSHOW_LOADER:%s", func, action);
            return {
                ...state,
                loader: action.payload
            }
        case types.SHOW_ALERT:
            log.trace("%sSHOW_ALERT:%s", func, action);
            return {
                ...state,
                Class: action.payload.Class,
                Message: action.payload.Message,
                Timeout: action.payload.Timeout
            }
        default:
            log.trace("%sdefault:%s", func, action);
            return state
    }
}//staticReducer