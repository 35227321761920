/**
* @module Scheduler
* @version 1.2
* @copyright Telecom2 Ltd.
*/

//import constants from "../../constants";
import { log } from '../../store/helpers/logger';


export class Scheduler {
    #schedulerData = null;
    className = "Scheduler.js";
    #execFunction = null;
    #timer = 0;
    constructor(data, exFunc) {
        const func = `constructor(),${this.className},`;
        log.trace('%sdata:%s', func, data);
        this.schedulerData = data;
        this.execFunction = exFunc;
        log.trace('%sthis.schedulerData:%s', func, this.schedulerData);
        this.main();
    }//constructor

    getTimer() {
        const func = `getTimer(),${this.className},`;
        log.trace('%s:', func);
        return this.timer;
    }

    async main() {
        const func = `main(),${this.className},`;
        log.trace('%s:', func);
        await this.setInterval();
    }//main

    async setInterval() {
        const func = `setInterval(),${this.className},`;
        log.trace('%s:', func);
        this.timer = setInterval(async () => {
            let data = this.schedulerData;
            data['caller'] = func;
            await this.execFunction(data);
        }, this.schedulerData.refreshRate);
    }//setInterval

}//Scheduler
