/**
 * Authentication component
 * @module Authentication
 * @version 1.2
 * @copyright Telecom2 Ltd.
 *
 */

 import React, { Component } from 'react';
 import { Link } from "react-router-dom";
 import SimpleReactValidator from "simple-react-validator";
 import { connect } from "react-redux";
 import {SourceProvider} from "./Streaming/SourceProvider";
 import Select from 'react-select';
 import {log} from '../store/helpers/logger';
 import constants from '../constants';
 import config from '../config.json';
 import { setPingReady } from '../store/helpers/common';
 import { RedirectToLogin, UpdateToken } from "../store/actions/userActions";
 import {
   removeShutDown,
   removeLocalStorage,
   getLocalStorage,
   setState,
 }
   from "../store/helpers/common";
 
 
 /**
  * @property auth_token {string} this variable is used for storing the token of user 
  * @property username {string} this variable is used for storing the username of user
  * @property streaming_id {string} this variable is used for storing the streaming_id of user
  * @property performer {string} this value is hold performer.streaming_id
  * @property streaming {bool}   this variable is used for either streaming start or stop  
  */
 const initialState = {
   username: '',
   performer: '',
   streaming: false,
   //returnUrl: encodeURIComponent('https://faml.telecom2.net/performer/ayresome-park').replace(/%/g,"|"),
   returnUrl: encodeURIComponent(config.customerRedirect),
   lastError: getLocalStorage("lastError", "initialState,Authentication.js", true)
 };
 
 
 
 /**
  * Authentication component 
  */
 export class _authentication extends Component {
 
   performerOptions = [
     { value: '22efb420-6af8-11eb-b241-b59c0e293344', label: 'testperformer100' },
     { value: '36648cde-6aed-11eb-abe9-9dcf1dc0e13a', label: 'testperformer200' },
     { value: 'fe7df570-cabf-11eb-b381-85748c7ef831', label: 'adultCreativePer' },
     { value: '4e73d9b0-7933-11ec-8745-31c118b38cb2', label: 'cellcastPer' },
   ]
   customerOptions = [
     { value: 'adultCreativeUsr', label: 'adultCreativeUsr' },
     { value: 'testcustomer100-1', label: 'testcustomer100' },
     { value: 'testcustomer200-1', label: 'testcustomer200' },
     { value: 'testcustomer300-1', label: 'testcustomer300' },
     { value: 'testcustomer400-1', label: 'testcustomer400' },
     { value: 'testmoderator100-1', label: 'testmoderator100' },
     { value: 'cellcastCus-3', label: 'cellcastCus' },
 
 
   ]
 
   /**
    * 
    * @param {*} props 
    */
   constructor(props) {
     const func = "constructor(),Authentication.js,";
     super(props);
     this.state = initialState;
     log.trace("%sthis.state,initialState:%s", func, this.state);
     this.validator = new SimpleReactValidator();
     setPingReady(false, func);
     this.streamSubscribeInterval = 0;
     //TODO removeAllIds(func);
   }//constructor
 
   /**
    * 
    */
   UNSAFE_componentWillMount() {
     const func = "UNSAFE_componentWillMount(),Authentication.js,";
     setState({ "lastError": getLocalStorage("lastError", func, true) }, null, this, func);
     log.trace("%sthis.state,lastError:%s", func, this.state);
   }//UNSAFE_componentWillMount
 
   /**
    * 
    */
   componentWillUnmount() {
     const func = "componentWillUnmount(),Authentication.js,";
     clearInterval(this.streamSubscribeInterval);
     this.streamSubscribeInterval = 0;
     log.trace("%sstreamSubscribeInterval:%s", func, this.streamSubscribeInterval);
   }//componentWillUnmount
 
 
   /**
    * this function contain streaming start functionality 
    * @async
    * 
    */
   submitHandler = async (e) => {
     const func = "submitHandler(),Authentication.js,";
     const validator = this.validator;
     if (!validator.allValid()) {
       validator.showMessages();
       this.forceUpdate();
       log.error("%sInvalid validation result!", func);
       return;
     }
     //TODO this is security gap have to remove it before system going to live
     const data = {
       username: this.state.username,
       performer: this.state.performer,
     }
 
     const result = await this.props.dispatch(UpdateToken(data));
     log.debug("%sResult of update token:%s", func, result);
 
     if (result.response !== constants.RESULT_SUCCESS) {
       RedirectToLogin("UpdateToken failed...", func);
       return;
     }
     removeLocalStorage('lastError', func, true);
     setState({ streaming: true }, null, this, func);
     removeShutDown(func);
   }//submitHandler
 
   render() {
     const func = "render(),Authentication.js,";
 
     const { performer, username, streaming } = this.state;
     log.trace("%sthis.state:%s", func, this.state);
     return (
       <div className="account-page login-page">
         {/* Page Content */}
         <div className="content">
           <div className="container-fluid">
             {streaming &&
               <div className="col-md-12">
                 <SourceProvider performer={performer} username={username} returnUrl={this.state.returnUrl} />
               </div>
             }
             {!streaming && <div className="row">
 
               <div className="col-12 lastError">{this.state.lastError}</div>
               <div className="col-md-6 offset-md-3">
                 {/* Authenticate Tab Content */}
                 <div className="account-content">
 
                   <div className="col-md-12 col-lg-12 login-right">
                     <div className="login-header">
                       You can use any of them input form or select box
                     </div>
                     <form action="index.html" data-testid="auth-form">
                       <div className="form-group form-focus">
                         <input id="username" type="text" className="form-control floating" value={this.state.username} onChange={e => setState({ username: e.target.value }, null, this, func)} />
                         <label className="focus-label" htmlFor="username">username</label>
                         {this.validator.message(
                           "UserName",
                           this.state.username,
                           "required"
                         )}
                       </div>
                       <div className="form-group form-focus">
                         <input id="performer" type="text" className="form-control floating" value={this.state.performer} onChange={e => setState({ performer: e.target.value }, null, this, func)} />
                         <label className="focus-label" htmlFor="performer">performer</label>
                         {this.validator.message(
                           "performer",
                           this.state.performer,
                           "required"
                         )}
 
                       </div>
                       <div className="form-group form-focus">
                         <Select id="customer" options={this.customerOptions} onChange={e => setState({ username: e.value }, null, this, func)} />
                       </div>
 
                       <div className="form-group form-focus">
                         <Select id="performer" options={this.performerOptions} onChange={e => setState({ performer: e.value }, null, this, func)} />
                       </div>
                       <br />
                       <Link id="btn-submit" className="btn btn-orange btn-block btn-lg" to="#" onClick={e => this.submitHandler()}>Submit</Link>
                     </form>
                   </div>
                 </div>
               </div>
               {/* Login Tab Content */}
             </div>}
           </div>
         </div>
         {/* Page Content */}
       </div>
     )
   }
 }//render
 /**
  * 
  */
 const connectedComponent = connect()(_authentication);
export { connectedComponent as Authentication };
 