/**
* ConnectedUserReducer module
* @module ConnectedUserReducer
* @version 1.2
* @copyright Telecom2 Ltd.
*/

import * as types from "./../types/types";
import {log} from '../../store/helpers/logger';

/**
 * 
 */
const initalState = {
    connected_user_listing:[]
}

/**
 * @function ConnectedUserReducer
 * @param {*} state 
 * @param {*} action 
 */
export const ConnectedUserReducer = (state = initalState, action) => {
    const func = "ConnectedUserReducer(),ConnectedUserReducer.js,";
    log.trace("%saction.type:%s", func, action.type);
    log.trace("%sstate:%s", func, state);
    switch (action.type) {
        case types.CONNECTED_USER_LISTING:
            log.trace("%sCONNECTED_USER_LISTING:%s", func, action.type);
            return {
                ...state,
                connected_user_listing: action.payload
            }

        default:
            log.trace("%sdefault:%s", func, action.type);
            return state
    }
}//ConnectedUserReducer

