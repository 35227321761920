/**
 * Logging module
 * @module Logger
 * @version 1.2
 * @copyright Telecom2 Ltd.
 *
*/
import axios from 'axios';
import Swal from 'sweetalert2';
import errors from "../../errors";

//import { stringify} from 'flatted';
const config = require('../../config.json');
const winston = require('winston');
const { SPLAT } = require('triple-beam');
//const jscookie = require('js-cookie');
const DetectRTC = require('detectrtc');
const { v4: uuidv4 } = require('uuid');
const metadata = require('../../version.json');
const Transport = require('winston-transport');
require("json-circular-stringify");
require("setimmediate");

var traceArray = [];
var debugArray = [];
var infoArray = [];
var warnArray = [];


//
// Inherit from `winston-transport` so you can take advantage
// of the base functionality and `.exceptions.handle()`.
//
export class LoggerTransport extends Transport {

    convertArray(info) {
        //const func = "convertArray(),logger.js,";
        let retVal = {
            auth: {
                auth_token: localStorage.getItem('auth_token'),
                streaming_id: localStorage.getItem('streaming_id'),
            },
            log: null
        };
        let tmp = {};
        info.forEach(function (value, key) {
            tmp[key] = value;
        });
        retVal.log = tmp;
        retVal = JSON.stringify(retVal, null, '\t');

        return retVal;
    }//convertArray


    send(info) {
        const func = "send(),logger.js,";
        const httpString = config.log.ssl ? 'https' : 'http';
        const urlToCall = `${httpString}://${config.log.host}:${config.log.port}`;
        const cancelTokenSource = axios.CancelToken.source();
        axios(config.log.api, {
            baseURL: urlToCall,
            data: this.convertArray(info),
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            timeout: config.log.timeout,
            cancelToken: cancelTokenSource.token
            //withCredentials:true
        })
            .then((response) => {
                if (response.status !== 200) {
                    const errMessage = `response status != 200,response: ${response}`;
                    console.error(func, new Date().toLocaleString() + " " + errMessage);
                }
            })
            .catch(function (error) {
                const errMessage = `catch error: ${error}`;
                console.error(func, new Date().toLocaleString() + " " + errMessage);
                cancelTokenSource.cancel("Canceling error");
                if (axios.isCancel(error)) {
                    console.error(func, new Date().toLocaleString() + " Canceling error");
                }
                console.error(func, new Date().toLocaleString() + " Call cancelled..");

            });


    }//send

    parse(info, args) {
        const func = "parse(),logger.js,";
        if (!args) return info.message;
        try {
            const tmp = info.message.split(",");

            args.forEach(function (value, key) {
                if (value === Object(value)) {
                    value = JSON.stringify(value, null, '\t');
                }
                info.message = info.message.replace(/%s/, value);
                //this is the module
                let i = 0;
                while (i < tmp.length) {
                    if (tmp[i] === '%m') {
                        info.module = args[i];
                        info.message = info.message.replace(/%m/, args[i]);
                        break;
                    }
                    i++;
                }

            });
        } catch (error) {
            console.error(func, new Date().toLocaleString() + " error:", error, "info:", info, "args:", args);
        }
        return info.message;
    }//parse

    processFilter(info, filter, suffix = "") {
        //const func = "processFilter(),logger.js,";
        let retVal = true;
        const filterLength = Object.keys(filter).length;
        let idx = 0;
        let seek = null;
        for (let i = 0; i < filterLength; i++) {
            if (filter[i] === '*') {
                retVal = false;
                break;
            }

            seek = filter[i] + suffix;
            idx = info.message.indexOf(seek);
            if (idx !== -1) {
                retVal = false;
                break;
            }

            if (filter[i].indexOf("^") === 0) {
                seek = filter[i].replace("^", "") + suffix;
                idx = info.message.indexOf(seek);
                if (idx !== -1) {
                    retVal = true;
                    break;
                }
                else {
                    retVal = false;
                }
            }
        }
        return retVal;
    }//processFilter

    filterOut(info) {
        //const func = "filterOut(),logger.js,";
        let retVal = false;
        //error and fatal level are not filtered
        if (info.level === "error") return false;
        if (info.level === "fatal") return false;
        if (this.processFilter(info, config.log.filter.function, "()")) {
            return true;
        }
        if (this.processFilter(info, config.log.filter.script, ".js")) {
            return true;
        }
        if (this.processFilter(info, config.log.filter.keyword)) {
            return true;
        }

        return retVal;
    }//filterOut

    log(info, callback) {
        //const func = "log(),logger.js,";
        setImmediate(() => {
            this.emit('logged', info);
        });

        // Perform the writing to the remote service
        info.message = this.parse(info, info[SPLAT]) + "|";
        info.username = localStorage.getItem('loggerUser');
        //after login immediatelly we can use it
        // //TODO get fresh hardware,permission info at every log record, may to slow have to profiling it
        // if (config.hardware) {
        //     this.data.hardware = this.loadHardware();
        // }
        // if (config.agent) {
        //     this.data.agent = navigator.userAgent;
        // }


        callback()
        {
            //const func = "callback(),logger.js,";
            let bufferTimeOut = false;
            if (info.message !== "flushBuffer(),logger.js,FLUSHBUFFER|") {
                if (this.filterOut(info)) {
                    return;
                }
            } else {
                //FLUSHBUFFER is a timer message to make buffers empty don't need to log them.
                return;

            }

            info.timestamp = new Date();

            //error,fatal always sent promtly never buffered
            if (info.message === "flushBuffer(),logger.js,FLUSHBUFFER|") {
                bufferTimeOut = true;
            }
            switch (info.level) {
                case "trace":
                    traceArray.push(info);
                    if ((traceArray.length >= config.log.buffer.trace) || (bufferTimeOut)) {
                        this.send(traceArray);
                        traceArray = [];
                    }
                    break;
                case "debug":
                    debugArray.push(info);
                    if ((debugArray.length >= config.log.buffer.debug) || (bufferTimeOut)) {
                        this.send(debugArray);
                        debugArray = [];
                    }
                    break;
                case "warn":
                    warnArray.push(info);
                    if ((warnArray.length >= config.log.buffer.warn) || (bufferTimeOut)) {
                        this.send(warnArray);
                        warnArray = [];
                    }
                    break;
                case "info":
                    infoArray.push(info);
                    if ((infoArray.length >= config.log.buffer.info) || (bufferTimeOut)) {
                        this.send(infoArray);
                        infoArray = [];
                    }
                    break;
                default:
                    this.send([info])
                    break;
            }
        }//callback
    }//log
}//LoggerTransport class



/**
 * Logger function
 * @example 
 * import {log} from './store/helpers/logger';
 *   
 * log.trace("== START ==");
 * const func="login(),login.js,";
 * log.info(%smessage variable:%s,func,variable);
 * log.error etc
 * CONFIGURE
 * ---
 * you can set logger function at multiply places
 * 1) react project config.json level entry
 * regulate at root the level. if set to trace
 * every log entry will goes to console and passed
 * to node to insert into table and text log.
 * 2) in node project config.json stream log entry level
 * regulate the text log only. You can have all entries in db
 * and based on this log level filter the text file. 
 * An example if loglevel info in node config.json and trace in 
 * react config.json. All log will displayed in browser console
 * and inserted into database, but in stream-log text file only info
 * and above info level logs will be written.
 * In agent.js there is delete function this will be delete old entries
 * from db that older than set in node command agent config.json sections.
 * 
 * react:
 * config.json
 *  "log": {
 *      "timestamp": "YYYY/MM/DD HH:mm:ss",
 *      "api": "/streamLog/insert",
 *      "auth": null,
 *      "level": "trace" <---
 *  }
 * node:
 * config.json
 *  "streamlog": {
 *      "errorEventName": "stream",
 *      "logDirectory": "log",
 *      "fileNamePattern": "stream-roll-<DATE>.log",
 *      "dateFormat": "YYYY.MM.DD",
 *      "levelNames": "trace,debug,info,warn,error,fatal",
 *      "level": "trace" <---
 *    },
 * Database:
 * TWSP.stream_log table
 * 
*/
export class Logger {
    flushBufferTime = 0;
    levels = {
        fatal: 0,
        error: 1,
        warn: 2,
        info: 3,
        debug: 4,
        trace: 5
    };
    cLevel = config.log.level;
    consoleLevel = config.consolelog.level;
    httpOpts = {
        host: config.log.host,//(Default: localhost) Remote host of the HTTP logging endpoint
        port: config.log.port,//(Default: 80 or 443) Remote port of the HTTP logging endpoint
        path: config.log.api,//Default: /) Remote URI of the HTTP logging endpoint
        auth: config.log.auth,//,//(Default: None) An object representing the username and password for HTTP Basic Auth
        ssl: config.log.ssl,//(Default: false) Value indicating if we should us HTTPS
    };

    data = {
        application: 'T2-Streaming',
        module: null,
        agent: null,
        //username: jscookie.get('loggerUser'), //if user ever use it before we have uuid immmediatelly 
        //uuid: jscookie.get('loggerUuid'),// and username is well
        username: localStorage.getItem('loggerUser'), //if user ever use it before we have uuid immmediatelly 
        uuid: localStorage.getItem('loggerUuid'),// and username is well
        hardware: null,
        timestamp: new Date(),
        version: `${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`
    };
    bufferedTransport = new LoggerTransport(this);


    /**
     * refresh hardware information
     */
    loadHardware() {
        DetectRTC.load(function () {
        });
        return DetectRTC;
    }

    constructor() {
        //get uuid earliest as possible
        if (!this.data.uuid) {
            const uuid = uuidv4();
            this.data.uuid = uuid;
            //jscookie.set('loggerUuid', uuid);
            localStorage.setItem('loggerUuid', uuid);
        }
        //no clear interval due this one running in full lifecycle of the code.
        setInterval(this.flushBuffer, config.log.buffer.timeout);


        //get hardware info only once,but this is may not contains permission changes etc
        //this.data.hardware = this.loadHardware();

        this.consoleFormat = winston.format.printf(info => {
            if (info.message) {
                info.message = this.parse(info, info[SPLAT]) + "|";
            }
            // const args = info[Symbol.for('splat')];
            // if (args) { info.message = util.format(info.message, ...args); }
            //this.data.username = jscookie.get('loggerUser');
            this.data.username = localStorage.getItem('loggerUser');
            return info.message;
        });



        this.logFormat = winston.format.printf(info => {
            info.message = this.parse(info, info[SPLAT]) + "|";
            //after login immediatelly we can use it
            //this.data.username = jscookie.get('loggerUser');
            this.data.username = localStorage.getItem('loggerUser');
            //TODO get fresh hardware,permission info at every log record, may to slow have to profiling it
            if (config.hardware) {
                this.data.hardware = this.loadHardware();
            }
            if (config.agent) {
                this.data.agent = navigator.userAgent;
            }

        });
        this.bufferedTransport.on('logged', (info) => {
            // Verification that log was called on your transport
            if (config.consolelog.console === "on") {
                //error or fatal always written     
                const consoleMessage = new Date().toLocaleString() + " " + info.message;
                if ((info.level === "error") || (info.level === "fatal")) {
                    console.error(consoleMessage);
                    return;
                }

                if (config.consolelog.level === "trace") {
                    console.trace(consoleMessage);
                }
                else if (config.consolelog.level === "debug") {
                    if (info.level !== "trace") {
                        console.debug(consoleMessage);
                    }
                } else if (config.consolelog.level === "info") {
                    if ((info.level !== "trace") && (info.level !== "debug")) {
                        console.info(consoleMessage);
                    }
                } else if (config.consolelog.level === "warn") {
                    if ((info.level !== "trace") && (info.level !== "debug") && (info.level !== "info")) {
                        console.warn(consoleMessage);
                    }
                } else if (config.consolelog.level === "error") {
                    if ((info.level === "error") || (info.level === "fatal")) {
                        console.error(consoleMessage);
                    }
                } else {
                    console.error("invalid loglevel", consoleMessage);
                }
            }
        });

        this.bufferedTransport.on('warn', (e) => console.error('warning!' + e));


        return winston.createLogger({
            level: this.cLevel,
            levels: this.levels,
            defaultMeta: this.data,
            transports: [
                this.bufferedTransport,
            ],
            exceptionHandlers: [
                this.bufferedTransport,
            ]
        });
    }//constructor



    parse(info, args) {
        if (args === undefined) {
            return info.message;
        }
        try {
            args.forEach(function (value, key) {
                if (value === Object(value)) {
                    value = JSON.stringify(value, null, '\t');
                }
                info.message = info.message.replace(/%s/, value);
                if (key === 1) {
                    info.module = "value";
                }
            });
        } catch (error) {
            console.error(new Date().toLocaleString() + " parse error:", error, "info:", info, "args:", args);
        }
        return info.message;
    }//parse

    flushBuffer() {
        const func = "flushBuffer(),logger.js,";
        //don't flus the buffer at first time
        if (!this.flushBufferTime) return;
        this.trace("%sFLUSHBUFFER", func);
        this.debug("%sFLUSHBUFFER", func);
        this.info("%sFLUSHBUFFER", func);
        this.warn("%sFLUSHBUFFER", func);
        this.flushBufferTime = Date.now();
    }//flushBuffer
}//Logger

const logger = new Logger();

function red5ProDebugInfoHandler(message) {
    const twspAlert = Swal.mixin(config.sweetAlertError);
    let fire = false;
    const toCapture = [
        { message: 'WebSocket connection attempts have ended', errorMessage: errors.WEBSOCKET_BLOCKED },
        //need to test more
        //{ message: 'Publisher status received, but could not handle', errorMessage:'Publisher status received, but could not handle' },

        //{ message: 'icecandidatetrickle:empty', errorMessage: errors.FIREFOXONMAC },
        //     { message:' [websocketclose]: 1000',errorMessage:errors.WEBSOCKET_BLOCKED_1000}
    ]
    let errorMessage = "";
    for (let i = 0; i < toCapture.length; i++) {
        const found = message.includes(toCapture[i].message);
        if (found) {
            fire = true;
            console.error("message:", toCapture[i].message, "errorMessage:", errors.FIREFOXONMAC);
            errorMessage = toCapture[i].errorMessage;
            break;
        }
    }


    if (fire) {
        twspAlert.fire(errors.STREAMING_ERROR_TITLE, errorMessage).then((result) => {
            if (result.isConfirmed) {
                let redirectUrl = localStorage.getItem('returnUrl');
                if (redirectUrl === undefined) {
                    console.error(new Date().toLocaleString() + ' redirectUrl undefined');
                    redirectUrl = "/";
                }
                window.top.location.href = redirectUrl;
            }
        });
    }
}//red5ProDebugInfoHandler

if (config.consolelog.red5pro === "on") {

    //windos console.log capture here to log red5pro sdk logs on server
    window.console.log = function () {
        let str = arguments[0];
        let func = 'console.log()';
        try {
            for (let i = 1; i < arguments.length; i++) {

                if (arguments[i] === Object(arguments[i])) {
                    arguments[i] = JSON.stringify(arguments[i], null, '\t');
                }
                str = str.replace(/%s/, arguments[i]);
                str = str.replace(/%c/, arguments[i]);
            };
            if (str.indexOf("[red5pro-sdk]")) {
                func = 'red5pro-sdk(),red5pro-sdk.js,';
            }

        } catch (error) {
            console.error(new Date().toLocaleString() + " parse error:", error, "str:", str, "arguments:", arguments);
        }
        red5ProDebugInfoHandler(str);

        if (str.indexOf("debug")) {
            logger.debug("%s%s", func, str);
        }
        else if (str.indexOf("info")) {
            logger.info("%s%s", func, str);
        } else {
            logger.error("%s%s", func, str);
        }

    };

}
// eslint-disable-next-line no-unused-vars
export function setLogLevel(level) {
    logger.transports[0].level=level;
}

export { logger as log };




