/**
 * SourceProvider component
 * @module SourceProvider
 * @version 1.2
 * @copyright Telecom2 Ltd.
 *
 */

import React, { Component } from 'react';
import { log } from '../../store/helpers/logger';
/**
 * Source provider
 */
export class SourceProvider extends Component {

    render() {
        const func = "render(),SourceProvider.jsx,";
        log.trace("%s", func);
        return (
            <>
            <iframe title="sp" src={`/live-streaming/${this.props.performer}/${this.props.username}/${this.props.returnUrl}`} height={'100%'} width={'100%'} style={{ minHeight: "100vh", border: "none" }} />
            </>
        )
    }
}


